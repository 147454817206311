import React, { useState, useContext, createContext, useEffect } from 'react';

import { useAxios } from './AxiosContext';
import { useAuth } from './UserAuthContext';
import $ from 'jquery';

const LeftMenuCollapseContext = createContext();

const LeftMenuCollapseProvider = ({ children }) => {
	const { user } = useAuth();
	const { axiosGetService } = useAxios();
	const location = window.location.pathname;

	const [appState, setAppState] = useState({
		solutionMethodId: null,
		rightPanelContent: null,
		userDefaultProjectTypeId: 0,
		userLeaveWithoutSave: false,
		userLeaveWithSave: false,
		lastUrl: null,
		defaultPhaseObject: {
			title: null,
			id: null,
			svgFileName: null,
		},
		defaultComplexity: {
			name: '',
			id: '',
			type: 'option',
		},
		defaultRole: {
			name: 'Select All',
			id: 0,
			type: 'option',
		},
	});
	const [notificationExpand, setNotificationExpand] = useState(false);
	const [role, setRole] = useState(appState.defaultRole);
	const [complexity, setComplexity] = useState(appState.defaultComplexity);
	const [phaseDynamicFilterTagMapping, setPhaseDynamicFilterTagMapping] =
		useState({});
	const [phaseDynamicFilterTag, setPhaseDynamicFilterTag] = useState({});
	const [phaseHashtags, setPhaseHashtags] = useState([]);
	const [complexityValidActivities, setComplexityValidActivities] = useState(
		[]
	);
	const [collapsedLeftMenu, setCollapsedLeftMenu] = useState(true);
	const [consultingSolutionMethods, setConsultingSolutionMethods] = useState(
		[]
	);
	const [supportSolutionMethods, setSupportSolutionMethods] = useState([]);
	const [expandedActivityGroups, setExpandedActivityGroups] = useState([]);
	const [skipNavigationId, setSkipNavigationId] = useState(null);
	const [complexityTag, setComplexityTag] = useState();
	const [solutionMethodsTag, setSolutionMethodsTag] = useState([]);
	const [phasesTag, setPhasesTag] = useState([]);
	const [activitiesTag, setActivitiesTag] = useState([]);
	const [rolesTag, setRolesTag] = useState({});
	const [processGroupFilterTag, setProcessGroupFilterTag] = useState([]);
	const [toolsTag, setToolsTag] = useState([]);
	const [knowledgeAreaTag, setKnowledgeAreaTag] = useState([]);
	const [selectedProcedures, setSelectedProcedures] = useState(0);
	const [dynamicFilterTag, setDynamicFilterTag] = useState({});
	const [tutorialDynamicFilterTag, setTutorialDynamicFilterTag] = useState({});
	const [dynamicFilterTagMapping, setDynamicFilterTagMapping] = useState({});
	const [isAdmin, setIsAdmin] = useState(false);
	const [tutorialDynamicFilterTagMapping, setTutorialDynamicFilterTagMapping] =
		useState({});
	const [selectedTab, setSelectedTab] = useState('All');
	const [nameIdMapping, setNameIdMapping] = useState({
		phases: {},
		solutionMethods: {},
		activities: {},
		roles: {},
		processGroup: {},
		procedures: {},
		tools: {},
		knowledgeArea: {},
	});
	const [phase, setPhase] = useState({
		title: null,
		id: null,
		svgFileName: null,
	});
	const [parentPhase, setParentPhase] = useState({
		title: null,
		id: null,
		svgFileName: null,
	});

	const [breadcrumbs, setBreadcrumbs] = useState([
		{
			title: 'Home',
			type: 'home',
			path: '/',
		},
	]);
	const [svg, setSvg] = useState({
		enable: false,
		url: null,
		type: null,
		initialLoad: true,
	});
	const [notifications, setNotifications] = useState({
		count: 0,
		data: [],
	});
	const [pendingAnnouncements, setPendingAnnouncements] = useState({
		data: {
			features: null,
			announcements: null,
		},
		count: 0,
	});
	const [userFilter, setUserFilter] = useState([{}]);
	const [userActivityFilter, setUserActivityFilter] = useState([{}]);
	const [favorites, setFavorites] = useState([]);
	const [clearFlyout, setClearFlyout] = useState(null);
	const [phaseExpand, setPhaseExpand] = useState([]);
	const [endUserView, setEndUserView] = useState(false);
	const [userIsAdmin, setUserIsAdmin] = useState(false);
	const [showRaciViewBanner, setShowRaciViewBanner] = useState(false);
	const [showSupportBanner, setShowSupportBanner] = useState(false);
	const [userIsOwner, setUserIsOwner] = useState(false);
	const [applyfilterTrigger, setApplyFilterTrigger] = useState(false);
	const getBannerStatus = async () => {
		const [{ data: showSupportBannerData }, { data: showRaciViewBannerData }] =
			await Promise.all([
				axiosGetService(`api/sdmsettings?sdmKey=ShowSupportBanner`),
				axiosGetService('api/sdmsettings?sdmKey=ShowRACIViewBanner'),
			]);
		if (showSupportBannerData[0].value === 'true') {
			setShowSupportBanner(true);
		}
		if (showRaciViewBannerData[0].value === 'true') {
			setShowRaciViewBanner(true);
		}
	};

	const getFavorites = async () => {
		await axiosGetService('/api/sdmfavorite/getbyuser').then((response) => {
			const favoritesData = response.data;
			if (favoritesData === null || favoritesData.length === 0) {
				setFavorites(null);
			} else {
				setFavorites(favoritesData);
			}
		});
	};

	const [isProjectDelivery, setIsProjectDelivery] = useState();
	const [newSolutionMethodId, setNewSolutionMethodId] = useState(isProjectDelivery);


	const getProjectDeliveryId = async () => {
		await axiosGetService(
			`api/sdmsettings?sdmKey=SDMNotInProjectDeliveryId`
		).then((response) => {
			if(response.data !== null){
				setIsProjectDelivery(response.data[0].value.split(",").at(1));
			}
		});
	};
	const getComplexityTag = async () => {
		await axiosGetService(
			`api/sdmsettings?sdmKey=DefaultComplexityTag`
		).then((response) => {
			if(response.data !== null){
				const obj = {
					id: response.data[0].value.split(",").at(0),
					name: response.data[0].value.split(",").at(1).toString()
				}
				setComplexityTag(obj)
			}
		});
	};
	
	useEffect(() => {
		if (user.isAuthenticated) {
			getProjectDeliveryId();
			getComplexityTag();
		}
	}, [user]);
	useEffect(() => {
		if (appState.solutionMethodId != null && newSolutionMethodId !== appState.solutionMethodId) {
			setNewSolutionMethodId(appState.solutionMethodId);
			getDefaultdynamiccomplexity()
		}
	}, [appState.solutionMethodId])
	const getDefaultdynamiccomplexity = async () => {
		await axiosGetService(`api/sdmsolutionmethod/defaultdynamiccomplexity?solutionMethodId=${appState.solutionMethodId}`).then(
			(response) => {
				let data = JSON.parse(response.data)
				setAppState({ ...appState, defaultComplexity: data })
				setComplexity(data)
			}
		);
	};

	const getImageBase64 = (responseData, updateData, type) => {
		let imagesFromDiagramName;

		if (type == 'topiccontribution')
			imagesFromDiagramName = $(responseData.lastContributorDescription).find(
				'img'
			);
		else if (type == 'topiclanding')
			imagesFromDiagramName = $(responseData.diagramName).find('img');
		else if (type == 'topiccrowdsourcing')
			imagesFromDiagramName = $(responseData.description).find('img');

		$.each(imagesFromDiagramName, function (i, img) {
			var imageSource = img.src;
			if (imageSource.indexOf('base64') <= 0) {
				imageSource = decodeURI(imageSource);
				axiosGetService(
					`api/sdmuser/getimagebase64fromblob?sourceUrl=` + imageSource
				).then((response) => {
					if (type == 'topiccontribution')
						responseData.lastContributorDescription =
							responseData.lastContributorDescription.replace(
								imageSource,
								response.data
							);
					else if (type == 'topiclanding')
						responseData.diagramName = responseData.diagramName.replace(
							imageSource,
							response.data
						);
					else if (type == 'topiccrowdsourcing')
						responseData.description = responseData.description.replace(
							imageSource,
							response.data
						);

					updateData(responseData);
				});
			}
		});
	};

	const getUserIsOwner = async () => {
		await axiosGetService(`api/sdmuser/isownerorcoowneroradminofartifact`).then(
			(response) => {
				setUserIsOwner(response.data);
			}
		);
	};

	const getSolutionMethodsByProjectId = async (projectId) => {
		await axiosGetService(
			`/api/sdmsolutionmethod/methodsbytype?methodTypeId=${projectId}&isSvgRequired=${true}&endUserView=${endUserView}`
		)
			.then((solutionMethod) => {
				if (projectId === 1) {
					setConsultingSolutionMethods(solutionMethod.data);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getNotifications = async () => {
		await axiosGetService('/api/sdmnotification/getNotification')
			.then((response) => {
				setNotifications({
					data: response.data.sdmNotifications,
					count: response.data.pendingNotifications,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getNewAnnouncements = async () => {
		await axiosGetService(`api/sdmuser/getflyoutdetails`).then((response) => {
			if (response.data) {
				clearTimeout(clearFlyout);
				setPendingAnnouncements({
					data: {
						features: response.data.features ? response.data.features : null,
						announcements: response.data.announcements
							? response.data.announcements
							: null,
						pendingApprovals: response.data.pendingApprovals,
						pendingReviews: response.data.pendingReviews,
						approvedActivities: response.data.activities.filter(
							({ contributionStatus }) =>
								contributionStatus === 'All Approved' ||
								contributionStatus === 'Partially Approved'
						),
						rejectedActivities: response.data.activities.filter(
							({ contributionStatus }) =>
								contributionStatus === 'All Rejected' ||
								contributionStatus === 'Partially Approved'
						),
						approvedTopics: response.data.topics.filter(
							({ contributionStatus }) =>
								contributionStatus === 'All Approved' ||
								contributionStatus === 'Partially Approved'
						),
						rejectedTopics: response.data.topics.filter(
							({ contributionStatus }) =>
								contributionStatus === 'All Rejected' ||
								contributionStatus === 'Partially Approved'
						),
						approvedTemplates: response.data.templates.filter(
							({ documentStatus }) => documentStatus === 'Published'
						),
						rejectedTemplates: response.data.templates.filter(
							({ documentStatus }) => documentStatus !== 'Published'
						),
						featuresList: response.data.featuresList,
						announcementsList: response.data.announcementsList,
					},
					count: response.data.count,
				});
				setClearFlyout(
					setTimeout(() => {
						setPendingAnnouncements({
							data: {
								features: null,
								announcements: null,
								pendingApprovals: {},
								pendingReviews: {},
								approvedActivities: [],
								rejectedActivities: [],
								approvedTopics: [],
								rejectedTopics: [],
								approvedTemplates: [],
								rejectedTemplates: [],
								featuresList: [],
								announcementsList: [],
							},
							count: response.data.count,
						});
					}, 6000000)
				);
			}
		});
	};
	const getIsAdmin = async () => {
		await axiosGetService('api/user/profile').then((res) => {
			setIsAdmin(res.data.isAdmin);
			setUserIsAdmin(res.data.isAdmin);
		});

		await axiosGetService("/api/sdmuser/getbyemail").then((response) => {
			if (response.data !== null) {
				if (response.data.userDefaultView === true) {
					setEndUserView(response.data.userDefaultView);
					setIsAdmin(false);
				}
			}
		});

	};

	useEffect(() => {
		if (user.isAuthenticated) {
			getSolutionMethodsByProjectId(1);
		}
	}, [user, endUserView]);

	useEffect(() => {
		if (user.isAuthenticated) {
			getIsAdmin();
			getBannerStatus();
			getUserIsOwner();
		}
	}, [user, endUserView]);

	useEffect(() => {
		if (user.isAuthenticated) {
			getNotifications();
			getFavorites();
			getNewAnnouncements();
		}
	}, [location, user]);

	useEffect(() => {
		const href = window.location.pathname;
		const hrefData = [
			'/template',
			'/topics',
			// '/topics/con',
			// '/topics/support',
			'/',
			'/mycontributions',
		];
		if (hrefData.includes(href)) {
			// setComplexityTag({
			// 	id: 3,
			// 	name: 'Medium',
			// });
			setSolutionMethodsTag([]);
			setPhasesTag([]);
			setActivitiesTag([]);
			setRolesTag({});
			setProcessGroupFilterTag([]);
			setToolsTag([]);
			setKnowledgeAreaTag([]);
			setSelectedProcedures(0);
			setDynamicFilterTag({});
			setDynamicFilterTagMapping({});
			setSelectedTab('All');
			setTutorialDynamicFilterTag({});
			setTutorialDynamicFilterTagMapping({});
		}
	}, [location]);

	return (
		<LeftMenuCollapseContext.Provider
			value={{
				appState,
				setAppState,
				breadcrumbs,
				setBreadcrumbs,
				complexityValidActivities,
				setComplexityValidActivities,
				collapsedLeftMenu,
				setCollapsedLeftMenu,
				consultingSolutionMethods,
				supportSolutionMethods,
				phase,
				setPhase,
				parentPhase,
				setParentPhase,
				expandedActivityGroups,
				setExpandedActivityGroups,
				complexity,
				setComplexity,
				role,
				setRole,
				phaseHashtags,
				setPhaseHashtags,
				svg,
				setSvg,
				notifications,
				setNotifications,
				notificationExpand,
				setNotificationExpand,
				favorites,
				getFavorites,
				getNotifications,
				skipNavigationId,
				setSkipNavigationId,
				complexityTag,
				setComplexityTag,
				solutionMethodsTag,
				setSolutionMethodsTag,
				phasesTag,
				setPhasesTag,
				activitiesTag,
				setActivitiesTag,
				rolesTag,
				setRolesTag,
				processGroupFilterTag,
				setProcessGroupFilterTag,
				toolsTag,
				setToolsTag,
				knowledgeAreaTag,
				setKnowledgeAreaTag,
				selectedProcedures,
				setSelectedProcedures,
				dynamicFilterTag,
				setDynamicFilterTag,
				dynamicFilterTagMapping,
				setDynamicFilterTagMapping,
				nameIdMapping,
				setNameIdMapping,
				selectedTab,
				setSelectedTab,
				tutorialDynamicFilterTag,
				setTutorialDynamicFilterTag,
				tutorialDynamicFilterTagMapping,
				setTutorialDynamicFilterTagMapping,
				getImageBase64,
				pendingAnnouncements,
				setPendingAnnouncements,
				phaseExpand,
				setPhaseExpand,
				getNewAnnouncements,
				isAdmin,
				setIsAdmin,
				userIsAdmin,
				getSolutionMethodsByProjectId,
				phaseDynamicFilterTag,
				setPhaseDynamicFilterTag,
				phaseDynamicFilterTagMapping,
				setPhaseDynamicFilterTagMapping,
				endUserView,
				setEndUserView,
				showRaciViewBanner,
				showSupportBanner,
				userIsOwner,
				applyfilterTrigger,
				setApplyFilterTrigger,
				setUserFilter,
				userFilter,
				userActivityFilter,
    setUserActivityFilter
			}}
		>
			{children}
		</LeftMenuCollapseContext.Provider>
	);
};

const useLeftMenuCollapse = () => useContext(LeftMenuCollapseContext);

export { LeftMenuCollapseProvider, useLeftMenuCollapse };
