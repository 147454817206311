import React, { useState, useEffect, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { Icon } from "@fluentui/react/lib/Icon";
import { toast } from "react-toastify";
import HashtagSuggestions from "../HashtagSuggestions";
import { useAxios } from "../../context/AxiosContext";
import { useAppInsights } from "../../context/TelemetryContext";
import { titleValidator } from "../../helpers/Validation";
import { templateFileTypes } from "../../static/TemplateIcons";
import MultiSelectDropdown from "./MultiSelectDropdown";
import Spinner from "../../helpers/Spinner";
import UploadSpinner from "../../helpers/CrowdSourcingSpinner";
import Dropdown from "./Dropdown";

import "./TemplateActivityLink.css";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  padding: "135px 10px",
  marginBottom: "15px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#F0F2F8",
  outline: "none",
  transition: "border .24s ease-in-out",
  width: "100%",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const TemplateNewBuilder = ({
  showNewTemplateBuilder,
  setShowNewTemplateBuilder,
  complexityId,
}) => {
  const [templateFileSize, setTemplateFileSize] = useState(0);
  const {
    getRootProps,
    getInputProps,
    open,
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
    rejectedFiles,
  } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    maxSize: templateFileSize,
  });
  const projectTypeData = [
    {
      id: 1,
      name: "Consulting",
    },
    {
      id: 2,
      name: "Support",
    },
  ];
  const { axiosGetService, axiosPostService, userAlias, axiosPostServiceSwimlane } = useAxios();
  const { track } = useAppInsights();
  const [errorMessage, setErrorMessage] = useState(false);
  const [templateTitle, setTemplateTitle] = useState("");
  const [userAddedHashtags, setUserAddedHashtags] = useState([]);
  const [hashtagSuggestions, setHashtagSuggestions] = useState([]);
  const [hashtagSuggestionInput, setHashtagSuggestionInput] = useState("");
  const [hashtagsInput, setHashtagsInput] = useState("");

  const [templateFile, setTemplateFile] = useState(null);
  const [fileTypeError, setFileTypeError] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const [selectedProjectType, setSelectedProjectType] = useState([]);

  const [activitySearchValue, setActivitySearchValue] = useState("");
  const [availableActivities, setAvailableActivities] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState([]);

  //solution method state
  const [selectedSolutionMethods, setSelectedSolutionMethods] = useState([]);
  const [solutionMethods, setSolutionMethods] = useState([]);

  // phase state
  const [phasesSearchValue, setPhasesSearchValue] = useState("");
  const [selectedPhases, setSelectedPhases] = useState([]);
  const [phases, setPhases] = useState([]);

  const [showFilter, setShowFilter] = useState(true);
  const [showUploadSpinner, setShowUploadSpinner] = useState(false);

  const [focus, setFocus] = useState(null);
  const [description, setDescription] = useState("");
  const [templateType, setTemplateType] = useState(null);
  const [focusTypes, setFocusTypes] = useState(null);
  const [templateTypes, setTemplateTypes] = useState(null);
  const [updateApiCall, setUpdateApiCall] = useState(false);

  const hashtagsAutoComplete = async () => {
    await axiosGetService(
      `api/sdmhashtags/autocomplete?hashTag=${encodeURIComponent(
        hashtagSuggestionInput
      )}`
    ).then((response) => {
      setHashtagSuggestions(response.data);
    });
  };

  const getFileSize = async () => {
    const response = await axiosGetService(
      `api/sdmsettings?sdmKey=TemplateFileSize`
    );
    setTemplateFileSize(response.data[0].value * 1048576);
  };

  const filterFileType = (file) => {
    if (file.name.split(".").length > 2) {
      setFileTypeError(true);
    }
    else {
      const selectedFileType = file.name.split(".").slice(-1).pop().toLowerCase();
      if (templateFileTypes.includes(selectedFileType)) {
        setTemplateFile(file);
        setFileTypeError(false);
        setSizeError(false);
        setErrorMessage(null);
      } else {
        setTemplateFile(null);
        setFileTypeError(true);
        setSizeError(false);
        setErrorMessage(null);
      }
    }
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject]
  );

  const getFocusandTempalteTypes = async () => {
    const response = await axiosGetService(
      `/api/sdmtemplate/templates/?pageSize=10&pageNumber=1&searchValue=""&id=0&complexityId=0`
    );
    setFocusTypes(response.data.focus);
    setTemplateTypes(response.data.type);
  };

  const findSelectedItem = (value, item) => {
    const filteredData = value.filter(({ id }) => id === item);
    if (filteredData.length) {
      return true;
    }
    return false;
  };

  const checkSelectedActivity = (value, item) => {
    const filteredData = value.filter(({ activityId }) => activityId === item);
    if (filteredData.length) {
      return true;
    }
    return false;
  };

  const removeSelectedItem = (value, setValue, item) => {
    const filteredData = value.filter(({ activityId }) => activityId !== item);
    setValue(filteredData);
  };

  const getSolutionMethods = async () => {
    const selecteProject = selectedProjectType.map(({ id }) => {
      return id;
    });
    await axiosGetService(
      `/api/sdmsolutionmethod/methodsbytypeforfilters?methodTypeId=${selecteProject.length === 1 ? selecteProject.toString() : 0
      }&isSvgRequired=${false}`
    ).then((response) => {
      const methods = response.data.map((item) => {
        return {
          id: item.solutionMethodId,
          name: item.solutionMethodName,
        };
      });
      const filterTag = selectedSolutionMethods.filter(({ id }) =>
        findSelectedItem(methods, id)
      );
      if (
        JSON.stringify(filterTag) !== JSON.stringify(selectedSolutionMethods)
      ) {
        setSelectedSolutionMethods(filterTag);
      }
      setSolutionMethods(methods);
    });
  };

  const getPhases = async () => {
    const solutionMethodsTag = selectedSolutionMethods.map(({ id }) => {
      return id;
    });
    await axiosGetService(
      `api/sdmphase/getphases?searchValue=${phasesSearchValue.trim().length > 0
        ? encodeURIComponent(phasesSearchValue)
        : 0
      }&methodIds=${solutionMethodsTag.length > 0 ? solutionMethodsTag.toString() : 0
      }&top=${0}`
    ).then((response) => {
      const data = response.data.phases.map((item) => {
        return {
          name: item.phaseName,
          id: item.phaseId,
        };
      });

      const filterTag = selectedPhases.filter(({ id }) =>
        findSelectedItem(data, id)
      );
      if (JSON.stringify(filterTag) !== JSON.stringify(selectedPhases)) {
        setSelectedPhases(filterTag);
      }
      setPhases(data);
    });
  };

  const getActivity = async () => {
    const phaseIds = selectedPhases.map(({ id }) => {
      return id;
    });
    const methods = selectedSolutionMethods.map(({ id }) => {
      return id;
    });
    await axiosGetService(
      `api/sdmactivity/getactivitiesbymethodandphase?searchValue=${activitySearchValue ? activitySearchValue : 0
      }&methodIds=${methods.length ? methods.toString() : 0}&phaseIds=${phaseIds.length ? phaseIds.toString() : 0
      }&complexityId=${complexityId}`
    ).then((response) => {
      const data = response.data.filter((item) => item.isContributionAvailable);
      setAvailableActivities(data);
      setShowSpinner(false);
    });
  };

  const resetStateForTemplateUpload = () => {
    setTemplateTitle("");
    setTemplateFile(null);
    setUserAddedHashtags([]);
  };

  const submitTemplate = async (templateFileType, templateURL) => {
    const suggestionActivityIds = selectedActivity.map(({ id }) => {
      return id;
    });
    const postData = {
      title: templateTitle,
      hashTags: userAddedHashtags.toString(),
      complexityId: complexityId,
      filePath: templateURL,
      fileType: templateFileType,
      contributorEmail: userAlias,
      suggestionActivityIds: suggestionActivityIds.toString(),
      submitSuggestion: true,
      description: description,
      templateType,
      focus,
    };

    await axiosPostService(
      `api/sdmtemplate/addactivitytemplatecontribution`,
      postData
    ).then((response) => {
      if (response.data.status) {
        track(1, 1902, "Template Landing Page", "Activity Template Linking", {
          templates: suggestionActivityIds.toString(),
        });
        resetStateForTemplateUpload();
        toast.info("Your contribution is successfull", {
          position: "top-right",
        });
        setShowNewTemplateBuilder(false);
      } else {
        toast.error(response.data.errorMsg, {
          position: "top-right",
        });
      }
      setShowUploadSpinner(false);
    });
  };

  const uploadTemplate = async () => {
    if (templateFile && templateTitle && selectedActivity.length > 0) {
      setShowUploadSpinner(true);
      const postData = new FormData();
      const fileName = templateFile.name;
      const fileExtension = fileName.split('.').pop(); 
      postData.append("fileLocation", templateFile,`${templateTitle}.${fileExtension}`);
      //postData.append("fileLocation", templateFile);
      await axiosPostServiceSwimlane(`api/sdmtemplate/uploadtemplate`, postData).then(
        (response) => {
          if (response.data.status === "false") {
            toast.error(response.data.errorMessage, {
              position: "top-right",
            });
            setShowUploadSpinner(false);
          } else if (response.data) {
            submitTemplate(
              response.data.templateType,
              response.data.templateURL
            );
          } else {
            toast.error("Template upload failed", {
              position: "top-right",
            });
            setShowUploadSpinner(false);
          }
        }
      );
    } else {
      toast.error("Please add title, select a file  and activities to submit", {
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      getSolutionMethods();
    }, 1000);
    return () => {
      clearTimeout(timeOutId);
    };
  }, [selectedProjectType]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      getPhases();
    }, 1000);
    return () => {
      clearTimeout(timeOutId);
    };
  }, [phasesSearchValue, selectedSolutionMethods]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setShowSpinner(true);
      getActivity();
    }, 1000);
    return () => {
      setShowSpinner(false);
      clearTimeout(timeOutId);
    };
  }, [activitySearchValue, selectedSolutionMethods, selectedPhases]);
  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (hashtagSuggestionInput.trim()) {
        hashtagsAutoComplete();
      }
    }, 1000);

    return () => {
      setHashtagSuggestions([]);
      clearTimeout(timeOutId);
    };
  }, [hashtagSuggestionInput]);

  useEffect(() => {
    getFileSize();
    getFocusandTempalteTypes();
  }, []);

  useEffect(() => {
    if (rejectedFiles.length && rejectedFiles[0].size > templateFileSize) {
      setFileTypeError(false);
      setSizeError(true);
      setErrorMessage(null);
    } else if (rejectedFiles.length) {
      filterFileType(rejectedFiles[0]);
    }
  }, [rejectedFiles]);

  useEffect(() => {
    if (acceptedFiles.length == 0) {
      setTemplateFile(null);
    } else if (
      acceptedFiles.length &&
      acceptedFiles[0].size > templateFileSize
    ) {
      setFileTypeError(false);
      setSizeError(true);
      setErrorMessage(null);
    } else {
      filterFileType(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  return (
    <>
      {showUploadSpinner ? (
        <UploadSpinner />
      ) : (
        <Modal
          className="phaseCreationRightPopup addNewTemplate" id="submitNewtemp"
          show={showNewTemplateBuilder}
        >
          <Modal.Header>
            <Modal.Title>Submit New Template </Modal.Title>
            <Icon
              iconName="ChromeClose"
              title="Close"
              onClick={() => {
                setShowNewTemplateBuilder(false);
              }}
              onKeyPress={(e) => {
                if (e.which === 13) {
                  setShowNewTemplateBuilder(false);
                }
              }}
              tabIndex="0"
              role="button"
              aria-label="Close Add Template dialog"
            />
          </Modal.Header>
          <Modal.Body>
            <div className="methodOwnerMainContainer">
              <div className="methodOwnerPage">
                <div className="methodOwnerContainer">
                  <div className="middleDetailsContainer">
                    <div className="methodOwnerMiddleContainer">
                      <div className="collabaratorsParentContainers">
                        <h6>Template Title</h6>
                        <div>
                          <input
                            placeholder="Enter Title"
                            aria-label="Enter Title"
                            value={templateTitle}
                            onChange={(e) => {
                              setTemplateTitle(e.target.value);
                            }}
                          />
                        </div>
                        {templateTitle &&
                          !titleValidator.test(templateTitle) ? (
                          <p className="errorMsg">Invalid title</p>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="collabaratorsParentContainers">
                        <h6>Add #hashtags</h6>

                        <HashtagSuggestions
                          className="templateActivityLinking"
                          enableDropdown={true}
                          data={hashtagSuggestions}
                          setUpdateApiCall={setUpdateApiCall}
                          setHashtagSuggestions={setHashtagSuggestions}
                          searchValue={hashtagsInput}
                          setSearchValue={setHashtagsInput}
                          existingHashtags={[]}
                          userAddedHashtags={userAddedHashtags}
                          setUserAddedHashtags={setUserAddedHashtags}
                          hashtagSuggestionInput={hashtagSuggestionInput}
                          setHashtagSuggestionInput={setHashtagSuggestionInput}
                          closeHashtagForm={() => {
                            //do nothing
                          }}
                          submitHashtags={() => {
                            //do nothing
                          }}
                          autoFocus={false}
                          showSubmitButton={false}
                          pageType={'submitNewTemplate'}
                        />
                      </div>
                      <div className="collabaratorsParentContainers">
                        <h6>Template Description</h6>
                        <div>
                          <textarea
                            placeholder="Enter Description"
                            aria-label="Enter Description"
                            value={description}
                            onChange={(e) => {
                              setDescription(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="templateFocusTypeDiv">
                        <div className="collabaratorsParentContainers">
                          <h6>Template Type</h6>
                          <div>
                            <Dropdown
                              className="topicType"
                              data={templateTypes}
                              value={templateType}
                              setValue={setTemplateType}
                              placeholder={"Select Type"}
                              enableDropdown={true}
                              key="type"
                            />
                          </div>
                        </div>
                        <div className="collabaratorsParentContainers">
                          <h6>Template Focus</h6>
                          <Dropdown
                            className="topicCategory"
                            data={focusTypes}
                            value={focus}
                            setValue={setFocus}
                            placeholder={"Select Focus"}
                            enableDropdown={true}
                            key="focus"
                          />
                        </div>
                      </div>

                      <div
                        {...getRootProps({ style })}
                        className="addtemplateDragDrop"
                      >
                        <input {...getInputProps()} />
                        {templateFile === null ? (
                          <div>
                            <span>
                              Drag and drop a file here <br /> or
                            </span>
                            <button
                              title="Choose File"
                              className="dragDropButton"
                              type="button"
                              onClick={open}
                              tabIndex={0}
                              onKeyPress={(e) => {
                                if (e.which === 13) {
                                  open();
                                }
                              }}
                            >
                              Choose File
                            </button>
                          </div>
                        ) : (
                          <>
                            <div className="templateContributionFileNameContainer">
                              <div
                                key={templateFile.path}
                                className="templateContributionFileName"
                              >
                                {templateFile.path}
                                <button
                                  onClick={() => setTemplateFile(null)}
                                  tabIndex={0}
                                  onKeyPress={(e) => {
                                    if (e.which === 13) {
                                      setTemplateFile(null);
                                    }
                                  }}
                                  title="Remove Uploaded File"
                                  role="Button"
                                  aria-label="Remove"
                                >
                                  X
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      {errorMessage ? (
                        <span className="errorMsg">All input are required</span>
                      ) : (
                        <></>
                      )}
                      {sizeError ? (
                        <span className="errorMsg">
                          File should be less than {templateFileSize / 1048576}{" "}
                          MB
                        </span>
                      ) : (
                        <></>
                      )}
                      {fileTypeError ? (
                        <span className="errorMsg">
                          Invalid file Format. Only "xlsx, pptx, potx, ppt, pdf,
                          doc, docx, dotx, xlsm, msg, mpp, mpt" are
                          allowed.
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="methodOwnerMiddleContainer">
                      <div className="activityAdditionCont referActivityContainer">
                        <div className="cloneActivityButtonAction">
                          <div className="addActivityFields">
                            <div className="cloneActivityContainer">
                              <div className="cloneActivityFieldsWrapper">
                                <input
                                  title={activitySearchValue}
                                  placeholder="Activity name"
                                  value={activitySearchValue}
                                  onChange={(e) =>
                                    setActivitySearchValue(e.target.value)
                                  }
                                />
                                <Icon iconName="FilterSolid" />
                                <Icon
                                  iconName={
                                    showFilter
                                      ? "CaretUpSolid8"
                                      : "CaretDownSolid8"
                                  }
                                  onClick={() => {
                                    setShowFilter(!showFilter);
                                  }}
                                  className="cloneDropDownArrow"
                                  onKeyPress={(e) => {
                                    if (e.which === 13) {
                                      setShowFilter(!showFilter);
                                    }
                                  }}
                                  tabIndex="0"
                                  title="Filters Dropdown"
                                  role="button"
                                  aria-label={
                                    showFilter
                                      ? "Collapse to hide filters"
                                      : "Expand to show filters"
                                  }
                                  aria-expanded={showFilter ? "false" : "true"}
                                />
                              </div>
                            </div>
                            <div
                              className={`cloneActivityFiltersWrapper ${!showFilter
                                  ? "hideTemplateUploadFilter"
                                  : "none"
                                }`}
                            >
                              <div>
                                <div className="cloneActivitySolTypeFilters">
                                  <div className="hidingConsultingSupport">
                                    <span>Consulting / Support</span>
                                    <MultiSelectDropdown
                                      className="activityReferProjectSelectionDropdown"
                                      data={projectTypeData}
                                      setValue={setSelectedProjectType}
                                      value={selectedProjectType}
                                      enableDropdown={true}
                                    />
                                  </div>

                                  <div className="multiSelectdropDown CloseMultiSelectDp">
                                    <span>Solution Methods</span>
                                    <MultiSelectDropdown
                                      className="activityReferSolutionMethodDropdown templateReferSolutionMethodDropdown"
                                      data={solutionMethods}
                                      setValue={setSelectedSolutionMethods}
                                      value={selectedSolutionMethods}
                                      enableDropdown={true}
                                    />
                                  </div>
                                </div>
                                <div className="cloneActivitySolTypeFilters">
                                  <div className="multiSelectdropDown CloseMultiSelectDp">
                                    <span>Phases</span>
                                    <MultiSelectDropdown
                                      className="activityReferSolutionMethodphasesDropdown templateReferSolutionMethodDropdown"
                                      data={phases}
                                      setValue={setSelectedPhases}
                                      value={selectedPhases}
                                      enableDropdown={true}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="AddActivityCloneButtonSection actvityAddResultsButton">
                                {!showSpinner && (
                                  <h6>
                                    {availableActivities.length} Result(s) Found
                                  </h6>
                                )}
                              </div>
                            </div>
                            <div className="cloneActivityResultsWrapper">
                              {showSpinner ? (
                                <Spinner />
                              ) : (
                                <>
                                  {availableActivities.length ? (
                                    <>
                                      <ul>
                                        {showMore ? (
                                          <>
                                            {availableActivities.map((item) => (
                                              <li
                                                key={
                                                  item.id +
                                                  item.name +
                                                  item.activityId
                                                }
                                              >
                                                <label
                                                  className="NotificationChkboxContainer"
                                                  title={item.name}
                                                >
                                                  <input
                                                    name={item.name}
                                                    type="checkbox"
                                                    onChange={() => {
                                                      if (
                                                        checkSelectedActivity(
                                                          selectedActivity,
                                                          item.id
                                                        )
                                                      ) {
                                                        removeSelectedItem(
                                                          selectedActivity,
                                                          setSelectedActivity,
                                                          item.id
                                                        );
                                                      } else {
                                                        setSelectedActivity([
                                                          ...selectedActivity,
                                                          {
                                                            ...item,
                                                            activityId: item.id,
                                                          },
                                                        ]);
                                                      }
                                                    }}
                                                    checked={checkSelectedActivity(
                                                      selectedActivity,
                                                      item.id
                                                    )}
                                                  />
                                                  <span className="CustomCheckmark"></span>
                                                  <span className="complexityLabel cloneActivityTitle">
                                                    {item.name}
                                                  </span>
                                                </label>
                                                <div className="cloneInfiActivity">
                                                  <Icon
                                                    iconName="Info"
                                                    tabIndex={0}
                                                  />
                                                  <div className="tabsTitleToolTip">
                                                    <span className="tabsToolTipArrow"></span>
                                                    <h5>Activity Details</h5>
                                                    <h6>
                                                      {item.projectTypeName}
                                                    </h6>
                                                    <span className="detailsOfCloneActivity">
                                                      <span className="detailsCloneActivitySol">
                                                        <span>
                                                          Delivery Method
                                                        </span>
                                                        :&nbsp;
                                                        <span>
                                                          {
                                                            item.solutionMethodName
                                                          }
                                                        </span>
                                                      </span>
                                                      <span className="detailsCloneActivitySol">
                                                        <span>Phase</span>
                                                        :&nbsp;
                                                        <span>
                                                          {item.phaseTitle}
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                </div>
                                              </li>
                                            ))}
                                          </>
                                        ) : (
                                          <>
                                            {availableActivities
                                              .slice(
                                                0,
                                                availableActivities.length > 10
                                                  ? 10
                                                  : availableActivities.length
                                              )
                                              .map((item) => (
                                                <li
                                                  key={
                                                    item.id +
                                                    item.name +
                                                    item.activityId
                                                  }
                                                >
                                                  <label
                                                    className="NotificationChkboxContainer"
                                                    title={item.name}
                                                  >
                                                    <input
                                                      name={item.name}
                                                      type="checkbox"
                                                      onChange={() => {
                                                        if (
                                                          checkSelectedActivity(
                                                            selectedActivity,
                                                            item.id
                                                          )
                                                        ) {
                                                          removeSelectedItem(
                                                            selectedActivity,
                                                            setSelectedActivity,
                                                            item.id
                                                          );
                                                        } else {
                                                          setSelectedActivity([
                                                            ...selectedActivity,
                                                            {
                                                              ...item,
                                                              activityId:
                                                                item.id,
                                                            },
                                                          ]);
                                                        }
                                                      }}
                                                      checked={checkSelectedActivity(
                                                        selectedActivity,
                                                        item.id
                                                      )}
                                                    />
                                                    <span className="CustomCheckmark"></span>
                                                    <span className="complexityLabel cloneActivityTitle">
                                                      {item.name}
                                                    </span>
                                                  </label>

                                                  <div className="cloneInfiActivity">
                                                    <Icon
                                                      iconName="Info"
                                                      tabIndex={0}
                                                    />
                                                    <div className="tabsTitleToolTip">
                                                      <span className="tabsToolTipArrow"></span>
                                                      <h5>Activity Details</h5>
                                                      <h6>
                                                        {item.projectTypeName}
                                                      </h6>
                                                      <span className="detailsOfCloneActivity">
                                                        <span className="detailsCloneActivitySol">
                                                          <span>
                                                            Delivery Method
                                                          </span>
                                                          :&nbsp;
                                                          <span>
                                                            {
                                                              item.solutionMethodName
                                                            }
                                                          </span>
                                                        </span>
                                                        <span className="detailsCloneActivitySol">
                                                          <span>Phase</span>
                                                          :&nbsp;
                                                          <span>
                                                            {item.phaseTitle}
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </div>
                                                </li>
                                              ))}
                                          </>
                                        )}
                                      </ul>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {availableActivities.length > 10 &&
                                    !showMore ? (
                                    <span
                                      className="closeShowMoreLess"
                                      onClick={() => setShowMore(true)}
                                      onKeyPress={(e) => {
                                        if (e.which === 13) {
                                          setShowMore(true);
                                        }
                                      }}
                                      tabIndex="0"
                                      role="button"
                                      aria-label="Click to Show more Activities"
                                    >
                                      Show more
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                  {showMore ? (
                                    <span
                                      className="closeShowMoreLess"
                                      onClick={() => setShowMore(false)}
                                      onKeyPress={(e) => {
                                        if (e.which === 13) {
                                          setShowMore(false);
                                        }
                                      }}
                                      tabIndex="0"
                                      role="button"
                                      aria-label="Click to Show Less Activities"
                                    >
                                      Show less
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="SaveContinueTemplateAddId"className="saveContinueDetailsContainer">
                    <button
                      className="forwardButton"
                      onClick={() => {
                        uploadTemplate();
                      }}
                      tabIndex="0"
                      role="button"
                      aria-label="Save Template"
                    >
                      <span className="saveContinueButton">Submit</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default TemplateNewBuilder;
