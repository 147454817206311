import React, { useState, useEffect } from 'react';
import { Icon } from '@fluentui/react';
import { toast } from 'react-toastify';
import TemplateTypeDropdown from '../Dropdown';
import ComplexityDropdown from './ComplexityDropdown';
import TemplateDropdown from './MultiSelectionDropdown';
import { useAxios } from '../../context/AxiosContext';
import { Modal } from 'react-bootstrap';
import {
	templateTypeData,
	templateTypeDataMapper,
	templateFocusData,
	templateFocusDataMapper,
} from '../../static/ActivityEdit';
import { hashtagValidator } from '../../helpers/Validation';
import HashtagSuggestions from '../HashtagSuggestions';

const TemplatesEdit = ({
	value,
	phases,
	template,
	setValue,
	complexityList,
	templatesMapper,
	setTemplatesMapper,
	selectedComplexity,
	phaseSearchValue,
	setPhaseSearchvalue,
	defaultTemplateType,
	setUpdateTemplateId,
	phaseId,
	activityId,
	selectedPhase
}) => {
	const titleValidator = new RegExp('^[a-zA-Z0-9s /&,_-]+$');
	const { axiosPostService, axiosGetService } = useAxios();
	const [existingTemplateData, setExistingTemplateData] = useState({});
	const [templateName, setTemplateName] = useState('');
	const [isInProgress, setIsInProgress] = useState(false);
	const [templateDescription, setTemplateDescription] = useState('');
	const [hashtagsInput, setHashtagsInput] = useState('');
	const [hashtags, setHashtags] = useState([]);
	const [templateType, setTemplateType] = useState(defaultTemplateType);
	const [templateFocus, setTemplateFocus] = useState(defaultTemplateType);
	const [selectedComplexities, setSelectedComplexities] = useState([
		{ id: selectedComplexity },
	]);
	const [selectedPhases, setSelectedPhases] = useState([]);
	const [hashtagSuggestions, setHashtagSuggestions] = useState([]);
	const [isDrafted, setisDrafted] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);
	const [hashtagSuggestionInput, setHashtagSuggestionInput] = useState('');
	const [userAddedHashtags, setUserAddedHashtags] = useState([]);
	const [updateApiCall, setUpdateApiCall] = useState(false);

	const hashtagsAutoComplete = async () => {
		await axiosGetService(
			`api/sdmhashtags/autocomplete?hashTag=${encodeURIComponent(
				hashtagSuggestionInput
			)}`
		).then((response) => {
			setHashtagSuggestions(response.data);
		});
	};

	const removeHashtag = (tag) => {
		const updatedHashtag = hashtags.filter((item) => tag !== item);
		setHashtags(updatedHashtag);
	};

	const updateHashtags = () => {
		setHashtags([...userAddedHashtags, ...hashtags]);
		setHashtagSuggestionInput('');
		setUserAddedHashtags([]);
	};
	const updateTemplate = async () => {
		if (
			templateName.trim().length === 0 ||
			selectedComplexities.length === 0 ||
			selectedPhases.length === 0 ||
			templateType.id === 0 ||
			templateFocus.id === 0
		) {
			setErrorMessage(true);
		} else {
			const complexitiesIds = selectedComplexities.map((item) => {
				return item.id;
			});
			const phasesIds = [Number(phaseId)]
			setErrorMessage(false);
			const postData = {
				id: template.id,
				title: templateName,
				focus: templateFocus.name,
				templatePhaseIds: phasesIds,
				copyToComplexityIds: complexitiesIds,
				inProgress: isInProgress,
				description: templateDescription,
				hashTags: hashtags.toString(),
				isDrafted: isDrafted,
				templateType: templateType.name,
				fileLocation: existingTemplateData.fileLocation,
				uniqueId: existingTemplateData.uniqueId,
				fileType: existingTemplateData.fileType,
				templateDraftId: existingTemplateData.templateDraftId,
				activityIdentifier: activityId,
			};
			await axiosPostService(
				`api/sdmtemplate/updateactivitytemplate`,
				postData
			).then((response) => {
				if (!response.data.status) {
					toast.error(response.data.errorMsg, {
						position: toast.POSITION.TOP_RIGHT,
					});
				} else {
					setValue((prevState) => ({
						...prevState,
						[selectedComplexity]: {
							...value[selectedComplexity],
							templatesList: [
								...value[selectedComplexity].templatesList,
								{
									name: templateName,
									fileLocation: response.data.fileLocation,
									id: response.data.templateId,
									previewUrl: response.data.previewUrl,
								},
							],
						},
					}));
					setTemplatesMapper((prevState) => ({
						...prevState,
						[response.data.templateId]: {
							name: templateName,
							fileLocation: response.data.fileLocation,
							id: response.data.templateId,
							previewUrl: response.data.previewUrl,
							fileType: response.data.fileType,
							inProgress: isInProgress,
						},
					}));
					setUpdateTemplateId(0);
					setUserAddedHashtags([]);
					toast.info('Tool Updated successfull', {
						position: 'top-right',
					});
				}
			});
		}
	};

	const getTemplateData = async () => {
		await axiosGetService(
			`api/sdmtemplate/getactivitytemplatebyid/${template.id}/${selectedComplexity}`
		).then((response) => {
			setExistingTemplateData(response.data);
			setTemplateName(response.data.title);
			setIsInProgress(response.data.inProgress);
			setTemplateType({
				id: templateTypeDataMapper[response.data.templateType],
				name: response.data.templateType,
			});
			setTemplateFocus({
				id: templateFocusDataMapper[response.data.focus],
				name: response.data.focus,
			});
			const existingHashtags = response.data.hashTagsList.map(({ name }) => {
				return name;
			});
			setHashtags(existingHashtags);
			if (response.data.tileDescription) {
				setTemplateDescription(response.data.tileDescription);
			}
			if (response.data.templatePhaseIds) {
				const existingPhaseIds = response.data.templatePhaseIds
					.split(',')
					.filter((id) => typeof Number(id) === 'number')
					.map((item) => {
						return {
							id: Number(item),
						};
					});
				setSelectedPhases(existingPhaseIds);
			}
			setisDrafted(response.data.isDrafted);
		});
	};

	useEffect(() => {
		getTemplateData();
	}, []);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (hashtagSuggestionInput.trim()) {
				hashtagsAutoComplete();
			}
		}, 1000);

		return () => {
			setHashtagSuggestions([]);
			clearTimeout(timeOutId);
		};
	}, [hashtagSuggestionInput]);

	return (

		<Modal
			size="lg"
			centered
			show={true}
			onHide={() => {
				/* Not calling any function to close modal*/
			}}
			dialogClassName="modal-90w"
		>
			<Modal.Header>
				<Modal.Title>
					<div className="polygonTopicModalRelatedContenTitle">
						<span className='newToolDialogHeading'>Edit Template</span>
					</div>
				</Modal.Title>
				<Icon
					className="relatedContentClose"
					iconName="ChromeClose"
					title="Close"
					onClick={() => {
						// setShowTemplateForm(false);
						setUpdateTemplateId(0);
						setUserAddedHashtags([]);
					}}
				/>
			</Modal.Header>
			<Modal.Body className="modal-body-acitivity-scrollable">
				<div className="addPolygonTopicContenUrlContainer">

					<div className="addNewTemplatesToActivitynew pt-0">
						<div className='addNewTemplatesFieldcheckbox'>
							<label class="NotificationChkboxContainer">
								<input
									className='templateInnerContent'
									name="Critical"
									type="checkbox"
									checked={isInProgress}
									onChange={() => {
										setIsInProgress(!isInProgress);
									}}
								/>
								<span class="CustomCheckmark"></span>
								<span class="complexityLabel">Output without template</span>
							</label>
						</div>
						<div className="addNewTemplatesFieldsName">
							<div className="addNewTemplatesNameTitle">
								<div className="addNewTemplatesWithOutOutputdata">
									<span>Template Name</span>
								</div>
								<input
									placeholder="Template name"
									value={templateName}
									onChange={(e) => {
										setTemplateName(e.target.value);
									}}
								/>

								{templateName && !titleValidator.test(templateName) ? (
									<p className="errorMsg">Invalid name</p>
								) : (
									<></>
								)}
							</div>
						</div>

						<div className="addNewTemplatesFields">
							<div>
								<span className='addNewTemplateFieldsLabel'>Template Type</span>
								<TemplateTypeDropdown
									className="templateEditTypeDropdown"
									data={templateTypeData}
									enableDropdown={true}
									value={templateType}
									setValue={setTemplateType}
								/>
							</div>
							<div>
								<span className='addNewTemplateFieldsLabel'>Template Focus</span>
								<TemplateTypeDropdown
									className="templateEditFocusDropdown"
									data={templateFocusData}
									enableDropdown={true}
									value={templateFocus}
									setValue={setTemplateFocus}
								/>
							</div>
						</div>
						<div className="addNewTemplatesFields">
							<div>
								<span className='addNewTemplateFieldsLabel'>Complexity</span>
								<ComplexityDropdown
									className="complexityDropdown"
									enableDropdown={true}
									data={complexityList}
									value={selectedComplexities}
									setValue={setSelectedComplexities}
								/>
							</div>
							<div>
								<span className='addNewTemplateFieldsLabel'>Phase</span>
								<TemplateDropdown
									className="phasesDropdown"
									hidePhaseDropDown={'hidePhaseDropDown'}
									data={phases}
									enableDropdown={true}
									searchValue={phaseSearchValue}
									setSearchValue={setPhaseSearchvalue}
									updatedValue={selectedPhases}
									setUpdatedValue={setSelectedPhases}
									label="Phases"
								/>
								<div className='deliverablesPhaseContainer'>
									<input type='text' value={selectedPhase.length > 0 ? selectedPhase[0].name : ''} disabled />
								</div>
							</div>
						</div>
						<div className="addNewTemplatesFields">
							<div className="addNewTemplatesDescription">
								<span className='addNewTemplateFieldsLabel'>Template Description</span>
								<textarea
									value={templateDescription}
									onChange={(e) => setTemplateDescription(e.target.value)}
								/>
							</div>
						</div>
						<div className="addNewTemplatesFields addNewTemplatesFieldsHashTags mt-0">
							<div className="addNewTemplatesDescription addNewActivityHashtags">
								<span className='addNewTemplateFieldsLabel'>Add Hashtags</span>

								<HashtagSuggestions
									className="topicsHashtagsInput"
									enableDropdown={true}
									data={hashtagSuggestions}
									setUpdateApiCall={setUpdateApiCall}
									setHashtagSuggestions={setHashtagSuggestions}
									searchValue={hashtagsInput}
									setSearchValue={setHashtagsInput}
									existingHashtags={hashtags}
									userAddedHashtags={userAddedHashtags}
									setUserAddedHashtags={setUserAddedHashtags}
									hashtagSuggestionInput={hashtagSuggestionInput}
									setHashtagSuggestionInput={setHashtagSuggestionInput}
									closeHashtagForm={() => {
										setHashtagsInput('');
										setUserAddedHashtags([]);
									}}
									submitHashtags={updateHashtags}
									autoFocus={false}
									showSubmitButton={true}
								/>
							</div>
							{hashtags.length > 0 ? (
								<div className="hashTagsContainer">
									{hashtags.map((item) => (
										<span key={item} tabIndex={0}>
											{item}{' '}
											<Icon
												iconName="Cancel"
												onClick={() => removeHashtag(item)}
												onKeyPress={(e) => {
													if (e.which === 13) {
														removeHashtag(item);
													}
												}}
												tabIndex="0"
												aria-label={`click to Remove ${item} Hashtag`}
												title="Cancel"
												role="Button"
											/>
										</span>
									))}
								</div>
							) : (
								<></>
							)}
						</div>
						{errorMessage && isInProgress ? (
							<span className="errorMsg">All input are required</span>
						) : (
							<></>
						)}
					</div>


				</div>
			</Modal.Body>
			<Modal.Footer className='addToolModalFooter'>
				<div className="AddTopicFieldContentButtons addMarginBusinnessRelative">

					<button
						disabled={false}
						title="Cancel"
						className="cancelBusinessContent  mr-2"
						type="button"
						onClick={() => {
							setUpdateTemplateId(0);
							setUserAddedHashtags([]);
						}}
						tabIndex={0}
						onKeyPress={(e) => {
							if (e.which === 13) {
								setUpdateTemplateId(0);
								setUserAddedHashtags([]);
							}
						}}
					>
						Close
					</button>


					<button
						title="Save"
						className="addBtnBusinessContent"
						type="button"
						disabled={
							!titleValidator.test(templateName) ||
							templateName.trim().length === 0
						}
						onClick={() => updateTemplate()}
						tabIndex={0}
						onKeyPress={(e) => {
							if (e.which === 13) {
								updateTemplate();
							}
						}}
					>
						Save
					</button>
				</div>
			</Modal.Footer>
		</Modal>


	);
};

export default TemplatesEdit;
