import React, { useState, useEffect,useRef } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useParams, Link, useRouteMatch, useHistory } from "react-router-dom";
import queryString from "query-string";
import { toast } from "react-toastify";
import $ from "jquery";
import { MessageBar, MessageBarType } from "@fluentui/react";
import Modal from "react-bootstrap/Modal";
import { HtmlSanitizer } from "../../helpers/HtmlSanitizer";
import { useAuth } from "../../context/UserAuthContext";
import { useAxios } from "../../context/AxiosContext";
import { useConfig } from "../../context/ConfigContext";
import { useAppInsights } from "../../context/TelemetryContext";
import { useLeftMenuCollapse } from "../../context/LeftMenuCollapseContext";
import { getOtherUserProfile } from "../../helpers/GraphService";
import { axiosTokenService } from "../CkEditor/api/axiosBaseUrl";
import HistoryPreview from "../ActivityReviewerCrowdSourcing/HistoricalPreviewPopUp";
import Dropdown from "../Dropdown";
import Breadcrumbs from "../Breadcrumbs";
import ActivityYammer from "../Yammer/ActivityYammer";
import FileDropModal from "../TemplateCard/FileDropModal";
import PreviewModal from "../TemplateCard/PreviewModal";
import SampleTemplateModal from "../TemplateCard/SampleTemplateModal";
import FavoriteAndFeedback from "../FavoriteAndFeedback";
import Spinner from "../../helpers/Spinner";
import { useCrowdSourcing } from "../../context/CrowdSourcingContext";
import DeletedEntityModal from "../DeletedEntity";
import HashtagSuggestions from "../HashtagSuggestions";
import ActivityTemplateBuilder from "../ActivityTemplateBuilder";
import ActivityRaciMatrix from "../ActivityRaciMatrix";
import { artifactMapData } from "../../static/ArtifactMapData";
import { TabTitle } from "../../utils/GeneralFunctions";

import "./Activity.css";
import ActivityCollaborators from "./ActivityCollaborators";

const inValid = ["", null, " ", []];
function Activity({ projectTypeId, solutionMethodId, isEnableComplexity }) {
  const { url } = useRouteMatch();
  const { track } = useAppInsights();

  const {
		activityId,
		complexityIds,
		parentPhaseId,
		phaseId,
		draftId,
		defaultComplexity,
	} = useParams();

  const { user } = useAuth();
  const history = useHistory();
  const { sdm2URL } = useConfig();
  const [reviewerInfo, setReviewerInfo] = useState(null);
  const { crowdSourcingAppState, setCrowdSourcingAppState } =
    useCrowdSourcing();
  const { showHistory } = crowdSourcingAppState;
  const CkEditorCurrentUser = user.profile.mail;
  const [ckEditorUsers, setCkEditorUsers] = useState([]);
  const suggestEditUrl = url.split("/").slice(0, 10).join("/");
  const { axiosGetService, axiosPostService } = useAxios();
  const { breadcrumbs, setBreadcrumbs, setSkipNavigationId } =
    useLeftMenuCollapse();
  const [sampleTemplates, setSampleTemplates] = useState({
    enable: false,
    id: null,
    parentTemplate: null,
  });
  const [showLoader, setShowLoader] = useState(false);
  // template Preview Data
  const [isReviewer, setIsReviewer] = useState(false);
  const [isApprover, setIsApprover] = useState(false);
  const [templatePreviewData, setTemplatePreviewData] = useState(null);
  const [enableTemplatePreview, setEnableTemplatePreview] = useState(false);
  const [enableFileDropModal, setEnableFileDropModal] = useState(false);
  const [toolsUrl, setToolsUrl] = useState({});
  const [isActivityLock, setIsActivityLock] = useState(false);
  const searchComplexityParam = Number(
    queryString.parse(window.location.search).complexity
  );
  const [activityContent, setactivityContent] = useState({});
  const [prevActivityOwner, setPrevActivityOwner] = useState({});
  const [templateData, setTemplateData] = useState([]);
  const [topicData, setTopicData] = useState([]);
  const [procedureData, setProcedureData] = useState([]);
  const [entryCriteriaData, setEntryCriteriaData] = useState([]);
  const [exitCriteriaData, setExitCriteriaData] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [editingStateId, setEditingStateId] = useState(null);
  const [complexities, setComplexities] = useState([]);
  const [contributorsData, setContributorsData] = useState(null);
  const [complexityId, setComplexityId] = useState(searchComplexityParam);
  const [filData, setfilData] = useState({
    name: "",
    type: "option",
    id: "",
  });
  const [complexityEnabled, setComplexityEnabled] = useState(true);
  const contributorInfo = [];
  const graphUserData = [];
  const userDataMap = new Map();
  const [historyToolsId, setHistoryToolsId] = useState([]);
  const [historicalPreviewTools, setHistoricalPreviewTools] = useState([]);
  const [historyToolsSuggestion, setHistoryToolsSuggestion] = useState([]);
  const [toolsReviewedIds, setToolsReviewedIds] = useState({});
  const [ckEditorUserMap, setCkEditorUserMap] = useState({});
  const [showHashtagsForm, setShowHashtagsForm] = useState(false);
  const [existingHashtags, setExistingHashtags] = useState([]);
  const [hashtags, setHashtags] = useState([]);
  const [hashtagsInput, setHashtagsInput] = useState("");
  const [hashtagSuggestions, setHashtagSuggestions] = useState([]);
  const [hashtagSuggestionInput, setHashtagSuggestionInput] = useState("");
  const [userAddedHashtags, setUserAddedHashtags] = useState([]);
  const [solutionMethodContentOwner, setSolutionMethodContentOwner] =
    useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [solutionMethodContentOwnerEmail, setSolutionMethodContentOwnerEmail] =
    useState(false);
  const [activityLockedBy, setActivityLockedBy] = useState("");
  const [lockedByUsername, setLockedByUsername] = useState("");
  const [isProjectDelivery, setIsProjectDelivery] = useState();
  const [showActivityOwnerCollaborators, setShowActivityOwnerCollaborators]=useState(false)
  const [activityOwner,setActivityOwner] = useState({})
  const [existingReviewers, setExistingReviewers] = useState([]);
	const [existingApprovers, setExistingApprovers] = useState([]);
  const [existingCoOwners, setExistingCoOwners] = useState([]);
  const [activityCollaborators, setActivityCollaborators]=useState({})
  const [showCollaboratorSpinner, setShowCollaboratorSpinner] = useState(true);
  const [previousPostData, setPreviousPostData] = useState({});
  const [isAdminOrOwner, setIsAdminOrOwner] = useState(false);
  const [isEdited,setIsEdited] = useState(false);
  const [relatedBusinessContent, setRelatedBusinessContent] = useState([])
  const [updateApiCall, setUpdateApiCall] = useState(false);


  const getProjectDeliveryId = async () => {
    await axiosGetService(
      `api/sdmsettings?sdmKey=SDMNotInProjectDeliveryId`
    ).then((response) => {
      setIsProjectDelivery(response.data[0].value.split(",").at(1));
    });
  };
  useEffect(() => {
    getProjectDeliveryId();
  }, []);

  const [showActivityTemplateBuilder, setShowActivityTemplateBuilder] =
    useState(false);

  const hashtagsAutoComplete = async () => {
    await axiosGetService(
      `api/sdmhashtags/autocomplete?hashTag=${encodeURIComponent(
        hashtagSuggestionInput
      )}`
    ).then((response) => {
      setHashtagSuggestions(response.data);
    });
  };

  const handleTemplatePreview = (data) => {
    setTemplatePreviewData(data);
    setEnableTemplatePreview(true);
  };

  const submitHashtags = async () => {
    if (userAddedHashtags.length > 0) {
      const postData = {
        artifactTypeId: artifactMapData["activities"],
        artifactId: activityContent.activityId,
        hashtags: [...userAddedHashtags, ...existingHashtags].toString(),
      };
      await axiosPostService(`api/sdmhashtags/add`, postData).then(
        (response) => {
          if (response.data.status) {
            if (userAddedHashtags.length > 0) {
              track(
                1,
                1201,
                activityContent.activityId,
                activityContent.activityName,
                {
                  hashtags: userAddedHashtags.toString(),
                }
              );
            }
            setExistingHashtags([...userAddedHashtags, ...existingHashtags]);
            setHashtagsInput("");
            setHashtags([]);
            setUserAddedHashtags([]);
            setHashtagSuggestionInput("");
          } else {
            toast.error(response.data.errorMessage, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      );
    }
  };
  const getActivityContents = async () => {
    setShowLoader(true);
    await axiosGetService(
      `api/activities/activity?activityIdentifier=${activityId}&complexityId=${searchComplexityParam}&url=activity&urlPathName=activity`
    )
      .then(async (response) => {
        getSolutionMethodContentOwner(response.data);
        if (response.data.isActivityDeleted) {
          setactivityContent(response.data);
        } else {
          const hashtags = response.data.hashTagsList.map(({ name }) => {
            return name;
          });
          setExistingHashtags(hashtags);

          if (response.data.complexityName !== filData) {
            setfilData({
              name: response.data.complexityName,
              id: response.data.complexityId,
              type: "option",
            });
            setComplexityId(response.data.complexityId);
          }

          const mappedComplexities = response.data.mappedCompelxityList.map(
            (item) => ({
              name: item.name,
              id: item.id,
              option: "type",
            })
          );
          setComplexities(mappedComplexities);
          setactivityContent(response.data);
          setComplexityEnabled(response.data.isComplexity);
          setTemplateData(response.data.activityTemplateDetails);
          loadBreadcrumb(response.data, response.data.methodIsRetired);
          setTopicData(response.data.activityTopics);
          setProcedureData(response.data.activityProcedureType);
          setIsActivityLock(response.data.isDrafted);
          setIsReviewer(response.data.isReviewer);
          setIsApprover(response.data.isApprover);
          setActivityLockedBy(response.data.lastDraftChangesMadeBy);
          if (response.data.isDrafted) {
            const accessToken = await axiosTokenService();
            const userData = await getOtherUserProfile(
              accessToken,
              response.data.lastDraftChangesMadeBy
            );
            if (userData)
              setLockedByUsername(userData.firstName + " " + userData.surname);
            else setLockedByUsername(response.data.lastDraftChangesMadeBy);
          }
          getSolutionMethodContentOwner(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setShowLoader(false);
  };

  const getSolutionMethodContentOwner = async (activity) => {
    await axiosGetService(
      `api/sdmactivity/getSolutionMethodContentOwner/${activity.solutionMethodId}`
    )
    .then(async (response) => {
        if (response.data) {
          const accessToken = await axiosTokenService();
          const userData = await getOtherUserProfile(
            accessToken,
            response.data
          );
          if (userData)
            setSolutionMethodContentOwner(
              userData.firstName + " " + userData.surname
            );
          setSolutionMethodContentOwnerEmail(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getEditingstateId = async () => {
    await axiosGetService(
      `api/sdmactivity/getActivity2/${activityContent.activityId}/${activityContent.complexityId}`
    )
      .then((response) => {
        setEditingStateId(response.data.stateId);
        setContributorsData(response.data);
        //Tools Historical preview
        const historyContributionsToolsId = [];
        if (
          response.data.resultCalloutJson &&
          response.data.resultCalloutJson.toolsAndGuidance
        ) {
          setHistoricalPreviewTools(
            response.data.resultCalloutJson.toolsAndGuidance
          );
          const toolsSuggestions =
            response.data.resultCalloutJson.toolsAndGuidance;
          for (const id in toolsSuggestions) {
            historyContributionsToolsId.push(
              JSON.parse(toolsSuggestions[id]).toolsId
            );
          }
          setHistoryToolsId(historyContributionsToolsId);
        }
        const updatedTools = [];
        const url = {};
        for (const id in response.data.activityToolsList) {
          const tools = {
            activities: response.data.activityToolsList.activities,
            createdBy: response.data.activityToolsList.createdBy,
            createdOn: response.data.activityToolsList.createdOn,
            email: response.data.activityToolsList.email,
            toolsId: response.data.activityToolsList.toolsId,
            isSelected: historyContributionsToolsId.includes(
              response.data.activityToolsList[id].id
            )
              ? true
              : false,
            modifiedBy: response.data.activityToolsList.modifiedBy,
            modifiedOn: response.data.activityToolsList.modifiedOn,
            modifiedUserEmail:
              response.data.activityToolsList.modifiedUserEmail,
            name: response.data.activityToolsList.name,
            url: response.data.activityToolsList.url,
          };
          updatedTools.push(tools);
          url[response.data.activityToolsList[id].id] =
            response.data.activityToolsList[id].url;
        }
        setToolsUrl(url);
        setHistoryToolsSuggestion([...updatedTools]);
        const reviewedIds = {};
        if (response.data.reviewedSuggestionsJson) {
          const toolsAndGuidancesReviewedSuggestions = JSON.parse(
            response.data.reviewedSuggestionsJson
          ).toolsAndGuidancesReviewedSuggestions;
          for (const id in toolsAndGuidancesReviewedSuggestions) {
            reviewedIds[
              JSON.parse(toolsAndGuidancesReviewedSuggestions[id]).id
            ] = JSON.parse(toolsAndGuidancesReviewedSuggestions[id]).state;
          }
          setToolsReviewedIds(reviewedIds);
        }
        setEntryCriteriaData(response.data.entryCriteriaList);
        setExitCriteriaData(response.data.exitCriteriaList);
        setTaskData(response.data.taskList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getContributor = (suggestions) => {
    for (const id in suggestions) {
      const userEmail = JSON.parse(suggestions[id]).authorId;
      if (userEmail && !userDataMap.has(userEmail)) {
        graphUserData.push(userEmail);
        userDataMap.set(userEmail, 1);
      }
    }
  };
  const getUsersInfo = async () => {
    if (
      contributorsData.resultCalloutJson &&
      contributorsData.stateId === 2 &&
      isReviewer
    ) {
      if (contributorsData.resultCalloutJson.description) {
        getContributor(contributorsData.resultCalloutJson.description);
      }
      if (contributorsData.resultCalloutJson.supportingActivities) {
        getContributor(contributorsData.resultCalloutJson.supportingActivities);
      }
      if (contributorsData.resultCalloutJson.toolsAndGuidance) {
        getContributor(contributorsData.resultCalloutJson.toolsAndGuidance);
      }
      if (contributorsData.resultCalloutJson.tipsAndTechniques) {
        getContributor(contributorsData.resultCalloutJson.tipsAndTechniques);
      }
      if (contributorsData.resultCalloutJson.templateSuggestions) {
        getContributor(contributorsData.resultCalloutJson.templateSuggestions);
      }
      if (contributorsData.resultCalloutJson.entryCriteriaSuggestions) {
        getContributor(
          contributorsData.resultCalloutJson.entryCriteriaSuggestions
        );
      }
      if (contributorsData.resultCalloutJson.exitCriteriaSuggestions) {
        getContributor(
          contributorsData.resultCalloutJson.exitCriteriaSuggestions
        );
      }
      if (contributorsData.resultCalloutJson.taskSuggestions) {
        getContributor(contributorsData.resultCalloutJson.taskSuggestions);
      }

      if (!userDataMap.has(CkEditorCurrentUser)) {
        graphUserData.push(CkEditorCurrentUser);
        userDataMap.set(CkEditorCurrentUser, 1);
      }
    } else {
      graphUserData.push(CkEditorCurrentUser);
    }
    const accessToken = await axiosTokenService();
    const userProfileMapping = {};
    for (const id in graphUserData) {
      const userEmail = graphUserData[id];
      const userData = await getOtherUserProfile(accessToken, userEmail);
      contributorInfo.push({
        id: userData.mail,
        name: userData.firstName + " " + userData.surname,
        avatar: userData.photo,
      });
      userProfileMapping[userData.mail] = [userData.firstName, userData.photo];
    }
    setCkEditorUserMap(userProfileMapping);
    setCkEditorUsers(contributorInfo);
    if (contributorsData.reviewerEmail) {
      await getOtherUserProfile(
        accessToken,
        contributorsData.reviewerEmail
      ).then((response) => {
        setReviewerInfo(response.firstName + " " + response.surname);
      });
    }
  };
  const showHistoryPreview = () => {
    setCrowdSourcingAppState({
      ...crowdSourcingAppState,
      showHistory: true,
      showPreviewPopUp: true,
    });
  };

  $(document).ready(() => {
    $.each($("#innerContent a"), (e, v) => {
      if (
        (v.href !== undefined || v.href !== null) &&
        v.getAttribute("xlink:href") == null &&
        (v.getAttribute("linkchanged") === null ||
          v.getAttribute("linkchanged") === undefined)
      ) {
        const url = processLinkChange(v.href, v);
        if (url) {
          $(v).attr("href", url);
        }

        $(v).attr("linkChanged", 1);
      }
    });
  });
  var processLinkChange = function (url, e) {
    if (url !== null && url !== undefined) {
      if (!url.includes(window.location.origin)) {
        return url;
      }

      const redirectUrl = window.location.origin;
      const params = getParams(url);
      let urlWindow = "";
      if (
        url.includes("activityContent") &&
        params.id &&
        params.complexityType
      ) {
        const activityid = params.id;
        urlWindow = `${redirectUrl}/project/1/method/0/phase/null/parentPhase/null/activity/${activityid}?complexity=${params.complexityType}`;
      } else if (url.includes("/topics") && params.id) {
        const topicId = params.id;
        urlWindow = `${redirectUrl}/topics/${topicId}`;
      } else {
        return url;
      }
      return urlWindow;
    }
  };

  var getParams = function (url) {
    if (url.includes("#")) {
      url = url.substring(url.indexOf("#") + 1, url.length);
    }
    const params = {};
    const parser = document.createElement("a");
    parser.href = url;
    const query = parser.search.substring(1);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=");
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  };

  const loadBreadcrumb = async (activity, methodIsRetired) => {
    await axiosGetService(
      `api/sdmuser/getbreadcrumb?itemId=${activity.activityId}&itemTypeId=2
			&isEdit=false&isAutoComplete=false&isSearchResultsHome=false
			&isSearchResults=false&isMyContributions=false&documentStatus=0
			&isNotifications=false&solutionMethodId=${activity.solutionMethodId}&phaseId=${
        Number(activity.phaseId) !== 0
          ? Number(activity.phaseId)
          : Number(activity.parentPhaseId) !== 0
          ? activity.parentPhaseId
          : 0
      }`
    ).then((response) => {
      const breadcrumbTitle = response.data
        .split(">")
        .map((item) => item.trim());
      const breadcrumbPhaseId =
        Number(activity.phaseId) !== 0
          ? activity.phaseId
          : activity.parentPhaseId;
      const breadcrumbParentPhaseId =
        Number(activity.parentPhaseId) !== 0 && Number(activity.phaseId) !== 0
          ? activity.parentPhaseId
          : null;
      const breadcrumbSolutionMethodId = activity.solutionMethodId;
      if (methodIsRetired) {
        const solutionMethodBreadcrumb = {
          title: breadcrumbTitle[2],
          type: "solutionMethod",
          path: `/project/${activity.methodologyTypeId}/method/${breadcrumbSolutionMethodId}`,
          parent: ["home"],
        };
        const phaseBreadcrumb = {
          title: breadcrumbTitle[3],
          type: "Phase",
          path: `/project/${activity.methodologyTypeId}/method/${breadcrumbSolutionMethodId}/phase/${breadcrumbPhaseId}/parentPhase/${breadcrumbParentPhaseId}`,
          parent: "solutionMethod",
        };
        const pageBreadcrumb = {
          title: breadcrumbTitle[4],
          type: "activity",
          path: window.location.pathname + `?complexity=${complexityId}`,
          parent: "Phase",
        };
        const newBreadcrumbs = breadcrumbs.slice(0, 1);
        if (
          phaseId === "null" &&
          parentPhaseId === "null" &&
          solutionMethodId === "0"
        ) {
          newBreadcrumbs.push(pageBreadcrumb);
        } else {
          newBreadcrumbs.push(solutionMethodBreadcrumb);
          newBreadcrumbs.push(phaseBreadcrumb);
          newBreadcrumbs.push(pageBreadcrumb);
        }
        setBreadcrumbs(newBreadcrumbs);
      } else {
        const projectBreadcrumb = {
          title: breadcrumbTitle[1],
          type: "projectType",
          path: `/project/${activity.methodologyTypeId}`,
          parent: ["home", "search", "raciGrid"],
        };
        var solutionMethodBreadcrumb;
        if (parseInt(window.location.href.split("/").at(6), 10) === parseInt(isProjectDelivery, 10)) {
          solutionMethodBreadcrumb = {
            title: breadcrumbTitle[2],
            type: "solutionMethod",
            path: `/project/${activity.methodologyTypeId}`,
            parent: "projectType",
          };
        }else{
          solutionMethodBreadcrumb = {
            title: breadcrumbTitle[2],
            type: "solutionMethod",
            path: `/project/${activity.methodologyTypeId}/method/${breadcrumbSolutionMethodId}`,
            parent: "projectType",
          };
        }
        
        const phaseBreadcrumb = {
          title: breadcrumbTitle[3],
          type: "Phase",
          path: `/project/${activity.methodologyTypeId}/method/${breadcrumbSolutionMethodId}/phase/${breadcrumbPhaseId}/parentPhase/${breadcrumbParentPhaseId}`,
          parent: "solutionMethod",
        };
        const pageBreadcrumb = {
          title: breadcrumbTitle[4],
          type: "activity",
          path: window.location.pathname + `?complexity=${complexityId}`,
          parent: ["phase", "search"],
        };
        const newBreadcrumbs = breadcrumbs.slice(
          0,
          breadcrumbs.length > 1 &&
            (breadcrumbs[1].type === "search" ||
              breadcrumbs[1].type === "raciGrid")
            ? 2
            : 1
        );
        if (
          (phaseId === "null" &&
            parentPhaseId === "null" &&
            solutionMethodId === "0") ||
          (breadcrumbs.length > 1 && breadcrumbs[1].type === "raciGrid")
        ) {
          newBreadcrumbs.push(pageBreadcrumb);
        } else {
          newBreadcrumbs.push(projectBreadcrumb);
          newBreadcrumbs.push(solutionMethodBreadcrumb);
          newBreadcrumbs.push(phaseBreadcrumb);
          newBreadcrumbs.push(pageBreadcrumb);
        }
        setBreadcrumbs(newBreadcrumbs);
      }
      TabTitle(breadcrumbTitle[4]);
    });
  };
  const getBusinessRelatedContent = async()=>{
		let api  = `api/sdmbusinessrules/getassociatedbusinessRulesbyArtifactId/${activityId}/2/${complexityId}/${false}`
		await axiosGetService(api).then(
			(response) => {
       let filterByDraft = response.data.filter(e=>e.isDrafted === false)
       let filterbyComplexity = filterByDraft.filter(e=>e.complexityId === filData.id)
				setRelatedBusinessContent(filterbyComplexity)
			})
	}

  useEffect(()=>{
    getBusinessRelatedContent();
  },[filData,complexityId])

  $("body").on("click", ".topicNav", function () {
    const topicurl = $(this).attr("href");
    if (topicurl.split("?").length > 0) {
      const query = topicurl.split("?")[1];
      const query2 = query.split("=")[1];
      const id = query2.substring(0, query2.indexOf("&"));
      if (id !== null) {
        const urlWindow = `${sdm2URL}/topics/${id}`;
        window.open(urlWindow, "_blank");
      }
    }
  });

  $(document).ready(function () {
    $("figure").each(function (index, element) {
      if (!element.getAttribute("contenteditable")) {
        const imgElement = element.children[0];
        $(imgElement).attr({ style: $(element).attr("style") });
      }
    });
  });

  useEffect(() => {
    if (complexities.length !== 0) {
      history.push(`${url}?complexity=${filData.id}`);
    }
  }, [filData]);

  useEffect(() => {
    getActivityContents();
    setShowHashtagsForm(false);
  }, [searchComplexityParam, activityId, isProjectDelivery]);

  useEffect(() => {
    if (
      activityContent &&
      activityContent.activityId &&
      activityContent.complexityId
    ) {
      getEditingstateId();
    }
  }, [activityContent]);

  useEffect(() => {
    if (contributorsData) {
      getUsersInfo();
    }
  }, [contributorsData, isReviewer]);

  useEffect(() => {
    if (activityContent.activityName !== undefined) {
      track(2, 2, activityContent.activityId, activityContent.activityName, {
        complexityId: searchComplexityParam,
      });
    }
    if (activityContent.isExternal) {
      window.open(
        activityContent.fileLocation,
        "",
        `height = ${window.screen.height}, width = ${window.screen.width}, resizable = yes, scrollbars = yes, toolbar = yes, menubar = yes, location = yes`
      );
    }
  }, [activityContent.activityName]);
  useEffect(
    () => () => {
      window.onpopstate = (e) => {
        setBreadcrumbs(breadcrumbs.slice(0, 1));
      };
    },
    []
  );

  useEffect(() => {
    setSkipNavigationId("activityComplexity");
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (hashtagSuggestionInput.trim()) {
        hashtagsAutoComplete();
      }
    }, 1000);

    return () => {
      setHashtagSuggestions([]);
      clearTimeout(timeOutId);
    };
  }, [hashtagSuggestionInput]);

  const getActivityOwnerDetails = async (email, isInhertedValue) => {
    const accessToken = await axiosTokenService();
    const userData = await getOtherUserProfile(accessToken, email);
    
    const modifiedUserData = { ...userData, isInhertedValues: isInhertedValue };
    setPrevActivityOwner(modifiedUserData);
    setActivityOwner(modifiedUserData);
  };
  
  const getCollaborators = async () => {
     await axiosGetService(
       `api/sdmactivity/getactivityorphaseormethodcollaborators/${activityId}/${phaseId}/${solutionMethodId}`
     ).then((response) => {
      setActivityCollaborators(response.data)
      getCollaboratorsInfo(response.data.coOwnerMembersList,setExistingCoOwners,response.data.isInheritedCoOwnerMembers)
      getCollaboratorsInfo(response.data.approverMembersList, setExistingApprovers,response.data.isInheritedApprovers)
      getCollaboratorsInfo(response.data.reviewerMembersList, setExistingReviewers,response.data.isInheritedReviewers)
      getActivityOwnerDetails(response.data.owner,response.data.isInheritedOwner)
      setPreviousPostData({
				approverMembersList: response.data.approverMembersList.sort(),
				reviewerMembersList: response.data.reviewerMembersList.sort(),
        coOwnerMembersList: response.data.coOwnerMembersList.sort(),
        owner: response.data.owner

			});
      if (
				response.data.coOwnerMembersList.length === 0 &&
				response.data.reviewerMembersList.length === 0 &&
				response.data.approverMembersList.length === 0
			) {
				setShowCollaboratorSpinner(false);
			}
     }).catch((error)=>{
      console.log(error)
     })
    }

    const getUser = async (userEmail, setValue, isInhertedValue) => {
      setShowCollaboratorSpinner(true);
      const accessToken = await axiosTokenService();
      await getOtherUserProfile(accessToken, userEmail).then((response) => {
        const modifiedResponse = { ...response, isInhertedValues: isInhertedValue };
    
        setValue((prevState) => [...prevState, modifiedResponse]);
      });
      setTimeout(() => {
        setShowCollaboratorSpinner(false);
      }, 1000);
    };
    
   
    const getCollaboratorsInfo = async (membersList, setValue,isInhertedValue) => {
      for (const userEmail of membersList) {
        getUser(userEmail, setValue,isInhertedValue);
      }
    };
    const getAdminStatus = async () => {
      await axiosGetService(
        `api/user/profile`
      ).then((response) => {
        setIsAdminOrOwner(response.data.isAdmin);
      });
    };
    useEffect(()=>{
    getCollaborators()
    getAdminStatus()
    },[isEdited])
    
  // const [maxChars, setMaxChars] = useState(40);
  // const colRef = useRef(null);
  // const updateMaxChars = () => {
  //   const availableWidth = colRef.current?.offsetWidth || 500;
  //   const newMaxChars = Math.floor(availableWidth /(availableWidth<229?9:8));
  //   setMaxChars(newMaxChars);
  // };
  // useEffect(() => {
  //   updateMaxChars();
  //   window.addEventListener('resize', updateMaxChars);
  //   return () => window.removeEventListener('resize', updateMaxChars);
  // }, []);

    // const previousWidthRef = useRef(window.innerWidth);
    // useEffect(() => {
    //   const updateContainers = () => {
    //     $('.LeftMenuContainer').hide();
    //     $('.rightSideMainContainer').css('width', '-webkit-fill-available');
    //   };
    
    //   const resetContainers = () => {
    //     $('.LeftMenuContainer').show();
    //     $('.rightSideMainContainer').css('width', 'calc(-200px + 100vw)');
    //   };
    
    //   const handleResize = () => {
    //     const currentWidth = window.innerWidth;
    //     if (currentWidth !== previousWidthRef.current) {
    //       updateContainers();
    //     }
    //     previousWidthRef.current = currentWidth;
    //   };
    //   updateContainers();
    //   window.addEventListener('resize', handleResize);

    //   return () => {
    //     window.removeEventListener('resize', handleResize);
    //     resetContainers();
    //   };
    // }, []);

  if (activityContent.isActivityDeleted) {
    return <DeletedEntityModal errorMsg={activityContent.errorMsg} />;
  }

  if (ckEditorUsers.length < 1 || !contributorsData) {
    return <Spinner />;
  }
  function checkAndShowTooltip() {
    // Define your condition here. For example:
    const condition =
      editingStateId === 2 ||
      activityContent.isExternal ||
      activityContent.methodIsRetired ||
      isActivityLock ||
      !activityContent.phaseIds ||
      !activityContent.isApproverGroupExist ||
      !activityContent.isReviewerGroupExist;

    if (condition) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  }
 
  const handleComplexityButtons = (item) => {
		const selectedValue = item;
		setfilData(selectedValue);
		track(1, 9, 'complexityFilter', selectedValue.name);
	};

  const RaciType = ({ label, rolesData, id }) => {
    return (
      <Col xs={12} sm={12} md={12} className=" pt-2 px-0">
        <h3 className="_raciHeadingInActivity" id={id}>{label}:</h3>
        {rolesData.map((role) => (
          <div className="_raciItemsInActivity" aria-label={role} title={role} key={role}>
            <span title={role}>{role}</span>
          </div>
        ))}
      </Col>
    );
  }

  const sanitizedContent = (content) => {
    if (!content) return '';
  
    if (content.includes('<img')) {
      content = content.replace(/<img/, '<img alt="Guidance Tips and Techniques" tabIndex="0"');
    }
    if (content.includes('<img')) {
      content = content.replace(/<img/, '<img alt="Supporting Activities" tabIndex="0"');
    }
    if (content.includes('<img')) {
      content = content.replace(/<img/, '<img alt="Entry Criteria" tabIndex="0"');
    }
    if (content.includes('<img')) {
      content = content.replace(/<img/, '<img alt="Tasks" tabIndex="0"');
    }

    if (content.includes('<img')) {
      content = content.replace(/<img/, '<img alt="Exit Criteria" tabIndex="0"');
    }

    if (content.includes('<h2')) {
      content = content.replace(/<h2\s+class=["']criteraType["'][^>]*>.*?<\/h2>/i, '');
    }
  
    return content;
  };
  function extractContentByHeading(content, headingName) {
    const headingRegex = new RegExp(`(<h2 class='criteraType'>${headingName}<\\/h2>\\s*(<ul>.*?<\\/ul>)?)`, 's');
    const match = content.match(headingRegex);
    return match && match[2] ? match[2].trim() : null;
  }
  const wrapElementsInList = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const unorderedList = doc.createElement('ul');
    doc.body.querySelectorAll('p, li').forEach(element => {
        const listItem = doc.createElement('li');
        listItem.innerHTML = element.innerHTML;
        unorderedList.appendChild(listItem);
    });
    return unorderedList.outerHTML;
};

const contentNotAvailable = () => {
  return <div className="_contentNotAvailable">N/A</div>;
};

return (
  <div style={{overflowY:'scroll',overflowX:'hidden'}}>
    
    
    <Container fluid className="px-1 m-0 _activityMainContainer">
      <Row className="mx-0 _rowWhite">
          <Col className="px-0">
              <div className="activityBreadcrumb">
                <Breadcrumbs />
                <FavoriteAndFeedback />
              </div>
         </Col>
      </Row>
           {showTooltip && (
              <Row className="my-0 _yellowBar">
              <MessageBar
                messageBarType={MessageBarType.warning}
                isMultiline={false}
              >
                <p style={{marginTop:'2px'}}>
                  Editing this content isn't possible due to an activity
                  configuration issue. Please contact{" "}
                  <a
                    target="_blank"
                    href={`https://teams.microsoft.com/l/chat/0/0?users=${solutionMethodContentOwnerEmail}`}
                    className="anchorTag"
                  >
                    {" "}
                    {solutionMethodContentOwner}{" "}
                  </a>
                  for more help.
                </p>
              </MessageBar>
              </Row>
            )}
        <Row className="mx-3 d-flex flex-wrap _rowGray pt-1">
          <Col xs={12} sm={12} md={7} className="px-0">
            <div className="_complexityContainer">
            <span>Complexity&nbsp;:</span>
              <div className={`_complexityButtonsContainer ${complexities.length>1?'':'_complexityButtonsContainerSingle'}`}>
              {complexities.map((complexity) => (
                <button  
                  key={complexity.id}
                  onClick={() => handleComplexityButtons(complexity)}
                  className={`_complexityButtons ${filData.id === complexity.id ? "_activeComplexityButtons" : ""}`}
                >
                    {complexity.name}
                </button>
              ))}
            </div>
            </div>
          </Col>
          <Col  xs={12} sm={12} md={5} className="d-flex justify-content-end px-0">
                 <span className="_activityVersion pr-4">
                {`Document Version: ${activityContent.activityVersion},`}
                &nbsp;&nbsp;
                {`${activityContent.date} `}
                </span>
         
                {(!isReviewer && !isApprover) ||
                  (isReviewer && editingStateId === 0) ||
                  (isReviewer && editingStateId === 2 && !isApprover) ||
                  (isApprover && editingStateId === 0) ||
                  (isApprover && editingStateId === 1 && !isReviewer) ? (
                    <div
                      className={`_activityEdit ${
                        editingStateId === 2 ||
                        activityContent.isExternal ||
                        activityContent.methodIsRetired ||
                        !activityContent.phaseIds ||
                        isActivityLock ||
                        !activityContent.isApproverGroupExist ||
                        !activityContent.isReviewerGroupExist
                          ? "disabledEditActivity"
                          : null
                      }`}
                      title={
                        showTooltip
                          ? "You can not edit this content"
                          : "Suggest Edit"
                      }
                      onMouseEnter={checkAndShowTooltip}
                    >
                      <Link
                        title="Suggest Edit"
                        tabIndex={editingStateId === 2 ? -1 : 0}
                        aria-label={
                          showTooltip
                            ? "You can not edit this content."
                            : "Suggest Edit"
                        }
                        onClick={() => {
                          track(
                            1,
                            130,
                            activityContent.activityId,
                            activityContent.activityName
                          );
                        }}
                        to={
                          isReviewer && editingStateId === 0
                            ? `${suggestEditUrl}/${activityContent.activityId}/reviewer/edit?complexity=${complexityId}&identifier=${activityContent.activityIdentifier}`
                            : `${suggestEditUrl}/${activityContent.activityId}/edit?complexity=${complexityId}&identifier=${activityContent.activityIdentifier}`
                        }
                        role="button"
                      >
                        Suggest Edit
                        <span className="editIcon" />
                      </Link>
                    </div>
                  ) : null}
             
          </Col>
        </Row>
        
      {(ckEditorUsers.length < 1 || !contributorsData || showLoader)?<Spinner />:(
      <Row className="mx-0 _allComlumns px-3 pb-3">
        <Col  xs={12} sm={9} md={9} className="pr-0 pl-0 bg-white border border-right-0 _borderRadius">
            <Card className="p-3 border-bottom-0 border-right-0">
                 <Row className="mx-2">
                    <Col className="px-0">
                      <h1 title={activityContent.activityName} className="activityLandingPageTitle _activityLandingPageTitle">{activityContent.activityName}</h1>
                    </Col>
                  </Row>

                  <Row className="mx-2">
                    <Col className="existingHashTagsOfActivity _existingHashTagsOfActivity pb-2 px-0">
                    {!showHashtagsForm &&
                      existingHashtags.map((name) => (
                        <span
                          role="link"
                          aria-label={name}
                          tabIndex={0}
                          key={name}
                          onClick={() => {
                            history.push(
                              `/search?searchParam=${encodeURIComponent(name)}`
                            );
                          }}
                          onKeyPress={(e) => {
                            if (e.which === 13) {
                              history.push(
                                `/search?searchParam=${encodeURIComponent(name)}`
                              );
                            }
                          }}
                        >
                          {name}
                        </span>
                      ))}

                    {!showHashtagsForm ? (
                      <div className="addHashtagsIconContainer _addHashtagsIconContainer">
                        <span
                          title="Add Hashtags"
                          className="_addHashtagsIcon"
                          onClick={() => {
                            setShowHashtagsForm(true);
                          }}
                          onKeyPress={(e) => {
                            if (e.which === 13) {
                              setShowHashtagsForm(true);
                            }
                          }}
                          tabIndex="0"
                          role="button"
                          aria-label="Add Hashtags"
                        ></span>
                        {/* <div class="tabsTitleToolTip">
                          <span class="tabsToolTipArrow"></span>
                          <span>Add Hashtags</span>
                        </div> */}
                      </div>
                    ) : (
                      <></>
                    )}
                    {showHashtagsForm ? (
                      <div className="activityDetailsHasttagsContainer _activityDetailsHasttagsContainer">
                        <div className="activityDetailsHasttagInput">
                          <HashtagSuggestions
                            className="activityHashtagsInput"
                            enableDropdown={true}
                            data={hashtagSuggestions}
                            setUpdateApiCall={setUpdateApiCall}
                            setHashtagSuggestions={setHashtagSuggestions}
                            searchValue={hashtagsInput}
                            setSearchValue={setHashtagsInput}
                            existingHashtags={existingHashtags}
                            userAddedHashtags={userAddedHashtags}
                            setUserAddedHashtags={setUserAddedHashtags}
                            hashtagSuggestionInput={hashtagSuggestionInput}
                            setHashtagSuggestionInput={setHashtagSuggestionInput}
                            closeHashtagForm={() => {
                              setHashtagsInput("");
                              setShowHashtagsForm(false);
                              setUserAddedHashtags([]);
                            }}
                            submitHashtags={submitHashtags}
                            autoFocus={true}
                            showSubmitButton={true}
                          />
                        </div>

                        {existingHashtags.length > 0 ? (
                          <div className="hashTagsContainer">
                            {existingHashtags.map((item) => (
                              <span key={item}>{item}</span>
                            ))}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    </Col>
                  </Row>

                  <Row className="mx-2">
                    <Col className="px-0">
                      <div
                      className="desc ck-content _activityDescription"
                      id="description"
                      dangerouslySetInnerHTML={{
                        __html: HtmlSanitizer(
                          activityContent.activityDescription && 
                            activityContent.activityDescription.includes('<img') ? 
                            activityContent.activityDescription.replace(/<img/, '<img alt="Description" tabIndex="0"') 
                            : activityContent.activityDescription
                          ),
                    }}
                    />
                    </Col>
                  </Row>
                      

                {(() => {
                  const hasEntryCriteria = sanitizedContent(activityContent.activityEntryCriteria) &&  extractContentByHeading(activityContent.activityEntryCriteria, "Entry Criteria");
                  const hasInput = extractContentByHeading(activityContent.activityEntryCriteria, "Input");
                  return (
                    <Row className="border-top mx-2 mt-1 pt-1">
                        <Col xs={12} sm={6} md={6} className="pl-0 pr-3">
                          <h3 className="_headingsInActivity">Entry Criteria</h3>
                          {!hasEntryCriteria?contentNotAvailable():(
                            <div className="_columnDataOfSubheadings">
                              <div
                                className="descEn ck-content _activityDescriptionCKEditor"
                                id="entryCriteria"
                                dangerouslySetInnerHTML={{
                                  __html: HtmlSanitizer(
                                    extractContentByHeading(activityContent.activityEntryCriteria, "Entry Criteria") &&
                                    activityContent.activityEntryCriteria.includes('<img')
                                      ? extractContentByHeading(activityContent.activityEntryCriteria, "Entry Criteria").replace(
                                          /<img/,
                                          '<img alt="Entry Criteria" tabIndex="0"'
                                        )
                                      : extractContentByHeading(activityContent.activityEntryCriteria, "Entry Criteria") &&
                                        activityContent.activityEntryCriteria.includes('<h2')
                                      ? extractContentByHeading(activityContent.activityEntryCriteria, "Entry Criteria").replace(
                                          /<h2\s+class=["']criteraType["'][^>]*>.*?<\/h2>/i,
                                          ''
                                        )
                                      : extractContentByHeading(activityContent.activityEntryCriteria, "Entry Criteria")
                                  ),
                                }}
                              />
                            </div>
                          )}
                        </Col>
                      

                        <Col xs={12} sm={6} md={6} className="pl-0 pr-3">
                          <h3 className="_headingsInActivity">Input</h3>
                          {!hasInput ?contentNotAvailable():(
                              <div className="_columnDataOfSubheadings">
                                <div
                                  className="descEn ck-content _activityDescriptionCKEditor"
                                  id="entryCriteria"
                                  dangerouslySetInnerHTML={{
                                    __html: HtmlSanitizer(extractContentByHeading(activityContent.activityEntryCriteria, "Input")),
                                  }}
                                  />
                              </div>
                          )}
                        </Col>
                    </Row>
                  );
                })()}


                {(() => {
                  const hasTasks = sanitizedContent(activityContent.activityTasks);
                  const hasDeliverables = templateData.length > 0 || sanitizedContent(activityContent.activityDeliverables);
                  return (
                    <Row className="border-top mx-2 mt-1 pt-1">
                        <Col xs={12} sm={6} md={6} className="pl-0 pr-3">
                          <h3 className="_headingsInActivity">Tasks</h3>
                          {!hasTasks ?contentNotAvailable():(
                              <div className="_columnDataOfSubheadings">
                                <div
                                  className="descT ck-content _activityDescriptionCKEditor _taskItems"
                                  id="task"
                                  dangerouslySetInnerHTML={{
                                    __html: HtmlSanitizer(activityContent.activityTasks && activityContent.activityTasks.includes('<img') 
                                    ? activityContent.activityTasks.replace(/<img/,'<img alt="Tasks" tabIndex="0"') : activityContent.activityTasks
                                    ),
                                  }}
                                />
                              </div>
                            )}
                        </Col>

                        <Col xs={12} sm={6} md={6} className="pl-0 pr-3">
                          <h3 className="_headingsInActivity">Deliverables</h3>
                          {!(hasTasks && hasDeliverables)? contentNotAvailable() : (
                              <div className="_columnDataOfSubheadings">
                                {templateData.length > 0 && (
                                  <div id="tempList" className="_activityDescriptionCKEditor">
                                    <ul>
                                      {templateData.map((template) => (
                                        <li key={template.id}>
                                          {template.inProgress ? (
                                            <a className="noClick" href={template.fileLocation} target="_blank">
                                              {template.title}
                                            </a>
                                          ) : (
                                            <a href={template.fileLocation} target="_blank">
                                              {template.title}
                                            </a>
                                          )}
                                          {template.sampleTemplates.length !== 0 && !template.inProgress ? (
                                            <button
                                              className="activitySampleButton"
                                              tabIndex={0}
                                              onKeyPress={(e) => {
                                                if (e.which === 13) {
                                                  setSampleTemplates({
                                                    enable: true,
                                                    id: template.id,
                                                    parentTemplate: template,
                                                  });
                                                }
                                              }}
                                              onClick={() => {
                                                setSampleTemplates({
                                                  enable: true,
                                                  id: template.id,
                                                  parentTemplate: template,
                                                });
                                              }}
                                            >
                                              (Sample)
                                            </button>
                                          ) : null}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                                {activityContent.activityDeliverables && (
                                <div
                                    className="ck-content _activityDescriptionCKEditor"
                                      id="tempList"
                                      dangerouslySetInnerHTML={{
                                        __html: HtmlSanitizer(wrapElementsInList(
                                          activityContent.activityDeliverables && 
                                          activityContent.activityDeliverables.includes('<img') ? 
                                          activityContent.activityDeliverables.replace(/<img/, '<img alt="Deliverables" tabIndex="0"') 
                                          : activityContent.activityDeliverables
                                        )),
                                        }}
                                    />
                                )}
                              </div>
                          )}
                        </Col>
                    </Row>
                  );
                })()}


                {(() => {
                  const hasExitCriteria = sanitizedContent(activityContent.activityExitCriteria) && extractContentByHeading(activityContent.activityExitCriteria, "Exit Criteria");
                  const hasOutput = extractContentByHeading(activityContent.activityExitCriteria, "Output");
                  return (
                    <Row className="border-top mx-2 mt-1 pt-1">
                        <Col xs={12} sm={6} md={6} className="pl-0 pr-3">
                          <h3 className="_headingsInActivity">Exit Criteria</h3>
                          {!hasExitCriteria? contentNotAvailable():(
                              <div className="_columnDataOfSubheadings">
                                <div
                                  className="descEx ck-content _activityDescriptionCKEditor"
                                  id="exitCriteria"
                                  dangerouslySetInnerHTML={{
                                    __html: HtmlSanitizer(
                                      extractContentByHeading(activityContent.activityExitCriteria, "Exit Criteria") &&
                                      activityContent.activityExitCriteria.includes('<img')
                                        ? extractContentByHeading(activityContent.activityExitCriteria, "Exit Criteria").replace(
                                            /<img/,
                                            '<img alt="Exit Criteria" tabIndex="0"'
                                          )
                                        : extractContentByHeading(activityContent.activityExitCriteria, "Exit Criteria") &&
                                          activityContent.activityExitCriteria.includes('<h2')
                                        ? extractContentByHeading(activityContent.activityExitCriteria, "Exit Criteria").replace(
                                            /<h2\s+class=["']criteraType["'][^>]*>.*?<\/h2>/i,
                                            ''
                                          )
                                        : extractContentByHeading(activityContent.activityExitCriteria, "Exit Criteria")
                                    ),
                                  }}
                                />
                              </div>
                          )}
                        </Col>

                        <Col xs={12} sm={6} md={6} className="pl-0 pr-3">
                          <h3 className="_headingsInActivity">Output</h3>
                          {!hasOutput?contentNotAvailable(): (
                              <div className="_columnDataOfSubheadings">
                                <div
                                  className="descEn ck-content _activityDescriptionCKEditor"
                                  id="entryCriteria"
                                  dangerouslySetInnerHTML={{
                                    __html: HtmlSanitizer(extractContentByHeading(activityContent.activityExitCriteria, "Output")),
                                  }}
                                />
                              </div>
                          )}
                        </Col>
                    </Row>
                  );
                })()}


                {(() => {
                  const hasTipsAndTechniques = sanitizedContent(activityContent.activityTipsAndTechniques);
                  const hasSupportingActivities = sanitizedContent(activityContent.activitySupportingActivities);
                  return (
                    <Row className="border-top mx-2 mt-1 pt-1">
                        <Col xs={12} sm={6} md={6} className="pl-0 pr-3">
                          <h3 className="_headingsInActivity">Guidance, Tips and Techniques</h3>
                          {!hasTipsAndTechniques ?contentNotAvailable(): (
                              <div className="_columnDataOfSubheadings">
                                <div
                                  className="descTT ck-content _activityDescriptionCKEditor"
                                  id="descTt"
                                  dangerouslySetInnerHTML={{
                                    __html: HtmlSanitizer(wrapElementsInList(
                                      activityContent.activityTipsAndTechniques &&
                                      activityContent.activityTipsAndTechniques.includes('<img')
                                        ? activityContent.activityTipsAndTechniques.replace(
                                            /<img/,
                                            '<img alt="Guidance Tips and Techniques" tabIndex="0"'
                                          )
                                        : activityContent.activityTipsAndTechniques
                                    )),
                                  }}
                                />
                              </div>
                          )}
                        </Col>

                        <Col xs={12} sm={6} md={6} className="pl-0 pr-3">
                          <h3 className="_headingsInActivity">Supporting Activities</h3>
                      {!hasSupportingActivities?contentNotAvailable(): (
                          <div className="_columnDataOfSubheadings">
                            <div
                              className="descSA ck-content _activityDescriptionCKEditor"
                              id="support"
                              dangerouslySetInnerHTML={{
                                __html: HtmlSanitizer(wrapElementsInList(
                                  activityContent.activitySupportingActivities &&
                                  activityContent.activitySupportingActivities.includes('<img')
                                    ? activityContent.activitySupportingActivities.replace(
                                        /<img/,
                                        '<img alt="Supporting Activities" tabIndex="0"'
                                      )
                                    : activityContent.activitySupportingActivities
                                )),
                              }}
                            />
                          </div>
                      )}
                        </Col>
                    </Row>
                  );
                })()}

                  
            </Card>
        </Col>
      
        <Col  xs={12} sm={3} md={3} className="pl-0 pr-0 bg-white border">
                  <Card className="border-left-0 border-right-0 border-bottom-0 py-3 pl-3 pr-1">
                    <Row>
                        <Col xs={12} sm={12} md={12}>
                          <div className="_imgContainer">
                            <img className="ownerImg" src={activityOwner.photo} onClick={()=>setShowActivityOwnerCollaborators(true)}/>
                            <span className="_ownerInfo">
                              <span>Owner</span>
                              <a href={`mailto:${activityOwner.mail}`}>
                                <span className="_ownerName">{activityOwner.firstName +" " +activityOwner.surname}</span>
                              </a>
                            </span>
                            <span className="_collaboratorsImg" onClick={()=>setShowActivityOwnerCollaborators(true)}></span>
                          </div>
                        </Col>
                    </Row>
                  </Card>

                  <Card className="border-left-0 border-right-0 border-bottom-0 mx-3 py-2">

                    <h4 className="_subHeadingInActivity">RACI 
                      <span class="_iInformationIcon" tabindex="0" role="button" aria-label="Information about RACI">
                          <div class="_tooltipRaciInfoIcon" role="tooltip">
                              <p>
                              **The RACI here depicts the ideal state where all roles are staffed on the project/engagement. In scenarios where the particular role is not staffed, the person playing the role on the project/engagement is expected to perform the activity.
                              </p>
                          </div>
                      </span>
                    </h4> 

                    <Row className="mx-0">
                      <RaciType
                        label="Responsible Role"
                        rolesData={activityContent.responsibleList}
                        id="previewResponsibleRole"
                      />
                      <RaciType
                        label="Accountable Role"
                        rolesData={activityContent.accountableList}
                        id="previewAccountableRole"
                      />
                      {activityContent.consultedList && activityContent.consultedList.length > 0 && (
                        <RaciType
                          label="Consulted Role"
                          rolesData={activityContent.consultedList}
                          id="previewConsultedRole"
                        />
                      )}

                    {activityContent.informedList && activityContent.informedList.length > 0 && (
                      <RaciType
                        label="Informed Role"
                        rolesData={activityContent.informedList}
                        id="previewInformedRole"
                      />
                    )}

                    </Row>
                  </Card>
                 
                  {activityContent.activityToolsList.some(toolsData => toolsData.isSelected) && (
                  <Card className="border-left-0 border-right-0 border-bottom-0 py-2 mx-3">
                    <Row>
                      <Col>
                          <>
                            <h4 className="_subHeadingInActivity">Tools</h4>
                            {activityContent.activityToolsList.map((item) =>
                              item.isSelected && (
                                <div className="_linksInActiviy" key={item.name}>
                                <a href={item.url} rel="noopener noreferrer" title={item.name}>{item.name}</a>
                                </div>
                              )
                            )}
                          </>
                      </Col>
                    </Row>
                  </Card>
                  )}

                  {topicData.length > 0 &&
                  <Card className="border-left-0 border-right-0 border-bottom-0 py-2 mx-3">
                    <Row>
                      <Col>
                        <>
                          <h4 className="_subHeadingInActivity">Topics</h4>
                            {topicData.map((item) => (
                              <div className="_linksInActiviy" key={item.name}>
                              <a href={item.url} rel="noopener noreferrer" title={item.name}>{item.name}</a>
                              </div>
                            ))}
                        </>
                      </Col>
                    </Row>
                  </Card>
                  }

                {procedureData.length > 0 && (
                  <Card className="border-left-0 border-right-0 border-bottom-0 py-2 mx-3">
                    <Row>
                      <Col>
                        <>
                          <h4 className="_subHeadingInActivity">Job Aids</h4>
                            {procedureData.map((item) => (
                              <div className="_linksInActiviy" key={item.name}>
                              <a href={item.url} rel="noopener noreferrer" title={item.name}>{item.name} </a>
                              </div>
                            ))}
                        </>
                      </Col>
                    </Row>
                  </Card>
                )}
                
                {relatedBusinessContent.length > 0 && (
                  <Card className="border-left-0 border-right-0 border-bottom-0 py-2 mx-3">
                    <Row>
                      <Col>
                        <>
                          <h4 className="_subHeadingInActivity">Business Rule</h4>
                            {relatedBusinessContent.map((item) => (
                              <div className="_linksInActiviy _linksInActiviyBR" key={item.name}>
                              <a href={item.url} rel="noopener noreferrer" title={item.name}>{item.name}</a>
                              </div>
                            ))}
                        </>
                      </Col>
                    </Row>
                  </Card>
                )}

        </Col>
      </Row> 
      )}     
      </Container>


{/*  
    <div className="activityLandinPage" role="main">
      <div className="activityBreadcrumb">
        <Breadcrumbs />
        <FavoriteAndFeedback />
      </div>
      {showHistory ? (
        <HistoryPreview
          contriutorLastSuggestions={contributorsData.resultCalloutJson}
          reviewedSuggestions={contributorsData.reviewedSuggestionsJson}
          contributorContent={contributorsData.lastContributorActivityContent}
          activityContent={activityContent}
          templateData={templateData}
          topicData={topicData}
          procedureData={procedureData}
          entryCriteriaData={entryCriteriaData}
          exitCriteriaData={exitCriteriaData}
          taskData={taskData}
          complexities={complexities}
          setComplexityName={setfilData}
          complexityName={filData}
          complexityId={complexityId}
          users={ckEditorUsers}
          currentUser={CkEditorCurrentUser}
          historyToolsId={historyToolsId}
          historicalPreviewTools={historicalPreviewTools}
          historyToolsSuggestion={historyToolsSuggestion}
          toolsReviewedIds={toolsReviewedIds}
          ckEditorUserMap={ckEditorUserMap}
          reviewerInfo={reviewerInfo}
          activityToolsList={contributorsData.activityToolsList}
          toolsUrl={toolsUrl}
          existingHashtags={existingHashtags}
        />
      ) : null}
      <div className="activityMainContainer">
        <div className="activityDescriptionDetails">
          <div className="activityDescTitlePart">
            <div className="activityTitleRightPart">
              <span className="activityVersion">
                {`Document Version: ${activityContent.activityVersion},`}
                &nbsp;&nbsp;
                {`${activityContent.date} `}
              </span>
              {isEnableComplexity && (
                <div className="activityComplexity">
                  <span>Complexity:</span>
                  <Dropdown
                    className="complexityFilter"
                    data={complexities}
                    setValue={setfilData}
                    value={filData}
                    enableDropdown={complexityEnabled}
                    newDropDownBox={'newDropDownBox'}
                    newDropDownContainer={'newDropDownContainer'}
                    newWrapHeader={'newWrapHeader'}
                  />
                </div>
              )}
              {(!isReviewer && !isApprover) ||
              (isReviewer && editingStateId === 0) ||
              (isReviewer && editingStateId === 2 && !isApprover) ||
              (isApprover && editingStateId === 0) ||
              (isApprover && editingStateId === 1 && !isReviewer) ? (
                <div
                  className={`activityEdit ${
                    editingStateId === 2 ||
                    activityContent.isExternal ||
                    activityContent.methodIsRetired ||
                    !activityContent.phaseIds ||
                    isActivityLock ||
                    !activityContent.isApproverGroupExist ||
                    !activityContent.isReviewerGroupExist
                      ? "disabledEditActivity"
                      : null
                  }`}
                  title={
                    showTooltip
                      ? "You can not edit this content"
                      : "Suggest Edit"
                  }
                  onMouseEnter={checkAndShowTooltip}
                >
                  <Link
                    title="Suggest Edit"
                    tabIndex={editingStateId === 2 ? -1 : 0}
                    aria-label={
                      showTooltip
                        ? "You can not edit this content."
                        : "Suggest Edit"
                    }
                    onClick={() => {
                      track(
                        1,
                        130,
                        activityContent.activityId,
                        activityContent.activityName
                      );
                    }}
                    to={
                      isReviewer && editingStateId === 0
                        ? `${suggestEditUrl}/${activityContent.activityId}/reviewer/edit?complexity=${complexityId}&identifier=${activityContent.activityIdentifier}`
                        : `${suggestEditUrl}/${activityContent.activityId}/edit?complexity=${complexityId}&identifier=${activityContent.activityIdentifier}`
                    }
                    role="button"
                  >
                    Suggest Edit
                    <span className="editIcon" />
                  </Link>
                </div>
              ) : null}
            </div>
          </div>
          {showTooltip && (
            <MessageBar
              messageBarType={MessageBarType.warning}
              isMultiline={false}
            >
              <p style={{marginTop:'2px'}}>
                Editing this content isn't possible due to an activity
                configuration issue. Please contact{" "}
                <a
                  target="_blank"
                  href={`https://teams.microsoft.com/l/chat/0/0?users=${solutionMethodContentOwnerEmail}`}
                  className="anchorTag"
                >
                  {" "}
                  {solutionMethodContentOwner}{" "}
                </a>
                for more help.
              </p>
            </MessageBar>
          )}
          <div className="descOfActivity">
            {isReviewer && editingStateId === 1 ? (
              <div className="approverMainWrapper">
                <div className="aprroverStatusIcon"></div>
                <p>You have content pending for review</p>
                <Link
                  tabIndex={0}
                  aria-label="Review Edit"
                  onKeyPress={(e) => {
                    if (e.which === 13) {
                      track(
                        1,
                        131,
                        activityContent.activityId,
                        activityContent.activityName
                      );
                    }
                  }}
                  onClick={() => {
                    track(
                      1,
                      131,
                      activityContent.activityId,
                      activityContent.activityName
                    );
                  }}
                  to={`${suggestEditUrl}/${activityContent.activityId}/reviewer/edit?complexity=${complexityId}&identifier=${activityContent.activityIdentifier}`}
                >
                  <button
                    tabIndex={-1}
                    type="button"
                    className="approverApproveEditButton btn btn-primary"
                  >
                    Review &amp; Edit
                  </button>
                </Link>
              </div>
            ) : (
              <>
                {isApprover && editingStateId === 2 ? (
                  <div className="approverMainWrapper">
                    <div className="aprroverStatusIcon"></div>
                    <p>You have content pending for approval</p>
                    <Link
                      tabIndex={0}
                      aria-label="Edit and Approve"
                      onKeyPress={(e) => {
                        if (e.which === 13) {
                          track(
                            1,
                            132,
                            activityContent.activityId,
                            activityContent.activityName
                          );
                        }
                      }}
                      onClick={() => {
                        track(
                          1,
                          132,
                          activityContent.activityId,
                          activityContent.activityName
                        );
                      }}
                      to={`${suggestEditUrl}/${activityContent.activityId}/approver/edit?complexity=${complexityId}&identifier=${activityContent.activityIdentifier}`}
                    >
                      <button
                        tabIndex={-1}
                        type="button"
                        className="approverApproveEditButton btn btn-primary"
                      >
                        Approve &amp; Edit
                      </button>
                    </Link>
                  </div>
                ) : null}
              </>
            )}
            <div style={{display:'flex',justifyContent:'space-between',
            alignItems:'center'}}>
            <h1
              title={activityContent.activityName}
              className="activityLandingPageTitle"
            >
              {activityContent.activityName}
            </h1>
            <div className="imgContainer">
            <img className="ownerImg" src={activityOwner.photo}
            onClick={()=>setShowActivityOwnerCollaborators(true)}/>
            <span className="ownerInfo">
            <span>Owner</span>
            <a href={`mailto:${activityOwner.mail}`}>
            <span style={{color:'#1979c2',fontWeight:'bolder'}}>
            {activityOwner.firstName +
              " " +
            activityOwner.surname} 
            </span>
            </a>
            </span>
            <span className="collaboratorsImg" onClick={()=>setShowActivityOwnerCollaborators(true)}></span>
          </div>
            </div>
            <div className="existingHashTagsOfActivity">
              {!showHashtagsForm &&
                existingHashtags.map((name) => (
                  <span
                    role="link"
                    aria-label={name}
                    tabIndex={0}
                    key={name}
                    onClick={() => {
                      history.push(
                        `/search?searchParam=${encodeURIComponent(name)}`
                      );
                    }}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        history.push(
                          `/search?searchParam=${encodeURIComponent(name)}`
                        );
                      }
                    }}
                  >
                    {name}
                  </span>
                ))}

              {!showHashtagsForm ? (
                <div className="addHashtagsIconContainer">
                  <span
                    title="Add Hashtags"
                    className="addHashtagsIcon"
                    onClick={() => {
                      setShowHashtagsForm(true);
                    }}
                    onKeyPress={(e) => {
                      if (e.which === 13) {
                        setShowHashtagsForm(true);
                      }
                    }}
                    tabIndex="0"
                    role="button"
                    aria-label="Add Hashtags"
                  ></span>
                  {/* <div class="tabsTitleToolTip">
                    <span class="tabsToolTipArrow"></span>
                    <span>Add Hashtags</span>
                  </div> */}
                  {/*  
                </div>
              ) : (
                <></>
              )}
            </div>

            {showHashtagsForm ? (
              <div className="activityDetailsHasttagsContainer">
                <div className="activityDetailsHasttagInput">
                  <HashtagSuggestions
                    className="activityHashtagsInput"
                    enableDropdown={true}
                    data={hashtagSuggestions}
                    setUpdateApiCall={setUpdateApiCall}
                    setHashtagSuggestions={setHashtagSuggestions}
                    searchValue={hashtagsInput}
                    setSearchValue={setHashtagsInput}
                    existingHashtags={existingHashtags}
                    userAddedHashtags={userAddedHashtags}
                    setUserAddedHashtags={setUserAddedHashtags}
                    hashtagSuggestionInput={hashtagSuggestionInput}
                    setHashtagSuggestionInput={setHashtagSuggestionInput}
                    closeHashtagForm={() => {
                      setHashtagsInput("");
                      setShowHashtagsForm(false);
                      setUserAddedHashtags([]);
                    }}
                    submitHashtags={submitHashtags}
                    autoFocus={true}
                    showSubmitButton={true}
                  />
                </div>

                {existingHashtags.length > 0 ? (
                  <div className="hashTagsContainer">
                    {existingHashtags.map((item) => (
                      <span key={item}>{item}</span>
                    ))}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}

            {activityContent.isExternal ||
            activityContent.methodIsRetired ||
            !activityContent.phaseIds ? (
              <div className="WarningMessageActivityPage">
                <MessageBar
                  messageBarType={MessageBarType.warning}
                  isMultiline={false}
                >
                  You can not edit this content due to incorrect configuration
                  of the activity. Please contact SDMPlus support for further
                  help.
                </MessageBar>
              </div>
            ) : null}
            {Number(editingStateId) === 2 && isReviewer && !isApprover ? (
              <div className="WarningMessageActivityPage">
                {contributorsData.lastContributorActivityContent ? (
                  <MessageBar
                    messageBarType={MessageBarType.warning}
                    isMultiline={false}
                  >
                    You can not edit this content now. A newer version of the
                    content is pending for approval. You can see a&nbsp;
                    <Link
                      tabIndex={0}
                      onKeyPress={(e) => {
                        if (e.which === 13) {
                          e.preventDefault();
                          showHistoryPreview();
                        }
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        showHistoryPreview();
                      }}
                      to={"/"}
                    >
                      Historical Preview here.
                    </Link>
                  </MessageBar>
                ) : (
                  <MessageBar
                    messageBarType={MessageBarType.warning}
                    isMultiline={false}
                  >
                    You can not edit this content now. A newer version of the
                    content is pending for approval. There is no history
                    available for this Activity since the contribution made by
                    a reviewer.
                    <p>
                      Please Contact &nbsp;
                      <a
                        target="_blank"
                        href={`https://teams.microsoft.com/l/chat/0/0?users=${solutionMethodContentOwnerEmail}`}
                      >
                        {solutionMethodContentOwner}
                      </a>
                    </p>
                  </MessageBar>
                )}
              </div>
            ) : (
              <>
                {Number(editingStateId) === 2 && !isApprover ? (
                  <div className="WarningMessageActivityPage">
                    <MessageBar
                      messageBarType={MessageBarType.warning}
                      isMultiline={false}
                    >
                      You can not edit this content now. A newer version of
                      the content is pending for approval.
                      {/* Please check later for editing. */}
                      {/*  
                      <p>
                        Please Contact &nbsp;
                        <a
                          target="_blank"
                          href={`https://teams.microsoft.com/l/chat/0/0?users=${solutionMethodContentOwnerEmail}`}
                        >
                          {solutionMethodContentOwner}
                        </a>
                      </p>
                    </MessageBar>
                  </div>
                ) : null}
              </>
            )}
            {isActivityLock ? (
              <div className="WarningMessageActivityPage">
                <MessageBar
                  messageBarType={MessageBarType.warning}
                  isMultiline={false}
                >
                  You can not edit this content now. Content is being updated
                  by
                  <a
                    target="_blank"
                    href={`https://teams.microsoft.com/l/chat/0/0?users=${activityLockedBy}`}
                  >
                    {lockedByUsername}
                  </a>
                  {" ."}
                  Please check later for editing.
                </MessageBar>
              </div>
            ) : (
              <></>
            )}
            <ActivityRaciMatrix activityData={activityContent} />
            

            { activityContent.isProcessStep && <span className="mandatoryProcessStepHeading">
              Note: This is a mandatory process step.</span>
            }
            
            <div className="innerContent ck-header" id="innerContent">
              {!inValid.includes(activityContent.activityDescription) ? (
                <h2
                  className="sub-heading"
                  id="desc"
                  aria-label="Description"
                >
                  Description
                </h2>
              ) : (
                <></>
              )}
              <div
                className="desc ck-content"
                id="description"
                dangerouslySetInnerHTML={{
                  __html: HtmlSanitizer(
                    activityContent.activityDescription && 
                      activityContent.activityDescription.includes('<img') ? 
                      activityContent.activityDescription.replace(/<img/, '<img alt="Description" tabIndex="0"') 
                      : activityContent.activityDescription
                    ),
              }}
              />

              {!inValid.includes(
                activityContent.activitySupportingActivities
              ) ? (
                <h2
                  className="sub-heading"
                  id="supportAct"
                  aria-label="Supporting Activities"
                >
                  Supporting Activities
                </h2>
              ) : (
                <></>
              )}
              <div
                className="descSA ck-content"
                id="support"
                dangerouslySetInnerHTML={{
                  __html: HtmlSanitizer(
            activityContent.activitySupportingActivities && 
                      activityContent.activitySupportingActivities.includes('<img') ? 
                      activityContent.activitySupportingActivities.replace(/<img/, '<img alt="Supporting Activities" tabIndex="0"') 
                      : activityContent.activitySupportingActivities
                  ),
                }}
              />

              {!inValid.includes(activityContent.activityEntryCriteria) ? (
                <h2
                  className="sub-heading"
                  id="entryc"
                  aria-label="Entry Criteria/Input"
                >
                  Entry Criteria/Input
                </h2>
              ) : (
                <></>
              )}
              <div
                className="descEn ck-content"
                id="entryCriteria"
                dangerouslySetInnerHTML={{
                  __html: HtmlSanitizer(
            activityContent.activityEntryCriteria && 
            activityContent.activityEntryCriteria.includes('<img') ? 
            activityContent.activityEntryCriteria.replace(/<img/, '<img alt="Entry Criteria" tabIndex="0"') 
            : activityContent.activityEntryCriteria
                  ),
                }}
              />

              {!inValid.includes(activityContent.activityTasks) ? (
                <h2
                  className="sub-heading"
                  id="tasks"
                  aria-label="Tasks"
                >
                  Tasks
                </h2>
              ) : (
                <></>
              )}
              <div
                className="descT ck-content"
                id="task"
                dangerouslySetInnerHTML={{
                  __html: HtmlSanitizer(
            activityContent.activityTasks && 
            activityContent.activityTasks.includes('<img') ? 
            activityContent.activityTasks.replace(/<img/, '<img alt="Tasks" tabIndex="0"') 
            : activityContent.activityTasks
                    ),
                }}
              />
              
              {procedureData.length > 0 ? (
                <>
                  <h2
                    className="sub-heading"
                    id="activityTo"
                    aria-label="Procedures"
                  >
                    Procedures
                  </h2>
                  <ul>
                    {procedureData.map((procedure) => (
                      <>
                        <li>
                          <a href={procedure.url}>{procedure.name}</a>
                        </li>
                      </>
                    ))}
                  </ul>
                </>
              ) : (
                <></>
              )}
              {topicData.length > 0 ? (
                <>
                  <h2
                    className="sub-heading"
                    id="activityTopics"
                    aria-label="Related Topics"
                  >
                    Related Topics
                  </h2>
                  <ul>
                    {topicData.map((topicData) => (
                      <>
                        <li>
                          <a href={topicData.url}>{topicData.name}</a>
                        </li>
                      </>
                    ))}
                  </ul>
                </>
              ) : (
                <></>
              )}

              {!inValid.includes(activityContent.activityExitCriteria) ? (
                <h2 className="sub-heading" id="exitc">
                  Exit Criteria/Output
                </h2>
              ) : (
                <></>
              )}
              <div
                className="descEx ck-content"
                id="exitCriteria"
                dangerouslySetInnerHTML={{
                  __html: HtmlSanitizer(
            activityContent.activityExitCriteria && 
            activityContent.activityExitCriteria.includes('<img') ? 
            activityContent.activityExitCriteria.replace(/<img/, '<img alt="Exit Criteria" tabIndex="0"') 
            : activityContent.activityExitCriteria
                    ),
                }}
              />

              <h2 className="sub-heading" id="deliver">
                <span>Deliverables</span>
              </h2>

              {templateData.length > 0 ? (
                <div id="tempList">
                  <ul>
                    {templateData.map((template) => (
                      <li>
                        {template.inProgress === true ? (
                          <a
                            className="noClick"
                            href={template.fileLocation}
                            target="_blank"
                          >
                            {template.title}
                          </a>
                        ) : (
                          <a href={template.fileLocation} target="_blank">
                            {template.title}
                          </a>
                        )}
                        {template.sampleTemplates.length !== 0 &&
                        !template.inProgress ? (
                          <button
                            className="activitySampleButton"
                            tabIndex={0}
                            onKeyPress={(e) => {
                              if (e.which === 13) {
                                setSampleTemplates({
                                  enable: true,
                                  id: template.id,
                                  parentTemplate: template,
                                });
                              }
                            }}
                            onClick={() => {
                              setSampleTemplates({
                                enable: true,
                                id: template.id,
                                parentTemplate: template,
                              });
                            }}
                          >
                            (Sample)
                          </button>
                        ) : (
                          <></>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
              {activityContent.activityDeliverables ? (
                <div
                className="ck-content"
                  id="tempList"
                  dangerouslySetInnerHTML={{
                    __html: HtmlSanitizer(
                      activityContent.activityDeliverables && 
                      activityContent.activityDeliverables.includes('<img') ? 
                      activityContent.activityDeliverables.replace(/<img/, '<img alt="Deliverables" tabIndex="0"') 
                      : activityContent.activityDeliverables
              ),
            }}
                />
              ) : null}

              {activityContent.activityToolsCount > 0 ? (
                <>
                  <h2 className="sub-heading" id="tool">
                    Tools
                  </h2>
                  <ul>
                    {activityContent.activityToolsList.map((toolsData) => (
                      <>
                        {toolsData.isSelected ? (
                          <li>
                            <a href={toolsData.url} target="_blank">
                              {toolsData.name}
                            </a>
                          </li>
                        ) : (
                          <></>
                        )}
                      </>
                    ))}
                  </ul>
                </>
              ) : (
                <></>
              )}
                 {relatedBusinessContent.length ? (
                  <>
                   <h2 className="sub-heading" id="tipstech">
                  Business Rule
                </h2>
                <ul>
                  {relatedBusinessContent.map((item) => (
                    <li key={item.id}>
                      <div>
                        <a
                          href={item.url}
                          target="_blank"
                          title={item.name}
                        >
                          {item.name}
                        </a>
                      </div>
                    </li>
                  ))}
                </ul>
                </>
              ) : (
                <></>
              )}

              {!inValid.includes(
                activityContent.activityTipsAndTechniques
              ) ? (
                <h2 className="sub-heading" id="tipstech">
                  Guidance, Tips and Techniques
                </h2>
              ) : (
                <></>
              )}
              
              <div
                className="descTT ck-content"
                id="descTt"
                dangerouslySetInnerHTML={{
                  __html: HtmlSanitizer(
                    activityContent.activityTipsAndTechniques && 
                    activityContent.activityTipsAndTechniques.includes('<img') ? 
                    activityContent.activityTipsAndTechniques.replace(/<img/, '<img alt="Guidance Tips and Techniques" tabIndex="0"') 
                    : activityContent.activityTipsAndTechniques
                  ),
                }}
              />
            </div>
          </div>
        </div>
        {/* <ActivityYammer
          activityId={activityId}
          filData={filData}
          complexityId={complexityId}
          activityName={activityContent.activityName}
        /> */}
        {/*  
      </div>
    </div>
  */}

    
    {showActivityTemplateBuilder ? (
      <ActivityTemplateBuilder
        showActivityTemplateBuilder={showActivityTemplateBuilder}
        setShowActivityTemplateBuilder={setShowActivityTemplateBuilder}
        activityId={activityContent.activityId}
        complexityId={complexityId}
        existingTemplates={templateData}
      />
    ) : (
      <></>
    )}
    {enableTemplatePreview ? (
      <PreviewModal
        enableTemplatePreview={enableTemplatePreview}
        closeModal={() => setEnableTemplatePreview(false)}
        data={templatePreviewData}
      />
    ) : (
      <></>
    )}
    {enableFileDropModal ? (
      <FileDropModal
        enableFileDropModal={enableFileDropModal}
        closeModal={() => setEnableFileDropModal(false)}
        data={sampleTemplates.parentTemplate}
      />
    ) : (
      <></>
    )}
    <Modal
      show={sampleTemplates.enable}
      onHide={() => {
        setSampleTemplates({
          id: null,
          enable: false,
          parentTemplate: null,
        });
      }}
      centered
      dialogClassName="templateModal"
    >
      {sampleTemplates.enable ? (
        <SampleTemplateModal
          id={sampleTemplates.id}
          handleTemplatePreview={handleTemplatePreview}
          setEnableFileDropModal={setEnableFileDropModal}
          closeModal={() => {
            setSampleTemplates({
              id: null,
              enable: false,
              parentTemplate: null,
            });
          }}
          enableFileDropModal={enableFileDropModal}
          complexityId={filData.id}
        />
      ) : (
        <></>
      )}
    </Modal>
    {/* <TopicCollabrators/> */}
    {showActivityOwnerCollaborators? <ActivityCollaborators
    setShowActivityOwnerCollaborators={setShowActivityOwnerCollaborators}
    activityName={activityContent.activityName}
    artifactName={'Activity'}
    // artifactOwner={activityOwner}
    // setArtifactOwner={setActivityOwner}
    existingCoOwners={existingCoOwners}
    existingReviewers={existingReviewers}
    existingApprovers = {existingApprovers}
    showCollaboratorSpinner={showCollaboratorSpinner}
    previousPostData={previousPostData}
    isAdminOrOwner={isAdminOrOwner}
    setIsAdminOrOwner={setIsAdminOrOwner}
    isEdited={isEdited}
    setIsEdited={setIsEdited}
    setExistingApprovers={setExistingApprovers}
    setExistingCoOwners={setExistingCoOwners}
    setExistingReviewers={setExistingReviewers}
    solutionMethodContentOwner={solutionMethodContentOwner}
    solutionMethodContentOwnerEmail={solutionMethodContentOwnerEmail}
    prevArtifactOwner={prevActivityOwner} // Pass the previous state to the child
  setPrevArtifactOwner={setPrevActivityOwner} // Pass the state setter to the child
    />
    :<></>}
  </div>
);
}

export default Activity;
