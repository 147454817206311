import React, { useRef, useEffect, useState } from 'react';
import {
	useParams,
	Switch,
	Route,
	useRouteMatch,
	useHistory,
	Link,
	NavLink as ReactRouterNavLink
} from 'react-router-dom';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { Icon } from '@fluentui/react';
import Modal from 'react-bootstrap/Modal';
import { Button, NavLink } from 'react-bootstrap';
import $ from 'jquery';
import queryString from 'query-string';
import { useAxios } from '../../context/AxiosContext';
import { useLeftMenuCollapse } from '../../context/LeftMenuCollapseContext';
import { useAppInsights } from '../../context/TelemetryContext';
import Phase from '../Phase';
import Activity from '../Activity';
import Complexity from '../Complexity';
import Breadcrumbs from '../Breadcrumbs';
import PhaseLeftMenu from '../PhaseLeftMenu';
import PhaseShowMore from '../PhaseShowMore';
import DeliveryPhase from '../DeliveryPhase';
import DeliveryManagement from '../DeliveryManagement';
import SolutionMethod from '../../views/SolutionMethod';
import DeliveryManagementDesc from '../../views/DeliveryManagement';
import ContributorActivityCrowdSourcing from '../ActivityContributorCrowdSourcing';
import ReviewerActivityCrowdSourcing from '../ActivityReviewerCrowdSourcing';
import ApproverActivityCrowdSourcing from '../ActivityApproverCrowdSourcing';
import FavoriteAndFeedback from '../FavoriteAndFeedback';
import ActivityUpdatePage from '../NewActivityDetailsPage';
import Spinner from '../../helpers/Spinner';
import DeletedEntityModel from '../DeletedEntity';
import HashtagSuggestions from './HashtagSuggestions';
import PhaseSelectionModal from './PhaseSelectionModal';
import MatrixView from './MatrixView';
import ExtractRolesModal from './ExtractRoles';
import { TabTitle } from '../../utils/GeneralFunctions';
import './LandingPage.css';
import './RealizeValueLandingPage.css'
import ProjectDeliveryPhases from './ProjectDeliveryPhases';
import ExtractMethodlogy from '../LeftMenu/ExtractMethodology';
import ActivityCollaborators from '../Activity/ActivityCollaborators';
import { getOtherUserProfile } from '../../helpers/GraphService';
import { axiosTokenService } from '../CkEditor/api/axiosBaseUrl';
import AdminEditSolutionMethod from '../SolutionMethod/SolutionMethod';


function RealizeValueLandingPage() {
	const showMatrixViewParam = window.location.search
		? queryString.parse(window.location.search).showMatrixView
		: false;
	const history = useHistory();
	const { projectTypeId, solutionMethodId, phaseId, parentPhaseId } = useParams();
	const { url } = useRouteMatch();
	const location = window.location.pathname;
	const {
		appState,
		setAppState,
		setCollapsedLeftMenu,
		phase,
		parentPhase,
		setExpandedActivityGroups,
		setComplexity,
		setRole,
		setPhase,
		setParentPhase,
		breadcrumbs,
		setBreadcrumbs,
		svg,
		setSvg,
		phaseHashtags,
		setPhaseHashtags,
		phaseDynamicFilterTag,
		setPhaseDynamicFilterTag,
		phaseDynamicFilterTagMapping,
		setPhaseDynamicFilterTagMapping,
		endUserView,
		showRaciViewBanner,
		showSupportBanner,
		complexity,
		supportSolutionMethods,
		consultingSolutionMethods,
	} = useLeftMenuCollapse();
	const { track } = useAppInsights();
	const { axiosGetService, axiosPostService, userAlias } = useAxios();
	const { defaultComplexity, defaultRole, defaultPhaseObject } = appState;
	const [solutionMethod, setSolutionMethod] = useState({
		phasesList: [],
	});
	const [parentSolutionMethod, setParentSolutionMethod] = useState({
		phasesList: [],
	});
	const [visible, setVisible] = useState({
		leftPanel: true,
		rightPanel: true,
	});
	const [hasParentMethod, setHasParentMethod] = useState(false);
	const [showMoreVisible, setShowMoreVisible] = useState(false);
	const [showMoreDescription, setShowMoreDescription] = useState(null);
	const [svgExpanded, setSvgExpanded] = useState(false);
	const [showFilters, setShowFilters] = useState(false);
	const [selectedComplexity, setSelectedComplexity] = useState(defaultComplexity.name);
	const [selectedRoles, setSelectedRoles] = useState(null);
	const [showGroup, setShowGroup] = useState(false);
	const [tagFilterHeight, setTagFilterHeight] = useState(false);
	const [phaseCreated, setPhaseCreated] = useState(false);
	const [showPublishButton, setShowPublishButton] = useState(false);
	const [isParentPhaseDrafted, setIsParentPhaseDrafted] = useState(false);
	const [showPublishModal, setShowPublishModal] = useState(false);
	const [showParentPhasePublishModal, setShowParentPhasePublishModal] =
		useState(false);
	const [showPhaseSelectionModal, setShowPhaseSelectionModal] = useState(false);
	const [visioNotFound, setVisioNotFound] = useState(false);
	const [coOwners, setCoOwners] = useState([]);
	const [parentPhaseCoOwners, setParentPhaseCoOwners] = useState([]);
	const [isUserValidated, setIsUserValidated] = useState(false);
	const [deletePhase, setDeletePhase] = useState(false);
	const [showUpdatePhasePopUp, setShowPhaseUpdatePopUp] = useState(false);
	const [deletePhaseId, setDeletePhaseId] = useState(0);
	const [showDeletePhaseModal, setShowDeletePhaseModal] = useState(false);
	const [showParentDeletePhaseModal, setShowParentDeletePhaseModal] =
		useState(false);
	const [isActivityCountZero, setIsActivityCountZero] = useState(false);
	const [isParentActivityCountZero, setIsParentActivityCountZero] =
		useState(false);
	const [apiCallMade, setApiCallMade] = useState(false);
	const [deletePhaseName, setDeletePhaseName] = useState('');
	const [hashtagSuggestions, setHashtagSuggestions] = useState([]);
	const [searchInput, setSearchInput] = useState('');
	const [hashtagSearchInput, setHashtagSearchInput] = useState('');
	const [showPhaseDeletionMessage, setShowPhaseDeletionMessage] =
		useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const [parentPhaseCreated, setParentPhaseCreated] = useState(false);
	const [phasePublishTitle, setPhasePublishTitle] = useState('');
	const [parentPhasePublishTitle, setParentPhasePublishTitle] = useState('');
	const [showPhaseUpdateModal, setShowPhaseUpdateModal] = useState(false);
	const [updatePhaseId, setUpdatePhaseId] = useState(0);
	const [selectedPhaseTab, setSelectedPhaseTab] = useState(2);
	const [showParentPhaseUpdateModal, setShowParentPhaseUpdateModal] =
		useState(false);
	const [updateParentPhaseId, setUpdateParentPhaseId] = useState(0);
	const [selectedParentPhaseTab, setSelectedParentPhaseTab] = useState(1);
	const [selectedPhase, setSelectedPhase] = useState(null);
	const [showMatrixView, setShowMatrixView] = useState(false);
	const [selectedComplexityId, setSelectedComplexityId] = useState(
		defaultComplexity.id
	);
	const [selectedRoleId, setSelectedRoleId] = useState(defaultRole.id);
	const [showDefaultViewModal, setShowDefaultViewModal] = useState(false);
	const [updateComplexityFilter, setUpdateComplexityFilter] = useState(false);
	const [handleExtractRoles, setHandleExtractRoles] = useState(false);
	const [supportBannerMessage, setSupportBannerMessage] = useState('');
	const [raciViewBanner, setRaciViewBanner] = useState('');
	const [complexities, setComplexities] = useState([]);
	const [isEnableComplexity, setIsEnableComplexity] = useState();
	const [realizeValueToggler, setRealizeValueToggler] = useState(1)
	const [isProgramDelivery, setIsProgramDelivery] = useState('')
	const [isProjectDelivery, setIsProjectDelivery] = useState('')
	const [programDeliveryData, setProgramDeliveryData] = useState([])
	const [projectDeliveryPhases, setProjectDeliveryPhases] = useState([])
	const [isPhaseSelected, setIsPhaseSelected] = useState(true)
	const [isActiveSolution, setIsActiveSolution] = useState()
	const [deliveryManagementId, setDeliveryManagementId] = useState('')
	const [projectId] = useState(1);
	const [showPhaseOwnerCollaborators, setShowPhaseOwnerCollaborators] = useState(false)
	const [solutionMethodOwner, setSolutionMethodOwner] = useState({})
	const [existingReviewers, setExistingReviewers] = useState([]);
	const [existingApprovers, setExistingApprovers] = useState([]);
	const [existingCoOwners, setExistingCoOwners] = useState([]);
	const [solutionMethodCollaborators, setSolutionMethodCollaborators] = useState({})
	const [showCollaboratorSpinner, setShowCollaboratorSpinner] = useState(true);

	const [showMethodProgramMenu, setShowMethodProgramMenu] = useState(false);
	const ref = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [showExtractMethodlogy, setShowExtractMethodlogy] = useState(true);
	const [extractMethodlogyId, setExtractMethodologyId] = useState(0);
	const [solutionMethodComplexities, setSolutionMethodComplexities] = useState([]);
	const [solutionMethodName, setSolutionMethodName] = useState();
	// const { defaultComplexity, projectTypeId } = appState;
	const [selectedSolutionComplexity, setSelectedSolutionComplexity] = useState(defaultComplexity);
	const [showEditSolutionMethodlogy, setShowEditSolutionMethodlogy] = useState(false);
	const [selectedSolutionMethod, setSelectedSolutionMethod] = useState(0);
	const [editProjectId, setEditProjectId] = useState(0);
	const [isAdmin, setIsAdmin] = useState(false);
	const [isEditSolutionMethod, setIsEditSolutionMethod] = useState(false);
	const [isDeleteSolutionMethod, setIsDeleteSolutionMethod] = useState(false);
	const [isSolutionCollaboratorsEdited,setIsSolutionCollaboratorsEdited]=useState(false)
	const [isPhaseEdited, setIsPhaseEdited] = useState(false)
	const [updateApiCall, setUpdateApiCall] = useState(false);
	const moveLeftPanel = () =>
		setVisible({ ...visible, rightPanel: !visible.rightPanel });
	const moveRightPanel = () =>
		setVisible({ ...visible, leftPanel: !visible.leftPanel });

	const setDefaultView = (newDefaultViewId) => {
		setShowDefaultViewModal(false);
		if (solutionMethod.defaultView !== newDefaultViewId) {
			setApiCallMade(true);
			axiosPostService('api/sdmsolutionmethod/updatedefaultview', {
				...solutionMethod,
				defaultView: newDefaultViewId,
			}).then((response) => {
				setSolutionMethod((prevState) => {
					return { ...prevState, defaultView: response.data.defaultView };
				});
				setApiCallMade(false);
				if (response.data.defaultView === 1) {
					setShowMatrixView(true);
				} else if (response.data.defaultView === 2) {
					setShowMatrixView(false);
				}
			});
		}
	};
	useEffect(() => {
		setSelectedComplexity(defaultComplexity.name)
		setSelectedComplexityId(defaultComplexity.id)
	}, [defaultComplexity]);

	const getIsAdmin = async () => {
		await axiosGetService('api/user/profile').then((res) => {
			setIsAdmin(res.data.isAdmin);
		});
	  }

	useEffect(()=>{
		getIsAdmin();
	},[]);
	const hashtagsAutoComplete = async () => {
		await axiosGetService(
			`api/sdmhashtags/autocomplete?hashTag=${encodeURIComponent(
				hashtagSearchInput
			)}`
		).then((response) => {
			setHashtagSuggestions(response.data);
		});
	};

	const getParentPhaseCoOwnerGroupId = async (id) => {
		await axiosGetService(
			`api/sdmsolutionmethod/ismethodownerorcoowner/${id}`
		).then((response) => {
			if (response.data) {
				const users = [userAlias];
				getParentSolutionMethodData(id, users);
				setParentPhaseCoOwners([...users]);
			} else {
				setParentPhaseCoOwners([]);
				getParentSolutionMethodData(id, []);
			}
		});
	};

	const getParentSolutionMethodData = async (id, coOwnersList) => {
		await axiosGetService(
			`/api/sdmsolutionmethod/getmergedphases/${id}/${coOwnersList.includes(userAlias) && !endUserView
			}/0/0/0/0/true/0`
		).then((response) => {
			setParentSolutionMethod(response.data);
			setIsUserValidated(true);
		});
	};

	const getSolutionMethodData = async (coOwnersList, type) => {
		await axiosGetService(
			`/api/sdmsolutionmethod/getmergedphases/${solutionMethodId}/${coOwnersList.includes(userAlias) && !endUserView
			}/0/0/0/0/false/0`
		).then((response) => {
			if (response.data && response.data.isDeleted) {
				setSolutionMethod({
					phasesList: [],
					errorMsg: response.data.errorMsg,
					isDeleted: true,
				});
				setIsUserValidated(true);
				setIsEnableComplexity(response.data.isComplexity)
			} else {
				if (response.data) {
					setIsEnableComplexity(response.data.isComplexity)
					setSolutionMethod(response.data);
					if (
						Number(response.data.parentId) === 0 ||
						response.data.parentId === null ||
						response.data.isMerged
					) {
						setHasParentMethod(false);
						setIsUserValidated(true);
					} else {
						TabTitle(response.data.solutionMethodName);
						getParentPhaseCoOwnerGroupId(response.data.parentId);
						setHasParentMethod(true);
					}
					if (type !== 'update') {
						if (showMatrixViewParam) {
							setShowMatrixView(true);
						} else if (response.data.defaultView === 1) {
							setShowMatrixView(true);
							setSvg({ enable: false, url: null, type: null });
						} else if (response.data.defaultView === 3) {
							setSvg({
								enable: true,
								url: null,
								type: 'phase',
								source: 'matrixView',
							});
						} else {
							setShowMatrixView(false);
							setSvg({ enable: false, url: null, type: null });
						}
					}
				} else {
					setIsEnableComplexity(response.data.isComplexity)
					setIsUserValidated(true);
					setSolutionMethod({
						phasesList: [],
					});
					setParentSolutionMethod({
						phasesList: [],
					});
				}
			}
		});
	};
	const getCoOwnerGroupId = async () => {
		await axiosGetService(
			`api/sdmsolutionmethod/ismethodownerorcoowner/${solutionMethodId}`
		).then((response) => {
			setIsUserValidated(false);
			if (response.data) {
				const users = [userAlias];
				getSolutionMethodData(users, 'source');
				setCoOwners([...users]);
			} else {
				setCoOwners([]);
				getSolutionMethodData([], 'source');
			}
		});
	};

	const getSupportBannerMessage = async () => {
		await axiosGetService(`api/sdmsettings?sdmKey=SupportBanner`).then(
			(response) => {
				setSupportBannerMessage(response.data[0].value);
			}
		);
	};
	const getRaciViewBanner = () =>
		axiosGetService(`api/sdmsettings?sdmKey=RACIViewBanner`).then(({ data }) =>
			setRaciViewBanner(data[0].value)
		);

	const onSolutionMethodChangeReset = () => {
		setComplexity(defaultComplexity);
		setRole(defaultRole);
		// setExpandedActivityGroups([]);
		setPhase(defaultPhaseObject);
		setParentPhase(defaultPhaseObject);
		if (svg.source !== 'matrixView') {
			setSvg({ enable: false, url: null, type: null });
		}
	};

	const openFullScreen = (elem) => {
		if (elem.msRequestFullscreen) {
			/* IE/Edge */
			elem.msRequestFullscreen();
		} else if (elem.webkitRequestFullscreen) {
			/* Chrome, Safari & Opera */
			elem.webkitRequestFullscreen();
		} else if (elem.mozRequestFullScreen) {
			/* Firefox */
			elem.mozRequestFullScreen();
		} else if (elem.requestFullscreen) {
			elem.requestFullscreen();
		}
	};

	const closeFullScreen = () => {
		if (document.msExitFullscreen) {
			document.msExitFullscreen();
		} else if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen();
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		} else if (document.exitFullscreen) {
			document.exitFullscreen();
		}
	};

	// on enter & exit fullscreen, setSvgExpanded
	const handleSvgExpand = () => {
		const elem = document.getElementById('svgPhase');
		if (svgExpanded) {
			closeFullScreen();
		} else {
			openFullScreen(elem);
		}
	};

	function handleFullScreen() {
		if (document.webkitFullscreenElement != null) {
			setSvgExpanded(true);
		} else {
			setSvgExpanded(false);
		}
	}

	const handleDynamicFilterTag = (artifactTypeId, tagId) => {
		const updatedProcessGroupFilterTag = phaseDynamicFilterTag[
			artifactTypeId
		].selectedTagId.filter((id) => id !== tagId);
		const updatedValue = {
			artifactTypeId: artifactTypeId,
			artifactTypeName: phaseDynamicFilterTag[artifactTypeId].artifactTypeName,
			selectedTagId: [...updatedProcessGroupFilterTag],
		};
		setPhaseDynamicFilterTag({
			...phaseDynamicFilterTag,
			[artifactTypeId]: updatedValue,
		});
	};

	const clearFilters = () => {
		setComplexity(defaultComplexity);
		setSelectedComplexity(defaultComplexity.name);
		const newDynamicFilterTag = { ...phaseDynamicFilterTag };
		const filterIds = Object.keys(newDynamicFilterTag);
		for (const id of filterIds) {
			newDynamicFilterTag[Number(id)].selectedTagId = [];
		}
		setPhaseDynamicFilterTag(newDynamicFilterTag);
		setHashtagSearchInput('');
		setRole(appState.defaultRole);
		setSelectedRoles(null);
		setSelectedRoleId(defaultRole.id);
		setPhaseHashtags([]);
	};

	const removeHashtag = (item) => {
		const updatehashtag = phaseHashtags.filter((hashtag) => hashtag !== item);
		setPhaseHashtags(updatehashtag);
	};

	const collapseFilter = (e) => {
		const filterArea = document.getElementById('filtersRightPanel');
		if (
			filterArea &&
			!filterArea.contains(e.target) &&
			e.target.className !== 'filtersButton' &&
			!(
				typeof e.target.className !== 'object' &&
				e.target.className &&
				e.target.className.includes('searchIcon')
			) &&
			e.target.className !== 'root-50' &&
			e.target.className !== 'root-56' &&
			e.target.className !== 'root-60' &&
			e.target.className !== 'showMoreFilter'
		) {
			setShowFilters(false);
		}
		const tagContainerHeight = $('.filterTagsContainer').height();
		if (Number(tagContainerHeight) > 36) {
			setTagFilterHeight(true);
		} else {
			setTagFilterHeight(false);
		}
	};

	const collapseDefaultViewModal = (e) => {
		const checkContainer = document.getElementById('defaultViewModalContainer');
		if (checkContainer && !checkContainer.contains(e.target)) {
			setShowDefaultViewModal(false);
		}
	};

	const updateComplexity = (complexityData, type) => {

		const complexityDetails = complexityData.filter(
			({ name }) => name === type
		);
		setSelectedComplexity(type);
		setComplexity(complexityDetails[0]);
		setSelectedComplexityId(complexityDetails[0].id);
	};

	// get complexities for dropDown
	const getPhaseComplexities = async () => {
		await axiosGetService(
			`api/sdmphase/getcomplexity/${solutionMethodId}/${phase.id && !showMatrixView ? phase.id : 0
			}/${parentPhase.id && !showMatrixView ? parentPhase.id : 0}`
		).then((res) => {
			const data = res.data.map((item) => {
				return {
					name: item.name,
					id: item.id,
					type: "option",
				};
			});
			const availableComplexities = data.map(({ name }) => {
				return name;
			});
			// if (!data.some((value) => value.id === complexity.id)) {
			// 	if (availableComplexities.includes("Medium")) {
			// 		updateComplexity(data, "Medium");
			// 	} else if (availableComplexities.includes("Critical")) {
			// 		updateComplexity(data, "Critical");
			// 	} else if (availableComplexities.includes("High")) {
			// 		updateComplexity(data, "High");
			// 	} else if (availableComplexities.includes("Low")) {
			// 		updateComplexity(data, "Low");
			// 	}
			// } else {
			if (complexity.id !== '') {
				updateComplexity(
					data,
					data.find((value) => value.id === complexity.id).name
				);
			}

			//}
			setComplexities(data);
		});
	};

	const getSolutionMethodComplexities = async () => {
		await axiosGetService("api/activities/projectcomplexity").then((res) => {
			const data = res.data.map((item) => {
				return {
					name: item.name,
					id: item.id,
					type: "option",
				};
			});
			setSolutionMethodComplexities(data);
		});
	};
	useEffect(() => {
		getSolutionMethodComplexities();
	}, []);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			if (hashtagSearchInput.trim() && hashtagSearchInput[0] === '#') {
				hashtagsAutoComplete();
			}
		}, 1000);

		return () => {
			setHashtagSuggestions([]);
			clearTimeout(timeOutId);
		};
	}, [hashtagSearchInput]);

	useEffect(() => {
		getCoOwnerGroupId();
		setSearchInput('');
		setHashtagSearchInput('');
		setSolutionMethod({
			phasesList: [],
		});
	}, [solutionMethodId, projectTypeId, endUserView]);

	// on phase change, reset panels and close showMore
	useEffect(() => {
		if (phase.id !== null) {
			setVisible({
				...visible,
				leftPanel: true,
			});
		}
		if (showMoreVisible) {
			setShowMoreVisible(false);
		}
		if (!phase.id) {
			setShowPublishButton(false);
		}
		setVisioNotFound(false);
	}, [phase.id]);

	// on parentPhase change, reset panels and close showMore
	useEffect(() => {
		if (parentPhase.id !== null) {
			setVisible({
				...visible,
				rightPanel: true,
			});
		}
		if (showMoreVisible) {
			setShowMoreVisible(false);
		}
	}, [parentPhase.id]);

	// on solutionMethod landing, close showMore and set defaultView
	useEffect(() => {
		if (location === url) {
			setShowMoreVisible(false);
			setVisible({
				leftPanel: true,
				rightPanel: true,
			});
			onSolutionMethodChangeReset();
		}
	}, [location]);

	useEffect(() => {
		setAppState({
			...appState,
			projectTypeId: Number(projectTypeId),
			solutionMethodId: null,
		});
		setAppState({
			...appState,
			projectTypeId: Number(projectTypeId),
			solutionMethodId: Number(solutionMethodId),
		});
		setShowPublishButton(false);
		setSelectedRoles(null);
		setSelectedComplexity(defaultComplexity.name);
		setDeletePhase(false);
		setIsActivityCountZero(false);
		setIsParentActivityCountZero(false);
		setIsParentPhaseDrafted(false);
		setPhasePublishTitle('');
		setParentPhasePublishTitle('');
		setShowMatrixView(false);
		setApiCallMade(false);
		setShowDefaultViewModal(false);
	}, [projectTypeId, solutionMethodId]);

	// onComponentLoad set ProjectTypeId, solutionMethodId from url params & get DeliveryManagement
	useEffect(() => {
		setAppState({
			...appState,
			projectTypeId: Number(projectTypeId),
			solutionMethodId: Number(solutionMethodId),
		});

		setCollapsedLeftMenu(true);

		document.addEventListener('fullscreenchange', handleFullScreen, false);
		document.addEventListener('mozfullscreenchange', handleFullScreen, false);
		document.addEventListener(
			'webkitfullscreenchange',
			handleFullScreen,
			false
		);
		document.addEventListener('msfullscreenchange', handleFullScreen, false);

		return () => {
			setBreadcrumbs(breadcrumbs.slice(0, 1));
			onSolutionMethodChangeReset();
			setPhaseDynamicFilterTag({});
			setPhaseDynamicFilterTagMapping({});
			setPhaseHashtags([]);
			document.removeEventListener('fullscreenchange', handleFullScreen, false);
			document.removeEventListener(
				'mozfullscreenchange',
				handleFullScreen,
				false
			);
			document.removeEventListener(
				'webkitfullscreenchange',
				handleFullScreen,
				false
			);
			document.removeEventListener(
				'msfullscreenchange',
				handleFullScreen,
				false
			);
		};
	}, []);

	useEffect(() => {
		if (showSupportBanner) {
			getSupportBannerMessage();
		}
	}, [showSupportBanner]);

	useEffect(() => {
		if (showRaciViewBanner) {
			getRaciViewBanner();
		}
	}, [showRaciViewBanner]);

	useEffect(() => {
		window.addEventListener('click', collapseFilter);
		return () => {
			window.removeEventListener('click', collapseFilter);
		};
	}, []);

	useEffect(() => {
		window.addEventListener('click', collapseDefaultViewModal);
		return () => {
			window.removeEventListener('click', collapseDefaultViewModal);
		};
	}, []);

	useEffect(() => {
		if (showMatrixView) {
			setShowMoreDescription(null);
			setShowMoreVisible(false);
		}
	}, [showMatrixView]);

	useEffect(() => {
		const timeOutId = setTimeout(() => {
			getPhaseComplexities();
		}, 3000);
		return () => {
			clearTimeout(timeOutId);
		};
	}, [
		parentPhase.id,
		phase.id,
		showMatrixView,
		phaseCreated,
		updateComplexityFilter,
		location,
		defaultComplexity,
		// complexity
	]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				setShowMethodProgramMenu(false);
			}
		};
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, [ref]);

	useEffect(() => {
			handleProjectDelivery();
	}, [isEditSolutionMethod,isDeleteSolutionMethod]);

	$(document).ready(function () {
		$('figure').each(function (index, element) {
			if (!element.getAttribute('contenteditable')) {
				const imgElement = element.children[0];
				$(imgElement).attr({ style: $(element).attr('style') });
			}
		});
	});

	const handleProgramDelivery = () => {
		setRealizeValueToggler(1)
		setIsPhaseSelected(true)
	}

	const handleProjectDelivery = async (id) => {
		const projectDeliveryId = isProjectDelivery === '' ? id : isProjectDelivery;
		
		if(projectDeliveryId !== undefined){
			setRealizeValueToggler(2)
			setIsPhaseSelected(false)
			setIsActiveSolution('')
			// await axiosGetService(
			// 	`/api/sdmsolutionmethod/projectdeliverymethodsbytype/${projectDeliveryId}/${true}`
			// )
				await axiosGetService(
					`/api/sdmsolutionmethod/projectdeliverymethodsbytype?methodTypeId=${projectId}&isProjectDelivery=${projectDeliveryId}&isSvgRequired=${true}&endUserView=${endUserView}`
				).then((response) => {
					setProjectDeliveryPhases(response.data)
				})
				.catch((err) => {
					console.log(err);
				});
		}

	};

	const getProgramDeliveryId = async () => {
		await axiosGetService(`api/sdmsettings?sdmKey=SDMProgramDeliveryId`).then(
			(response) => {
				setIsProgramDelivery(response.data[0].value)
			}
		);
	};
	const getProjectDeliveryId = async () => {
		const response = await axiosGetService(`api/sdmsettings?sdmKey=SDMNotInProjectDeliveryId`)
		setIsProjectDelivery(response.data[0].value)
		let id = response.data[0].value
		handleProjectDelivery(id);
	};
	const getProgramDeliveryData = async () => {
		await axiosGetService(`api/sdmsolutionmethod/getmergedphases/${Number(isProgramDelivery)}/true/0/0/0/0/false/0`)
			.then((response) => {
				setProgramDeliveryData(response.data)
			})
	}
	const getDeliveryManagementId = async () => {
		await axiosGetService(`api/sdmsettings?sdmKey=DeliveryManagementId`).then(
			(response) => {
				setDeliveryManagementId(Number(response.data[0].value))
			}
		);
	};

	useEffect(() => {
		getProgramDeliveryId();
		getProjectDeliveryId();
		getProgramDeliveryData()
		getDeliveryManagementId();
	}, [endUserView])

	const getSolutionMethodOwnerDetails = async (email) => {
		const accessToken = await axiosTokenService();
		const userData = await getOtherUserProfile(accessToken, email);
		setSolutionMethodOwner(userData);
	};

	const getUser = async (userEmail, setValue) => {
		setShowCollaboratorSpinner(true);
		const accessToken = await axiosTokenService();
		await getOtherUserProfile(accessToken, userEmail).then((response) => {
			setValue((prevState) => [...prevState, response]);
		});
		setTimeout(() => {
			setShowCollaboratorSpinner(false);
		}, 1000);
	};

	const getCollaboratorsInfo = async (membersList, setValue) => {
		for (const userEmail of membersList) {
			getUser(userEmail, setValue);
		}
	}


	const getCollaborators = async () => {
		await axiosGetService(
			`api/sdmactivity/getactivityorphaseormethodcollaborators/0/0/${solutionMethodId}`
		).then((response) => {
			setSolutionMethodCollaborators(response.data)
			getCollaboratorsInfo(response.data.coOwnerMembersList, setExistingCoOwners)
			getCollaboratorsInfo(response.data.approverMembersList, setExistingApprovers)
			getCollaboratorsInfo(response.data.reviewerMembersList, setExistingReviewers)
			getSolutionMethodOwnerDetails(response.data.owner)
			if (
				response.data.coOwnerMembersList.length === 0 &&
				response.data.reviewerMembersList.length === 0 &&
				response.data.approverMembersList.length === 0
			) {
				setShowCollaboratorSpinner(false);
			}
		}).catch((error) => {
			console.log(error)
		})
	}

	useEffect(() => {
		getCollaborators()
	}, [isSolutionCollaboratorsEdited])


	const handleSvgClick = () => {
		track(1, 151, 'Phase SVG Switch', 'Phase SVG Switch');
		if (svg.enable && svg.type === 'phase') {
			setSvg({
				enable: false,
				url: null,
				type: null,
			});
		} else if (phase.id !== null) {
			track(2, 151, phase.id, phase.title);
			setSvg({
				...svg,
				enable: true,
				type: 'phase',
				url: phase.svgFileName,
			});
		} else if (phase.id === null) {
			setSvg({
				enable: true,
				url: null,
				type: 'phase',
			});
		} else if (solutionMethod.solutionMethodId !== null) {
			setSvg({
				enable: true,
				type: 'solutionMethod',
				url: solutionMethod.visioFilePath || null, /* visioFilePath & svgFile */
			});
		}else {
			setSvg({
				enable: false,
				url: null,
				type: null,
			});
		}
	};
	const updateSvgFileName = () => {
		const selectedPhase = solutionMethod.phasesList.filter(
			({ phaseId }) => phase['id'] === phaseId
		);
		const selectedSoltionMethod = solutionMethod.visioFilePath;

		if (selectedPhase.length > 0) {
			setPhase((prevState) => ({
				...prevState,
				svgFileName: selectedPhase[0].svgFileName,
			}));
		}
	};

	return (
		<Switch>
			<Route path="/project/:projectTypeId/method/:solutionMethodId/phase/:phaseId/parentPhase/:parentPhaseId/updateActivity/:activityId/draft/:draftId/complexity/:complexityIds/default/:defaultComplexity">
				<ActivityUpdatePage
					isUserValidated={isUserValidated}
					getSolutionMethodData={getSolutionMethodData}
					coOwners={coOwners}
					isEnableComplexity={isEnableComplexity}
					solutionMethodOwner={solutionMethodOwner}
					existingCoOwners={existingCoOwners}
				/>
			</Route>
			<Route path="/project/:projectTypeId/method/:solutionMethodId/phase/:phaseId/parentPhase/:parentPhaseId/activity/:activityId/approver/edit">
				<ApproverActivityCrowdSourcing isEnableComplexity={isEnableComplexity} />
			</Route>
			<Route path="/project/:projectTypeId/method/:solutionMethodId/phase/:phaseId/parentPhase/:parentPhaseId/activity/:activityId/reviewer/edit">
				<ReviewerActivityCrowdSourcing isEnableComplexity={isEnableComplexity} />
			</Route>
			<Route path="/project/:projectTypeId/method/:solutionMethodId/phase/:phaseId/parentPhase/:parentPhaseId/activity/:activityId/edit">
				<ContributorActivityCrowdSourcing isEnableComplexity={isEnableComplexity} />
			</Route>
			<Route
				path={`/project/:projectTypeId/method/:solutionMethodId/phase/:phaseId/parentPhase/:parentPhaseId/activity/:activityId`}
			>
				<Activity
					projectTypeId={projectTypeId}
					solutionMethodId={solutionMethodId}
					isEnableComplexity={isEnableComplexity}
				/>
			</Route>

			<Route path={url}>
				{!isUserValidated || apiCallMade ? (
					<Spinner />
				) : (
					<>
						{solutionMethod.isDeleted ? (
							<DeletedEntityModel errorMsg={solutionMethod.errorMsg} />
						) : (
							<>
								{showPhaseDeletionMessage ? (
									<Modal
										className="activityDiscardPopUp"
										show={showPhaseDeletionMessage}
										onHide={() => {
											setShowPhaseDeletionMessage(false);
											setSelectedPhase(null);
										}}
										size="md"
										aria-labelledby="contained-modal-title-vcenter"
										centered
									>
										<Modal.Body>
											<p>
												You have successfully{' '}
												{selectedPhase.isRefered ? 'de-linked' : 'deleted'} the
												phase
											</p>
											<div className="discardPopUpActivityButtons">
												<Button
													title="OK"
													onClick={() => {
														setShowPhaseDeletionMessage(false);
														setSelectedPhase(null);
													}}
													tabIndex={-1}
													className="activityDiscardPopUpAccept"
													variant="info"
												>
													OK
												</Button>
											</div>
										</Modal.Body>
									</Modal>
								) : null}
								{showPhaseSelectionModal &&
									(phasePublishTitle || parentPhasePublishTitle) && (
										<PhaseSelectionModal
											setShowParentPhasePublishModal={
												setShowParentPhasePublishModal
											}
											setShowPublishModal={setShowPublishModal}
											phasePublishTitle={phasePublishTitle}
											parentPhasePublishTitle={parentPhasePublishTitle}
											setShowPhaseSelectionModal={setShowPhaseSelectionModal}
										/>
									)}
								<div
									role="main"
									className={
										solutionMethod.isMerged
											? 'phaseLandPageMainContainer mergedPhaseLandPage'
											: 'phaseLandPageMainContainer'
									}
								>
									<div
										className={
											hasParentMethod && !showMatrixView
												? 'phaselandMiddleContainer deliveryMargin'
												: `phaselandMiddleContainer ${showMatrixView ? 'matrixView' : ''
												}`
										}
									>
										<div className="breadcrumbsAndFilters newBreadCrumbContainer">
											<Breadcrumbs />
											<div className="viewChangeDiv newViewChangeDiv">

												{coOwners.includes(userAlias) && !endUserView && (
													<>
														<div className="extractRolesMethodology">
															<span
																id='extractRolesMethodologyIconId'
																className="extractRolesMethodologyIcon"
																onClick={() => setHandleExtractRoles(true)}
																onKeyPress={(e) => {
																	if (e.which === 13) {
																		setHandleExtractRoles(true);
																	}
																}}
																title="Extract Roles"
																tabIndex={0}
																aria-label={`Click to Extract Roles for ${solutionMethod.solutionMethodName}`}
																role="button"
															></span>
														</div>
														{handleExtractRoles ? (
															<ExtractRolesModal
																handleExtractRoles={handleExtractRoles}
																setHandleExtractRoles={setHandleExtractRoles}
																solutionMethodId={solutionMethodId}
																solutionMethodName={
																	solutionMethod.solutionMethodName
																}
															/>
														) : (
															<></>
														)}
													</>
												)}

												{coOwners.includes(userAlias) && !endUserView && (
													<div
														className="viewChangeDefaultSelect"
														id="defaultViewModalContainer"
													>
														<span>
															<Icon
																title="Set Default View"
																iconName="MoreVertical"
																onClick={() =>
																	setShowDefaultViewModal(!showDefaultViewModal)
																}
																onKeyPress={(e) => {
																	if (e.which === 13) {
																		setShowDefaultViewModal(
																			!showDefaultViewModal
																		);
																	}
																}}
																tabIndex={0}
																aria-label={`Click to Set default matrix/Blade View ${solutionMethod.solutionMethodName}`}
																role="button"
															/>
														</span>
														{showDefaultViewModal ? (
															<div>
																<span class="showMoreArrow"></span>
																<span className="viewChangeSelectDropDown">
																	<span className="viewChangeSelectTitle">
																		Select default view
																	</span>
																	<span className="viewChangeSelectIconContainer">
																		<span className="viewChangeSelectIcons">
																			<span
																				className={
																					solutionMethod.defaultView === 1
																						? 'matrixViewActive'
																						: 'matrixView'
																				}
																				tabIndex="0"
																				onClick={() => setDefaultView(1)}
																				onKeyPress={(e) => {
																					if (e.which === 13) {
																						setDefaultView(1);
																					}
																				}}
																				title="Matrix View"
																				aria-label={`Click to Set default view as Matrix view for ${solutionMethod.solutionMethodName}`}
																				role="button"
																			></span>
																			<span>Matrix</span>
																		</span>
																		<span
																			className="viewChangeSelectIcons"
																			title="Blade View"
																		>
																			<span
																				className={
																					solutionMethod.defaultView === 2
																						? 'bladeViewActive'
																						: 'bladeView'
																				}
																				tabIndex="0"
																				onClick={() => setDefaultView(2)}
																				onKeyPress={(e) => {
																					if (e.which === 13) {
																						setDefaultView(2);
																					}
																				}}
																				title="Blade View"
																				aria-label={`Click to Set default view as Blade view for ${solutionMethod.solutionMethodName}`}
																				role="button"
																			></span>
																			<span>Blade</span>
																		</span>
																	</span>
																</span>
															</div>
														) : (
															<></>
														)}
													</div>
												)}
												<span
													className={
														showMatrixView ? 'matrixViewActive' : 'matrixView'
													}
													onClick={() => {
														history.push(location);
														setShowMatrixView(true);
														track(
															1,
															1601,
															solutionMethodId,
															solutionMethod.solutionMethodName
														);
														setSvg({ enable: false, url: null, type: null });
													}}
													onKeyPress={(e) => {
														history.push(location);
														setShowMatrixView(true);
														track(
															1,
															1601,
															solutionMethodId,
															solutionMethod.solutionMethodName
														);
														setSvg({ enable: false, url: null, type: null });
													}}
													tabIndex="0"
													title="Matrix View"
													aria-label={`Click to view as Matrix view for ${solutionMethod.solutionMethodName}`}
													role="button"
												></span>
												{/* {showMatrixView ? (
													<span
														onKeyPress={(e) => {
															setSvg({
																enable: true,
																url: null,
																type: 'phase',
																source: 'matrixView',
															});
															track(
																1,
																151,
																'Phase SVG Switch',
																'Phase SVG Switch'
															);
															setShowMatrixView(!showMatrixView);
															history.push(location);
														}}
														onClick={() => {
															setSvg({
																enable: true,
																url: null,
																type: 'phase',
																source: 'matrixView',
															});
															track(
																1,
																151,
																'Phase SVG Switch',
																'Phase SVG Switch'
															);
															setShowMatrixView(!showMatrixView);
															history.push(location);
														}}
														className={'MethodswimlineIcon'}
														title="Visio View"
														tabIndex="0"
														aria-label={`Click to view visio`}
														role="button"
													></span>
												) : (
													<></>
												)} */}

												<span
													className={
														showMatrixView || svg.enable
															? 'bladeView'
															: 'bladeViewActive'
													}
													onClick={() => {
														setShowMatrixView(false);
														setSvg({ enable: false, url: null, type: null });
														track(
															1,
															1602,
															solutionMethodId,
															solutionMethod.solutionMethodName
														);
														history.push(location);
													}}
													onKeyPress={(e) => {
														setSvg({ enable: false, url: null, type: null });
														setShowMatrixView(false);
														track(
															1,
															1602,
															solutionMethodId,
															solutionMethod.solutionMethodName
														);
														history.push(location);
													}}
													tabIndex="0"
													title="Blade View"
													aria-label={`Click to view as blade view for ${solutionMethod.solutionMethodName}`}
													role="button"
												></span>
											</div>
											{((showPublishButton && !endUserView) ||
												isParentPhaseDrafted) && (
													<button
														className="phasePublishButton"
														onClick={() => setShowPhaseSelectionModal(true)}
														disabled={
															svg.enable ||
															(!phasePublishTitle && !parentPhasePublishTitle)
														}
														onKeyPress={(e) => {
															setShowPhaseSelectionModal(true);
														}}
														tabIndex="0"
														title="Publish"
														aria-label={`Click to publish Phase`}
														role="button"
													>
														Publish
													</button>
												)}
											{(!showPublishButton || endUserView) &&
												!isParentPhaseDrafted ? (
												<FavoriteAndFeedback />
											) : (
												<></>
											)}

										</div>
										<div className='customToggler'>
											<ReactRouterNavLink
											    tabIndex={-1}
												exact
												className={realizeValueToggler === 1 ? 'togglerActive' : 'togglerBlur customTogglerLink'}
												to={`/project/${1}/method/${Number(isProgramDelivery)}`}
											>
												<button className={realizeValueToggler === 1 ? 'togglerActive' : 'togglerBlur'}
													onClick={() => handleProgramDelivery()}>
													Program Delivery
												</button>
											</ReactRouterNavLink>
											<ReactRouterNavLink
											tabIndex={-1}
												exact
												className={realizeValueToggler === 1 ? 'togglerActive' : 'togglerBlur customTogglerLink'}
												to={`/project/${1}/method/${Number(deliveryManagementId)}`}
											>
												<button className={realizeValueToggler === 2 ? 'togglerActive' : 'togglerBlur'}
													onClick={() => handleProjectDelivery()}>
													Project Delivery
												</button>
											</ReactRouterNavLink>
										</div>
										{realizeValueToggler === 2 ?
											<ProjectDeliveryPhases
												projectDeliveryPhases={projectDeliveryPhases}
												setIsPhaseSelected={setIsPhaseSelected}
												setIsActiveSolution={setIsActiveSolution}
												isActiveSolution={isActiveSolution}
												deliveryManagementId={deliveryManagementId}
												setRealizeValueToggler={setRealizeValueToggler}
												realizeValueToggler={realizeValueToggler}
												solutionMethodOwner={solutionMethodOwner}
												setIsEditSolutionMethod={setIsEditSolutionMethod}
												isEditSolutionMethod={isEditSolutionMethod}
												setIsDeleteSolutionMethod={setIsDeleteSolutionMethod}
												isDeleteSolutionMethod={isDeleteSolutionMethod}
											/> : <></>}

										{isPhaseSelected && (phase.id !== null ||
											parentPhase.id !== null ||
											showMoreVisible ||
											showMatrixView) ? (
											<>
												<div
													className={
														svg.enable && svg.url !== null
															? 'hideFilters'
															: 'none'
													}
												>
													<div className="filterTagsContainer newFilterTagsContainer">
														<div className="tagsContainer">

															<h2>{solutionMethod.solutionMethodName} </h2>
															{solutionMethod.solutionMethodId === Number(isProgramDelivery) ?
																<div className="editProgramSolutionMethod">
																	<Icon
																		className="extractProgramMoreIcon"
																		iconName="MoreVertical"
																		title="Extract Methodology"
																		aria-label="Click to view extract Methodology"
																		role="button"
    																	aria-busy={isLoading ? "true" : "false"}
																		tabIndex={0}
																		onClick={() => {
																			setShowMethodProgramMenu(true);
																		}}
																		onKeyPress={(e) => {
																			if (e.which === 13) {
																				setShowMethodProgramMenu(true);
																			}
																		}}
																	/>

																	<div
																		className={`showProgramMoreMenu ${showMethodProgramMenu
																			? ""
																			: "hideMethodEditMenu"
																			}`}
																		ref={ref}
																	>
																		<span className="showMoreArrow"></span>
																		<ul className="menuProgramItemUL" role="listbox">
																			{isAdmin && !endUserView &&  (
																				<>
																					<li className="moreProgramItemLi" tabIndex="0">
																						<div
																							className="ellipseProgramMenuItem"
																							tabIndex={0}
																							onClick={() => {
																								setShowEditSolutionMethodlogy(true);
																								setSelectedSolutionMethod(solutionMethod.solutionMethodId);
																								setShowMethodProgramMenu(false);
																							}}
																							onKeyPress={(e) => {
																								if (e.which === 13) {
																									setShowEditSolutionMethodlogy(true);
																									setSelectedSolutionMethod(solutionMethod.solutionMethodId);
																									setShowMethodProgramMenu(false);
																								}
																							}}
																							aria-label="Click to edit Solution Method"
																							role="option"
																						>
																							<span>Edit Solution Method</span>
																						</div>
																					</li>
																				</>
																			)}
																			<li className="moreProgramItemLi" tabIndex="0">
																				<div
																					className="ellipseProgramMenuItem"
																					tabIndex={0}
																					onClick={(event) => {
																						event.stopPropagation();
																						setExtractMethodologyId(solutionMethod.solutionMethodId);
																						setSolutionMethodName(solutionMethod.solutionMethodName);
																						setShowExtractMethodlogy(true);
																						setShowMethodProgramMenu(false);
																					}}
																					onKeyPress={(e) => {
																						if (e.which === 13) {
																							setExtractMethodologyId(solutionMethod.solutionMethodId);
																							setSolutionMethodName(solutionMethod.solutionMethodName);
																							setShowExtractMethodlogy(true);
																							setShowMethodProgramMenu(false);
																						}
																					}}
																					aria-label="Click to extract Solution Method"
																					role="option"
																				>
																					<span>Extract Solution Method</span>
																				</div>
																			</li>
																		</ul>
																	</div>
																	{showExtractMethodlogy &&
																		extractMethodlogyId === solutionMethod.solutionMethodId ? (
																		<ExtractMethodlogy
																			showExtractMethodlogy={showExtractMethodlogy}
																			extractMethodlogyId={extractMethodlogyId}
																			solutionMethodName={solutionMethodName}
																			selectedComplexity={selectedSolutionComplexity}
																			setSelectedComplexity={setSelectedSolutionComplexity}
																			setExtractMethodologyId={setExtractMethodologyId}
																			setShowExtractMethodlogy={setShowExtractMethodlogy}
																			complexities={solutionMethodComplexities}
																		/>
																	) : null}
																	{showEditSolutionMethodlogy && (
																		<AdminEditSolutionMethod
																			showEditSolutionMethodlogy={showEditSolutionMethodlogy}
																			setShowEditSolutionMethodlogy={setShowEditSolutionMethodlogy}
																			selectedSolutionMethod={selectedSolutionMethod}
																			setSelectedSolutionMethod={setSelectedSolutionMethod}
																			methods={
																				editProjectId === 1
																					? selectedSolutionMethod
																						? consultingSolutionMethods
																						: [
																							...consultingSolutionMethods,
																							{
																								solutionMethodId: 0,
																								solutionMethodName: "",
																								type: "new",
																							},
																						]
																					: selectedSolutionMethod
																						? supportSolutionMethods
																						: [
																							...supportSolutionMethods,
																							{ solutionMethodId: 0, solutionMethodName: "", type: "new" },
																						]
																			}
																		/>
																	)}
																</div>:<></>
															}


															{isEnableComplexity && <span
																className={
																	!showPublishButton
																		? 'staticComplexity'
																		: 'staticComplexity disabledComplexity'
																}
															>
																Complexity:&nbsp;{selectedComplexity}
															</span>}

															{selectedRoles &&
																selectedRoles !== 'Select All' &&
																selectedRoles !== 'None' ? (
																<span>
																	Roles:&nbsp;{selectedRoles}
																	<Icon
																		iconName="cancel"
																		onClick={() => {
																			setSelectedRoles(null);
																			setSelectedRoleId(0);
																		}}
																	/>
																</span>
															) : (
																<></>
															)}
															{phaseHashtags.map((item) => (
																<span>
																	{item}
																	<Icon
																		iconName="cancel"
																		onClick={() => removeHashtag(item)}
																	/>
																</span>
															))}
															{Object.keys(phaseDynamicFilterTag).map(
																(item) => (
																	<>
																		{phaseDynamicFilterTag[Number(item)]
																			.selectedTagId.length > 0 &&
																			phaseDynamicFilterTag[
																				Number(item)
																			].selectedTagId.map((tagId) => (
																				<>
																					{phaseDynamicFilterTagMapping[
																						phaseDynamicFilterTag[Number(item)]
																							.artifactTypeId
																					][tagId] && (
																							<span key={tagId}>
																								{`${phaseDynamicFilterTagMapping[
																									phaseDynamicFilterTag[
																										Number(item)
																									].artifactTypeId
																								][tagId]
																									}`}
																								<Icon
																									onClick={() =>
																										handleDynamicFilterTag(
																											Number(item),
																											tagId
																										)
																									}
																									iconName="cancel"
																								/>
																							</span>
																						)}
																				</>
																			))}
																	</>
																)
															)}
														</div>
														{/* <div className='imgContainer imgContainerSolutionMethod'>
														<img className="ownerImg" src={solutionMethodOwner.photo}/>
                                                         <span className="ownerInfo ownerInfoSolutionMethod">
                                                         <span>Owner</span>
                                                         <a href={`mailto:${solutionMethodOwner.mail}`}>
                                                         <span style={{color:'#239ffd',fontWeight:'bolder'}}>{solutionMethodOwner.mail}</span>
                                                         </a>
                                                         </span>
														 <span className="collaboratorsImg collaboratorsImgSolutionMethod"
															onClick={() => setShowPhaseOwnerCollaborators(true)} >
														</span>
														</div> */}
														

														<div className="globalHashtagsSearch">
															<Icon iconName="Search" />
															<HashtagSuggestions
																className="phaseLandingHashtagAutoSuggestion"
																enableDropdown={true}
																data={hashtagSuggestions}
																setUpdateApiCall={setUpdateApiCall}
																searchValue={hashtagSearchInput}
																setSearchValue={setHashtagSearchInput}
																existingHashtags={phaseHashtags}
																setExistingHashtags={setPhaseHashtags}
																setHashtagSuggestions={setHashtagSuggestions}
																showSpinner={showSpinner}
																setShowSpinner={setShowSpinner}
																setDataSearchInput={setSearchInput}
															/>
														</div>
														<div
															className="raciFiltersPart"
															tabIndex={0}
															role="button"
															aria-label="Clear all filters"
														>
															<span>Clear all filters</span>
															<span
																className="ClearFiltersButton"
																tabIndex={0}
																onClick={clearFilters}
																onKeyPress={(e) => {
																	if (e.which === 13) {
																		clearFilters();
																	}
																}}
															></span>
														</div>
														<button
															role='button'
															aria-label='Filters'
															className="filtersButton"
															onClick={() => {
																setShowFilters(!showFilters);
																setShowGroup(true);
															}}
															disabled={showPublishButton && !endUserView}
														>
															Filters
															<Icon
																aria-hidden="false"
																iconName="FilterSolid"
																className="searchIcon"
																title="Filters"
															/>
														</button>
														{showMatrixView && <span className="collaboratorsImg"
															style={{ width: '26px', marginRight: '15px' }}
															onClick={() => setShowPhaseOwnerCollaborators(true)} ></span>
														}
													</div>
													<Complexity
														showFilters={showFilters}
														setShowFilters={setShowFilters}
														setSelectedComplexity={setSelectedComplexity}
														setSelectedRoles={setSelectedRoles}
														selectedRoles={selectedRoles}
														isMerged={solutionMethod.isMerged}
														solutionMethodId={solutionMethodId}
														searchInput={searchInput}
														setShowSpinner={setShowSpinner}
														setSelectedComplexityId={setSelectedComplexityId}
														setSelectedRoleId={setSelectedRoleId}
														showMatrixView={showMatrixView}
														phaseCreated={phaseCreated}
														updateComplexityFilter={updateComplexityFilter}
														setUpdateComplexityFilter={
															setUpdateComplexityFilter
														}
														parentId={solutionMethod.parentId}
														complexities={complexities}
														setComplexities={setComplexities}
														isEnableComplexity={isEnableComplexity}
													/>
												</div>
											</>
										) : (
											<></>
										)}
										{svg.enable  ? (
											<div className="phaseSwimlineView" id="svgPhase">
												<button
													onClick={() => {
														handleSvgClick();
														updateSvgFileName();
													}}
													className={`toggleView ${svg.url!==null ? 'toggleViewWithVisio':''}`} 
												>
													<span
														onKeyPress={(e) => {
															if (e.which === 13) {
																handleSvgClick();
																updateSvgFileName();
															}
														}}
														className={
															svg.type !== 'phase' ? 'swimlineIcon' : 'swimlineIconactive'
														}
														role="button"
														aria-pressed={svg.type !== 'phase' ? 'false' : 'true'}
														aria-label="visio and blade view"
														title="Toggle between Visio and Blade View"
													></span>
												</button>
												{visioNotFound ? (
													<p className="visioNotFound">
														Visio approval in progress.
													</p>
												) : (
													<>
													{ svg.url !== null ?(
														<>
														<img
															alt="First Sprint"
															onError={() => setVisioNotFound(true)}
															src={svg.url}
														></img>
														<button
															title="swichSwimlineIcon"
															className="swichSwimlineIcon"
															onClick={handleSvgExpand}
															aria-label="expand swimline"
														>
															<span
																className={
																	svgExpanded
																		? 'swimlineCollapseIcon'
																		: 'swimlineExpandIcon'
																}
															></span>
														</button>
														</>
													):(
														<div className='noVisiofileavailable'>
															<h3>No Visio file available.</h3>
														</div>
													)}
													</>
														
														
												)}
											</div>
										) : showMoreVisible ? (
											<PhaseShowMore
												phaseDescription={showMoreDescription}
												setShowMoreVisible={setShowMoreVisible}
												parentName={
													showMoreDescription.type === 'phase'
														? solutionMethod.solutionMethodName
														: parentSolutionMethod.solutionMethodName
												}
												isMerged={solutionMethod.isMerged}
											/>

										) : (
											<>
												{isPhaseSelected && showMatrixView ? (
													<MatrixView
														selectedComplexityId={selectedComplexityId}
														solutionMethodId={solutionMethodId}
														setShowMatrixView={setShowMatrixView}
														selectedRoleId={selectedRoleId}
														searchInput={searchInput}
														projectTypeId={projectTypeId}
														solutionMethod={solutionMethod}
														setSolutionMethod={setSolutionMethod}
														coOwners={coOwners}
														realizeValueToggler={realizeValueToggler}
														isProgramDelivery={isProgramDelivery}
														isPhaseSelected={isPhaseSelected}
													/>

												) : (
													<>
														{isPhaseSelected ?
															<div
																className={`
																	${realizeValueToggler === 1 ? 'descrpOfProgramDeliveryPhases' : 'descrpOfProjectDeliveryPhases'}
																	${phase.id !== null ||
																		parentPhase.id !== null ||
																		showMoreVisible
																		? `filterPhasesView ${tagFilterHeight ? 'tagFilterHeight' : ''
																		}`
																		: ''
																	}`}
															>
																<div
																	className={
																		visible.leftPanel
																			? `solutionMethodDescription ${!visible.rightPanel
																				? 'methodActive'
																				: ''
																			}
																			${!hasParentMethod ? 'noDelivery' : ''}`
																			: 'solutionMethodDescription collapsed'
																	}
																>
																	{visible.leftPanel ? (
																		<>
																			{hasParentMethod ? (
																				<span
																					className="collapsingIcon"
																					onClick={() => {
																						moveLeftPanel();
																					}}
																					role="button"
																					tabIndex={0}
																					onKeyPress={(e) => {
																						if (e.which === 13) {
																							moveLeftPanel();
																						}
																					}}
																					aria-label="collapsing Left Panel"
																				>
																					<span className="roundingIcon">
																						{visible.rightPanel ? (
																							<Icon
																								iconName="ChevronRight"
																								title="expand"
																							/>
																						) : (
																							<Icon
																								iconName="ChevronLeft"
																								title="collapse"
																							/>
																						)}
																					</span>
																				</span>
																			) : null}
																			 {/* <div className="filterTagsContainer newFilterTagsContainer">
																				<div className="tagsContainer">

																					<p>{solutionMethod.solutionMethodName} </p>


																					{isEnableComplexity && <span
																						className={
																							!showPublishButton
																								? 'staticComplexity'
																								: 'staticComplexity disabledComplexity'
																						}
																					>
																						Complexity:&nbsp;{selectedComplexity}
																					</span>}

																					{selectedRoles &&
																						selectedRoles !== 'Select All' &&
																						selectedRoles !== 'None' ? (
																						<span>
																							Roles:&nbsp;{selectedRoles}
																							<Icon
																								iconName="cancel"
																								onClick={() => {
																									setSelectedRoles(null);
																									setSelectedRoleId(0);
																								}}
																							/>
																						</span>
																					) : (
																						<></>
																					)}
																					{phaseHashtags.map((item) => (
																						<span>
																							{item}
																							<Icon
																								iconName="cancel"
																								onClick={() => removeHashtag(item)}
																							/>
																						</span>
																					))}
																					{Object.keys(phaseDynamicFilterTag).map(
																						(item) => (
																							<>
																								{phaseDynamicFilterTag[Number(item)]
																									.selectedTagId.length > 0 &&
																									phaseDynamicFilterTag[
																										Number(item)
																									].selectedTagId.map((tagId) => (
																										<>
																											{phaseDynamicFilterTagMapping[
																												phaseDynamicFilterTag[Number(item)]
																													.artifactTypeId
																											][tagId] && (
																													<span key={tagId}>
																														{`${phaseDynamicFilterTagMapping[
																															phaseDynamicFilterTag[
																																Number(item)
																															].artifactTypeId
																														][tagId]
																															}`}
																														<Icon
																															onClick={() =>
																																handleDynamicFilterTag(
																																	Number(item),
																																	tagId
																																)
																															}
																															iconName="cancel"
																														/>
																													</span>
																												)}
																										</>
																									))}
																							</>
																						)
																					)}
																				</div>

																				<div className="globalHashtagsSearch">
																					<Icon iconName="Search" />
																					<HashtagSuggestions
																						className="phaseLandingHashtagAutoSuggestion"
																						enableDropdown={true}
																						data={hashtagSuggestions}
																						searchValue={hashtagSearchInput}
																						setSearchValue={setHashtagSearchInput}
																						existingHashtags={phaseHashtags}
																						setExistingHashtags={setPhaseHashtags}
																						setHashtagSuggestions={setHashtagSuggestions}
																						showSpinner={showSpinner}
																						setShowSpinner={setShowSpinner}
																						setDataSearchInput={setSearchInput}
																					/>
																				</div>
																				<div
																					className="raciFiltersPart"
																					tabIndex={0}
																					role="button"
																					aria-label="Clear all filters"
																				>
																					<span>Clear all filters</span>
																					<span
																						className="ClearFiltersButton"
																						tabIndex={0}
																						onClick={clearFilters}
																						onKeyPress={(e) => {
																							if (e.which === 13) {
																								clearFilters();
																							}
																						}}
																					></span>
																				</div>
																				<button
																					className="filtersButton"
																					onClick={() => {
																						setShowFilters(!showFilters);
																						setShowGroup(true);
																					}}
																					disabled={showPublishButton && !endUserView}
																				>
																					Filters
																					<Icon
																						iconName="FilterSolid"
																						className="searchIcon"
																						title="Filters"
																					/>
																				</button>
																			</div>  */}

																			<div className='newBladeViewContainer'>
																				{!showMatrixView ? (
																					<PhaseLeftMenu
																						phasesList={solutionMethod.phasesList}
																						solutionMethodName={solutionMethod.solutionMethodName}
																						isMerged={solutionMethod.isMerged}
																						projectTypeId={projectTypeId}
																						solutionMethodId={solutionMethodId}
																						methodOwner={solutionMethod.methodOwner}
																						setPhaseCreated={setPhaseCreated}
																						solutionMethod={solutionMethod}
																						coOwners={coOwners}
																						showUpdatePhasePopUp={showUpdatePhasePopUp}
																						setShowPhaseUpdatePopUp={setShowPhaseUpdatePopUp}
																						setDeletePhase={setDeletePhase}
																						setDeletePhaseId={setDeletePhaseId}
																						isRetired={solutionMethod.isRetired}
																						isActivityCountZero={isActivityCountZero}
																						showDeletePhaseModal={showDeletePhaseModal}
																						setShowDeletePhaseModal={setShowDeletePhaseModal}
																						getSolutionMethodData={getSolutionMethodData}
																						phaseCreated={phaseCreated}
																						showPhaseUpdateModal={showPhaseUpdateModal}
																						setShowPhaseUpdateModal={setShowPhaseUpdateModal}
																						updatePhaseId={updatePhaseId}
																						setUpdatePhaseId={setUpdatePhaseId}
																						selectedTab={selectedPhaseTab}
																						setSelectedTab={setSelectedPhaseTab}
																						selectedPhase={selectedPhase}
																						setSelectedPhase={setSelectedPhase}
																						isSolutionCollaboratorsEdited={isSolutionCollaboratorsEdited}
																						setIsSolutionCollaboratorsEdited={setIsSolutionCollaboratorsEdited}
																						setExistingApprovers={setExistingApprovers} 
																						setExistingCoOwners={setExistingCoOwners} 
																						setExistingReviewers={setExistingReviewers}
																						setIsPhaseEdited={setIsPhaseEdited}
																						isPhaseEdited={isPhaseEdited}
																						updateSvgFileName={updateSvgFileName}
																						handleSvgClick={handleSvgClick}
																					/>
																				) : (
																					<></>
																				)}

																				<div className="solMethodDescrOnLandPage newSolMethodDescrOnLandPage">
																					{projectTypeId === '2' &&
																						showSupportBanner && (
																							<MessageBar
																								className="supportMethodConceptsBanner"
																								messageBarType={
																									MessageBarType.warning
																								}
																								isMultiline={true}
																							>
																								{supportBannerMessage}
																							</MessageBar>
																						)}
																					{showRaciViewBanner && (
																						<Link to={'/racigrid'}>
																							<MessageBar
																								className="supportMethodConceptsBanner raciViewBanner"
																								messageBarType={MessageBarType.info}
																								isMultiline={true}
																							>
																								<p
																									dangerouslySetInnerHTML={{
																										__html: raciViewBanner,
																									}}
																								></p>
																							</MessageBar>
																						</Link>
																					)}
																					<Switch>
																						<Route
																							exact
																							path={`${url}/phase/null/parentPhase/:parentPhaseId`}
																						>
																							<SolutionMethod
																								solutionMethod={solutionMethod}
																								projectTypeId={projectTypeId}
																								solutionMethodIdBreadcrumb={
																									solutionMethodId
																								}
																								showFilters={false}
																								setShowFilters={setShowFilters}
																								setShowGroup={setShowGroup}
																								isMerged={solutionMethod.isMerged}
																							/>
																						</Route>
																						<Route
																							path={`/project/${1}/method/${solutionMethodId}/phase/:phaseId/parentPhase/:parentPhaseId`}
																						>
																							<Phase
																								phaseId={phaseId}
																								parentPhaseId={parentPhaseId}
																								setShowMoreDescription={
																									setShowMoreDescription
																								}
																								setShowMoreVisible={
																									setShowMoreVisible
																								}
																								selectedComplexity={selectedComplexity}
																								setSelectedComplexity={setSelectedComplexity}
																								setSelectedComplexityId={setSelectedComplexityId}
																								showMatrixView={showMatrixView}
																								projectTypeId={projectTypeId}
																								solutionMethodId={solutionMethodId}
																								showGroup={showGroup}
																								showFilters={showFilters}
																								selectedRoles={selectedRoles}
																								isMerged={solutionMethod.isMerged}
																								solutionMethodName={
																									solutionMethod.solutionMethodName
																								}
																								parentMethodName={
																									solutionMethod.parentMethodName
																								}
																								isRetired={solutionMethod.isRetired}
																								phaseCreated={phaseCreated}
																								setShowPublishButton={
																									setShowPublishButton
																								}
																								showPublishModal={showPublishModal}
																								setShowPublishModal={
																									setShowPublishModal
																								}
																								setPhaseCreated={setPhaseCreated}
																								coOwners={coOwners}
																								deletePhase={deletePhase}
																								setDeletePhase={setDeletePhase}
																								setDeletePhaseId={setDeletePhaseId}
																								showDeletePhaseModal={
																									showDeletePhaseModal
																								}
																								setShowDeletePhaseModal={
																									setShowDeletePhaseModal
																								}
																								setIsActivityCountZero={
																									setIsActivityCountZero
																								}
																								isActivityCountZero={
																									isActivityCountZero
																								}
																								getSolutionMethodData={
																									getSolutionMethodData
																								}
																								setDeletePhaseName={
																									setDeletePhaseName
																								}
																								setShowPhaseDeletionMessage={
																									setShowPhaseDeletionMessage
																								}
																								searchInput={searchInput}
																								setPhasePublishTitle={
																									setPhasePublishTitle
																								}
																								showPhaseUpdateModal={
																									showPhaseUpdateModal
																								}
																								setShowPhaseUpdateModal={
																									setShowPhaseUpdateModal
																								}
																								updatePhaseId={updatePhaseId}
																								setUpdatePhaseId={setUpdatePhaseId}
																								selectedTab={selectedPhaseTab}
																								setSelectedTab={setSelectedPhaseTab}
																								selectedPhase={selectedPhase}
																								setSelectedPhase={setSelectedPhase}
																								updateComplexityFilter={
																									updateComplexityFilter
																								}
																								setUpdateComplexityFilter={
																									setUpdateComplexityFilter
																								}
																								complexities={complexities}
																								setComplexities={setComplexities}
																								isPhaseEdited={isPhaseEdited}
																							/>
																						</Route>
																						<Route
																							exact
																							path="/project/:projectId/method/:solutionMethodId"
																						>
																							<SolutionMethod
																								solutionMethod={solutionMethod}
																								setShowFilters={setShowFilters}
																								showFilters={showFilters}
																								setShowGroup={setShowGroup}
																								showGroup={showGroup}
																								isMerged={solutionMethod.isMerged}
																								isSolutionCollaboratorsEdited={isSolutionCollaboratorsEdited}
																						        setIsSolutionCollaboratorsEdited={setIsSolutionCollaboratorsEdited}
																							/>
																						</Route>
																						{/* <Route path="*">
																							<ComingSoon />
																						</Route> */}
																					</Switch>
																				</div>

																			</div>

																		</>
																	) : null}
																</div>
																{hasParentMethod ? (
																	<div
																		className={
																			visible.rightPanel
																				? `deliveryMangementDescription ${!visible.leftPanel
																					? 'methodActive'
																					: ''
																				}`
																				: 'deliveryMangementDescription collapsed'
																		}
																	>
																		{visible.rightPanel ? (
																			<>
																				<span
																					className="collapsingIcon"
																					onClick={() => moveRightPanel()}
																					role="button"
																					tabIndex={0}
																					onKeyPress={(e) => {
																						if (e.which === 13) {
																							moveRightPanel();
																						}
																					}}
																					aria-label="collapsing Right Panel"
																				>
																					<span className="roundingIcon">
																						{visible.leftPanel ? (
																							<Icon
																								iconName="ChevronLeft"
																								title="expand"
																							/>
																						) : (
																							<Icon
																								iconName="ChevronRight"
																								title="collapse"
																							/>
																						)}
																					</span>
																				</span>
																				<div>
																					{!showMatrixView ? (
																						<div className="contentContOfDeliveryManagment">
																							<Switch>
																								<Route
																									exact
																									path={`${url}/phase/:phaseId/parentPhase/null`}
																								>
																									<DeliveryManagementDesc
																										parentSolutionMethod={
																											parentSolutionMethod
																										}
																										setVisible={setVisible}
																									/>
																								</Route>
																								<Route
																									exact
																									path={`${url}/phase/:phaseId/parentPhase/:parentPhaseId`}
																								>
																									<DeliveryPhase
																										setShowMoreDescription={
																											setShowMoreDescription
																										}
																										setShowMoreVisible={
																											setShowMoreVisible
																										}
																										projectTypeId={projectTypeId}
																										solutionMethodId={
																											solutionMethodId
																										}
																										showGroup={showGroup}
																										showFilters={showFilters}
																										selectedRoles={selectedRoles}
																										isMerged={
																											solutionMethod.isMerged
																										}
																										solutionMethodName={
																											solutionMethod.solutionMethodName
																										}
																										parentMethodName={
																											solutionMethod.parentMethodName
																										}
																										isRetired={
																											solutionMethod.isRetired
																										}
																										phaseCreated={phaseCreated}
																										isParentPhaseDrafted={
																											isParentPhaseDrafted
																										}
																										setIsParentPhaseDrafted={
																											setIsParentPhaseDrafted
																										}
																										setShowPublishButton={
																											setShowPublishButton
																										}
																										showPublishModal={
																											showParentPhasePublishModal
																										}
																										setShowPublishModal={
																											setShowParentPhasePublishModal
																										}
																										setPhaseCreated={
																											setPhaseCreated
																										}
																										coOwners={parentPhaseCoOwners}
																										deletePhase={deletePhase}
																										setDeletePhase={
																											setDeletePhase
																										}
																										setDeletePhaseId={
																											setDeletePhaseId
																										}
																										showParentDeletePhaseModal={
																											showParentDeletePhaseModal
																										}
																										setShowParentDeletePhaseModal={
																											setShowParentDeletePhaseModal
																										}
																										setIsActivityCountZero={
																											setIsParentActivityCountZero
																										}
																										isActivityCountZero={
																											isParentActivityCountZero
																										}
																										getSolutionMethodData={
																											getSolutionMethodData
																										}
																										setDeletePhaseName={
																											setDeletePhaseName
																										}
																										setShowPhaseDeletionMessage={
																											setShowPhaseDeletionMessage
																										}
																										searchInput={searchInput}
																										selectedComplexity={
																											selectedComplexity
																										}
																										parentId={
																											solutionMethod.parentId
																										}
																										getParentSolutionMethodData={
																											getParentSolutionMethodData
																										}
																										parentPhaseCreated={
																											parentPhaseCreated
																										}
																										setParentPhaseCreated={
																											setParentPhaseCreated
																										}
																										showDeletePhaseModal={
																											showDeletePhaseModal
																										}
																										setShowDeletePhaseModal={
																											setShowDeletePhaseModal
																										}
																										setParentPhasePublishTitle={
																											setParentPhasePublishTitle
																										}
																										parentSolutionMethod={
																											parentSolutionMethod
																										}
																										showPhaseUpdateModal={
																											showParentPhaseUpdateModal
																										}
																										setShowPhaseUpdateModal={
																											setShowParentPhaseUpdateModal
																										}
																										updatePhaseId={
																											updateParentPhaseId
																										}
																										setUpdatePhaseId={
																											setUpdateParentPhaseId
																										}
																										selectedTab={
																											selectedParentPhaseTab
																										}
																										setSelectedTab={
																											setSelectedParentPhaseTab
																										}
																										selectedPhase={selectedPhase}
																										setSelectedPhase={
																											setSelectedPhase
																										}
																										updateComplexityFilter={
																											updateComplexityFilter
																										}
																										setUpdateComplexityFilter={
																											setUpdateComplexityFilter
																										}
																									/>
																								</Route>
																								<Route exact path={url}>
																									<DeliveryManagementDesc
																										parentSolutionMethod={
																											parentSolutionMethod
																										}
																										setVisible={setVisible}
																										isMerged={
																											solutionMethod.isMerged
																										}
																									/>
																								</Route>
																								<Route path="*">
																									<ComingSoon />
																								</Route>
																							</Switch>
																						</div>
																					) : (
																						<></>
																					)}
																				</div>
																			</>
																		) : null}
																	</div>
																) : null}
															</div>
															:
															<>
															</>
														}

													</>
												)}
											</>
										)}
									</div>
									{!showMatrixView ? (
										<>
											{hasParentMethod ? (
												<DeliveryManagement
													phasesList={parentSolutionMethod.phasesList}
													parentMethodName={
														parentSolutionMethod.solutionMethodName
													}
													solutionMethodName={solutionMethod.solutionMethodName}
													isMerged={solutionMethod.isMerged}
													projectTypeId={projectTypeId}
													solutionMethodId={solutionMethodId}
													methodOwner={solutionMethod.methodOwner}
													setPhaseCreated={setPhaseCreated}
													solutionMethod={solutionMethod}
													coOwners={parentPhaseCoOwners}
													showUpdatePhasePopUp={showUpdatePhasePopUp}
													setShowPhaseUpdatePopUp={setShowPhaseUpdatePopUp}
													setDeletePhase={setDeletePhase}
													setDeletePhaseId={setDeletePhaseId}
													isRetired={solutionMethod.isRetired}
													isActivityCountZero={isParentActivityCountZero}
													parentId={solutionMethod.parentId}
													getParentSolutionMethodData={
														getParentSolutionMethodData
													}
													parentPhaseCreated={parentPhaseCreated}
													setParentPhaseCreated={setParentPhaseCreated}
													showParentDeletePhaseModal={
														showParentDeletePhaseModal
													}
													setShowParentDeletePhaseModal={
														setShowParentDeletePhaseModal
													}
													showDeletePhaseModal={showDeletePhaseModal}
													setShowDeletePhaseModal={setShowDeletePhaseModal}
													showPhaseUpdateModal={showParentPhaseUpdateModal}
													setShowPhaseUpdateModal={
														setShowParentPhaseUpdateModal
													}
													updatePhaseId={updateParentPhaseId}
													setUpdatePhaseId={setUpdateParentPhaseId}
													selectedTab={selectedParentPhaseTab}
													setSelectedTab={setSelectedParentPhaseTab}
													selectedPhase={selectedPhase}
													setSelectedPhase={setSelectedPhase}
													getSolutionMethodData={getSolutionMethodData}
												/>
											) : null}
										</>
									) : (
										<></>
									)}
								</div>
							</>
						)}
						{showPhaseOwnerCollaborators ? <ActivityCollaborators
							setShowActivityOwnerCollaborators={setShowPhaseOwnerCollaborators}
							activityName={solutionMethod.solutionMethodName}
							artifactName={'Solution Method'}
							artifactOwner={solutionMethodOwner}
							existingCoOwners={existingCoOwners}
							existingReviewers={existingReviewers}
							existingApprovers={existingApprovers}
							showCollaboratorSpinner={showCollaboratorSpinner}
						/> : <></>
						}
					</>
				)}
			</Route>
		</Switch>
	);
}

const ComingSoon = () => {
	const history = useHistory();
	history.push('/pageNotFound');
	return <></>;
};

export default RealizeValueLandingPage;
