/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Navbar, Nav, Form, FormControl, Button } from "react-bootstrap";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { initializeIcons } from "@uifabric/icons";
import { Icon } from "@fluentui/react/lib/Icon";
import $ from 'jquery';
import SearchSuggestion from "./SearchSuggestion";
import MultiLevelDropdown from "../MultiLevelDropdown";
import MultiLevelDropdownMobile from "../MultiLevelDropdown";
import RightPanel from "../RightPanel/RightPanel";

import UserPreference from "../../views/UserPreference";

import { useAxios } from "../../context/AxiosContext";
import { useAuth } from "../../context/UserAuthContext";
import { useAppInsights } from "../../context/TelemetryContext";
import { useLeftMenuCollapse } from "../../context/LeftMenuCollapseContext";

import userDefaultImg from "../../assets/UserProfile.png";
import { NavbarDataLeft } from "../../static/Navbar";

import { Dropdown, DropdownMenuItemType } from '@fluentui/react/lib/Dropdown';
import "./Navbar.css";
import { Stack } from "@fluentui/react";
initializeIcons();

function NavBar() {
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuth();

  const { track } = useAppInsights(0);
  const { axiosGetService, axiosPostService } = useAxios();
  const {
    appState,
    setAppState,
    collapsedLeftMenu,
    notifications,
    pendingAnnouncements,
    endUserView,
    userIsOwner,
  } = useLeftMenuCollapse();
  const { rightPanelContent } = appState;
  const [searchText, setSearchText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [activeClassId, setActiveClassId] = useState([]);
  const [validInput, setValidInput] = useState(true);
  const [errorMessage, setErrorMessage] = useState("Invalid input type");
  const [navItems, setNavItems] = useState([]);
  const [artifactsList, setArtifactsList] = useState([]);
  const [availableArtifacts, setAvailableArtifacts] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [showSearchPopUp, setShowSearchPopUp] = useState(false);
const [recentSearchHistory, setRecentSearchHistory] = useState([])
const [searchLoader, setSearchLoader] = useState(false); 
const [triggerElement, setTriggerElement] = useState(null);


const getSearchHistory = async () => {
  setSearchLoader(true);
  let checkSearchText = searchText === '' ? '0' : searchText;
  try {
    const response = await axiosGetService(
      `api/searchhistory?searchValue=${encodeURIComponent(checkSearchText.replace(/:/g, ''))}&createdBy=${user.profile.mail}`
    );
    setRecentSearchHistory(response.data.searchList);
  } catch (error) {
    console.error('Error fetching search history:', error);
  } finally {
    setSearchLoader(false);
  }
};
useEffect(() => {
  getSearchHistory();
}, [searchText])

  const addSearchHistory = async (searchTextString) => {
    try {
      const postData = {
        SearchText: searchTextString,
        CreatedBy: user.profile.mail,
      };
      const response = await axiosPostService(`api/addsearchhistory`, postData);
      return response.data;
    } catch (error) {
      console.error("Error adding search history:", error);
      throw error;
    }
  };


  const getNavItems = async () => {
    await axiosGetService(`api/sdmsettings?sdmKey=MenuJson`).then(
      (response) => {
        getDeliveryPlaybookStatus(JSON.parse(response.data[0].value));
      }
    );
  };
  
  const getSearchArtifacts = async () => {
    await axiosGetService(`api/sdmartifactmaster/getsearchfiltercategoryartifacts`).then((res) => {
      const data = res.data?.map((item) => {
        return {
          name: item.name,
          id: parseInt(item.id,10),
          artifactTypeId: item.artifactTypeId,
          //isSelected: item.isSelected,
        };
      });
      setAvailableArtifacts(data);

    });
  };

  const getArtifactSearchData = (availableArtifacts) => {
    const availableArtifactsArray = [];
    availableArtifacts?.map(items => {
      availableArtifactsArray.push({
        key: items.artifactTypeId,
        text: items.name,
      })
    })
    return availableArtifactsArray;
  }

  const getSelectedArtifactSearchData = (availableArtifacts) => {
    const availableArtifactsArray = [];    
    availableArtifacts?.filter((item) => selectedKeys?.includes(item.artifactTypeId)).map(items => {
      availableArtifactsArray.push({
        key: items.artifactTypeId,
        text: items.name,
      })
    })
    return availableArtifactsArray;
  }
  const createNavItem = (item) => {
    if (item.hasSubMenu === true && item.id !== 6) {
      return (
        <MultiLevelDropdown
          data={item}
          key={`topMenu_${item.id}`}
          drop="down"
          setActiveClassId={setActiveClassId}
          activeClassId={activeClassId}
        />
      );
    }
    if (item.id !== 6) {
      return (
        <li>
         <NavLink
          to={item.link}
          id={`topMenu_${item.id}`}
          key={`topMenu_${item.id}`}
          activeClassName={
            item.id == 5 ||
              (item.id == 0 &&
                window.location.pathname === "/topics/498") ||
              (item.id == 0 && window.location.pathname === "/tutorials") ||
              (item.id == 0 && window.location.href.indexOf("/tutorials") > 1)
              ? null
              : "topMenuItemActive"
          }
          className="nav-link"
          aria-label={item.title}
          tabIndex={0}
          onClick={() => track(1, 105, `topMenuItem_${item.title}`, item.title)}
          onKeyPress={(e) => {
            if (e.which === 13) {
              track(1, 105, `topMenuItem_${item.title}`, item.title);
            }
          }}
        >
          {item.title}
        </NavLink>
        </li>
      );
    } else return null
  };

  const createNavItemMobile = (item) => {
    if (document?.location?.pathname === '/racigrid') {
      $('#topMenuMob_5').addClass('topMenuItemActive');
    }
    if (item.hasSubMenu === true && item.id == 6) {
      return (
        <MultiLevelDropdownMobile
          data={item}
          key={`topMenuMob_${item.id}`}
          drop="down"
          setActiveClassId={setActiveClassId}
          activeClassId={activeClassId}
        />
      );
    }
    if (item.id == 5) {
      return (
        <NavLink
          to={item.link}
          id={`topMenuMob_${item.id}`}
          key={`topMenu_${item.id}`}
          activeClassName={
            item.id == 5 ||
              (item.id == 0 && window.location.pathname === "/topics/consulting/498") ||
              (item.id == 0 && window.location.pathname === "/tutorials") ||
              (item.id == 0 && window.location.href.indexOf("/tutorials") > 1)
              ? null
              : "topMenuItemActive"
          }
          className="nav-link"
          aria-label={item.title}
          tabIndex={0}
          onClick={() => track(1, 105, `topMenuItem_${item.title}`, item.title)}
          onKeyPress={(e) => {
            if (e.which === 13) {
              track(1, 105, `topMenuItem_${item.title}`, item.title);
            }
          }}
        //role="menuitem"
        >
          {item.title}
        </NavLink>
      );
    }
    else return null;
  };




  // set Content to rightPanel used by right Side Icons eg. externalApps, userProfile etc
  const handleRightPanel = (id, e) => {
    setTriggerElement(e.currentTarget);
    const newRightPanelContent = rightPanelContent === id ? null : id;
    setAppState({ ...appState, rightPanelContent: newRightPanelContent });
  };

  const clear = () => {
    setSelectedKeys([]);
  };

  // get Search Suggestions
  const getSuggestions = async () => {
    setSearchText(searchText.trim());
    setSuggestions([]);
    setShowSuggestions(true);
    const regexp = /^[a-zA-Z0-9\s\.()[\]\d\w,#:;&""''_\-\*]+$/;
    if (!regexp.test(searchText)) {
      setValidInput(false);
      setErrorMessage(`Invalid input type`);
    } else {
      setValidInput(true);
      axiosGetService(
        `api/searchautocomplete?searchValue=${encodeURIComponent(searchText.replace(/:/g, ''))}&filterCategoryIds=${selectedKeys.length > 0 ? selectedKeys : 0}&endUser=${!userIsOwner || endUserView}`
      ).then((res) => {
        if (res.data.status === "false") {
          setErrorMessage(res.data.errorMessage);
          setValidInput(false);
        } else {
          if (searchText[0] === "#") {
            track(1, 1204, "hashtagSearch", searchText);
          }
          setSuggestions(res.data);
        }
      });
    }
  };

  // on press Enter route to search results page
  const routeToSearchResults = (event, text) => {
    if (
      event.which === 13 &&
      searchText.length > 2 &&
      searchText.trim().length > 0 &&
      validInput
    ) {
      setShowSuggestions(false);
      setSuggestions([]);
      history.push(`/search?searchParam=${encodeURIComponent(text)}`);
      // setSearchText('');
    }
  };

  // onClick search if suggestions present show them
  const expandSuggestions = () => {
    if (searchText.length !== 0) {
      setShowSuggestions(true);
    }
  };

  // onOutside click of searchbar, collapse Suggestions
  const collapseSuggestions = (e) => {
    const input = document.getElementById("navSearchBar");
    if (input !== null && !input.contains(e.target)) {
      setShowSuggestions(false);
    }
  };

  const getDeliveryPlaybook = async (items) => {
    await axiosGetService(`api/sdmsettings?sdmKey=PlaybookUrl`).then(
      (response) => {
        const updatedNavbar = [...items];
        updatedNavbar.splice(3, 0, {
          id: 5,
          title: "Program Delivery Playbook",
          link: response.data[0].value,
          hasSubMenu: false,
        });
        setNavItems(updatedNavbar);
      }
    );
  };

  const getDeliveryPlaybookStatus = async (items) => {
    await axiosGetService(`api/sdmsettings?sdmKey=ShowDeliveryPlaybook`).then(
      (response) => {
        if (response.data[0].value === "true") {
          getDeliveryPlaybook(items);
        } else {
          setNavItems([...items]);
        }
      }
    );
  };

  const onChange = (event, item) => {
    if (item) {
      setSelectedKeys(
        item.selected
          ? [...selectedKeys, item.key]
          : selectedKeys.filter(key => key !== item.key)
      )
    }
  }

  const onRenderPlaceholder = () => {
    return (selectedKeys.length > 0 ? "All Categories (" + selectedKeys.length + ")" : "All Categories");
  }

  const onRenderCaretDown = (event) => {
    return (
      <Stack horizontal verticalAlign={"center"} >
        {!selectedKeys.length > 0 || selectedKeys && (
          <Icon
            tabIndex={0}
            iconName={"Cancel"}
            aria-label="Clear"
            title="Clear"
            role="button"
            styles={{
              root: {
                color: "rgb(96, 94, 92)",
                marginRight: "5px",
                fontWeight: '700',
                fontSize: '10px',
                "&:hover": {
                  fontWeight: 700
                }
              }
            }}
            onClick={(event) => {
              event.stopPropagation();
              clear();
            }}
            onKeyDown={(event) => {
              if (event.which === 13) {
                event.stopPropagation();
                clear();
              }
            }}
          />
        )}
        <Icon
          iconName={"ChevronDown"}
          styles={{
            root: {
              color: "rgb(96, 94, 92)",
              "&:hover": {
                fontWeight: 800
              }
            }
          }}
          onClick={(event) => event.currentTarget.parentNode.onClick}
        />
      </Stack>
    );
  }




  // onChange of searchText, get Suggestions and set whether to show Suggestions or not
  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (
        searchText !== null &&
        searchText.length > 2 &&
        searchText.trim().length > 0
      ) {
        getSuggestions();
      } else if (searchText === null || searchText.trim().length === 0) {
        setSearchText(searchText.trim());
        setShowSuggestions(false);
        setValidInput(true);
      }
    }, 1000);
    return () => {
      clearTimeout(timeOutId);
    };
  }, [searchText, selectedKeys, endUserView]);

  // onComponentDidMount, addEventListener to collapseSuggestionDropdown on outside click of Input
  useEffect(() => {
    window.addEventListener("click", collapseSuggestions);
  }, []);

  useEffect(() => {
    if (!location.pathname.includes("/search")) {
      setSearchText("");
    }
    setSuggestions([]);
  }, [location]);

  useEffect(() => {
    getNavItems();
  }, []);

  useEffect(() => {
    getSearchArtifacts();
  }, []);

  $(document).ready(function () {
    $(window).resize(function () {
      if ((window.screen.width > 991)) {
        $('.navbar-collapse.collapse').removeClass("show");
      }
    });
  });

  const inputRef = useRef(null);
  const popupRef = useRef(null);
  useEffect(() => {
    const handleClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
        setShowSearchPopUp(false);
      }
    };
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    if (showSearchPopUp) {
      inputRef.current.focus();
    }
  }, [showSearchPopUp]);


$(function() { function handleKeyboardFocus(event) {
  if (event.key === 'Tab') {
      if ($('#myProfile').is(':focus') && !event.shiftKey) {
          event.preventDefault();
          if($('#feedbackCompId').length >0 )  $('#feedbackCompId').focus();
          else $('#breadcrumbId_0').focus();

      } else if ($('#feedbackCompId').is(':focus') && !event.shiftKey) {
          event.preventDefault();
          if($('#phaseId_0').length >0 ) focusElement('#phaseId_0');
          else $('#breadcrumbId_0').focus();

      } else if ($('#feedbackCompId').is(':focus') && event.shiftKey) {
          event.preventDefault();
          $('#myProfile').focus();

      } else if ($('#breadcrumbId_0').is(':focus') && event.shiftKey) {
          event.preventDefault();
          if($('#feedbackCompId').length >0 )  $('#feedbackCompId').focus();
          else $('#myProfile').focus();

      } else if ($('#phaseId_0').is(':focus') && event.shiftKey) {
        event.preventDefault();
        $('#feedbackCompId').focus();

      } else if ($('#phaseId_4').is(':focus') && !event.shiftKey) {
        event.preventDefault();
        $('#divMainLeftMenuId').focus();

      } else if ($('#divMainLeftMenuId').is(':focus') && event.shiftKey) {
        event.preventDefault();
        $('#phaseId_4').focus(); 
        
      } else if ($('#contentinfoId').is(':focus') && !event.shiftKey) {
        event.preventDefault();
        if($('#breadcrumbId_0').length >0 ) $('#breadcrumbId_0').focus()
        else $('#phaseId_0').focus();

      } else {
        handleBreadcrumbFocus(event);
      }
  }
}

  function handleBreadcrumbFocus(event) {
    const allFocusableElements = $('a[href], button, input, select, textarea, [tabindex]:not([tabindex="-1"])');
    const breadcrumbElements = $('[id^="breadcrumbId_"]');

    /* Handle forward tabbing*/
    if (breadcrumbElements.last().is(':focus') && !event.shiftKey) {
      event.preventDefault();
      const nextElement = findNextFocusableElement(allFocusableElements, breadcrumbElements.last());
      if (nextElement) {
        nextElement.focus();
      }
    }
    /* Handle backward tabbing */
    else if (breadcrumbElements.first().is(':focus') && event.shiftKey) {
      event.preventDefault();
      const prevElement = findPrevFocusableElement(allFocusableElements, breadcrumbElements.first());
      if (prevElement) {
        prevElement.focus();
      }
    }
  }

  function findNextFocusableElement($allElements, $currentElement) {
    const priorityElements = ['#complexityBoxId', '#extractRolesMethodologyIconId'];
    for (let selector of priorityElements) {
      if ($(selector).length > 0) {
        return $(selector);
      }
    }
    
    let currentIndex = $allElements.index($currentElement);
    let nextIndex = (currentIndex + 1) % $allElements.length;
    while (nextIndex !== currentIndex) {
      const $nextElement = $allElements.eq(nextIndex);
      if ($nextElement.is(':visible') && $nextElement.is(':not([disabled])')) {
        return $nextElement;
      }
      nextIndex = (nextIndex + 1) % $allElements.length;
    }
    return null;
  }

  function findPrevFocusableElement($allElements, $currentElement) {
    const priorityElements = ['#extractRolesMethodologyIconId', '#complexityBoxId'];
    for (let selector of priorityElements) {
      if ($(selector).length > 0) {
        return $(selector);
      }
    }
    
    let currentIndex = $allElements.index($currentElement);
    let prevIndex = (currentIndex - 1 + $allElements.length) % $allElements.length;
    while (prevIndex !== currentIndex) {
      const $prevElement = $allElements.eq(prevIndex);
      if ($prevElement.is(':visible') && $prevElement.is(':not([disabled])')) {
        return $prevElement;
      }
      prevIndex = (prevIndex - 1 + $allElements.length) % $allElements.length;
    }
    return null;
  }

  function focusElement(selector) {
      $(selector).focus();        
      setTimeout(() => {
          if (!$(selector).is(':focus')) {
              $(selector).focus();
          }
      }, 0);
  }

$('#myProfile, #phaseId_0, [id^="breadcrumbId_"]').attr('tabindex', '0');
$(document).off('keydown');
$(document).on('keydown', handleKeyboardFocus);
});

  
  return (
    <>
      <UserPreference />
      {/*   <Navbar
        bg="light" expand="lg"
        fixed="top"
        className="sdmNavBar"
        role="navigation"
      // role="tablist"
      >
        <NavLink
          to="/"
          className="sdmLogo"
          title="SDMPlus 2.0"
          aria-label="SDM PLUS 2.0 LOGO Click to go Home"
          tabIndex={0}
          onClick={() => {
            track(1, 3, "SDMLogo", "SDMLogo");
          }}
          onKeyPress={(e) => {
            if (e.which === 13) {
              track(1, 3, "SDMLogo", "SDMLogo");
            }
          }}
        // role="tab"
        >
          <Navbar.Brand
            className={`${collapsedLeftMenu ? "collapse" : "expand"}`}
          >
            <span className="logo" />
            <span
              id="logoTextCollapse"
              className={`${collapsedLeftMenu ? "collapse" : "expand"}`}
            >
              SDMPlus 2.0
            </span>
          </Navbar.Brand>
        </NavLink>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto sdmMainMenu">
            {navItems.map((item) => createNavItem(item))}
          </Nav>
          <Nav className="rightSecNavBar mr-sm-2">
            <div
              className={`${!validInput ? "navSearchBox inputError" : "navSearchBox"
                }`}
            >
              <div className="sdmGlobalSearch">
                <Icon iconName="Search" />
                <input
                  aria-label="searchBar"
                  type="text"
                  placeholder="Search"
                  id="navSearchBar"
                  value={searchText}
                  tabIndex={0}
                  onChange={(event) => setSearchText(event.target.value)}
                  onClick={(event) => expandSuggestions()}
                  onKeyPress={(event) =>
                    routeToSearchResults(event, searchText)
                  }
                  title={
                    !searchText || searchText.length < 3
                      ? "Please enter at least three character to search"
                      : searchText
                  }
                />
              </div>
              {showSuggestions ? (
                <SearchSuggestion
                  data={suggestions}
                  validInput={validInput}
                  errorMessage={errorMessage}
                  searchText={searchText}
                  routeToSearchResults={routeToSearchResults}
                />
              ) : null}
            </div>
            {/* <Toggle /> 
            <ul>
              <li
                tabIndex={0}
                className={
                  rightPanelContent === "externalApps"
                    ? "externalAppsIcon navIconActive"
                    : "externalAppsIcon"
                }
                id="externalApps"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    handleRightPanel("externalApps");
                    track(1, 105, "topMenu_otherTools", "Other Tools");
                  }
                }}
                onClick={() => {
                  handleRightPanel("externalApps");
                  track(1, 105, "topMenu_otherTools", "Other Tools");
                }}
                title="Other Tools"
                aria-label="click to open External apps dialog"
              >
                <span className="othertoolsIcon" />
              </li>
              <li
                tabIndex={0}
                className={
                  rightPanelContent === "helpAndSupport"
                    ? "questionMark navIconActive"
                    : "questionMark"
                }
                id="helpAndSupport"
                aria-label="click to open Help and Support dialog"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    handleRightPanel("helpAndSupport");
                    track(1, 105, "topMenu_helpAndSupport", "Help & Support");
                  }
                }}
                onClick={() => {
                  handleRightPanel("helpAndSupport");
                  track(1, 105, "topMenu_helpAndSupport", "Help & Support");
                }}
              >
                <Icon
                  iconName="StatusCircleQuestionMark"
                  title="Help &amp; Support"
                />
              </li>
              <li
                tabIndex={0}
                className={
                  rightPanelContent === "whatsNew"
                    ? "whatsNewIcon navIconActive"
                    : "whatsNewIcon"
                }
                id="whatsNew"
                aria-label="click to open Announcements dialog"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    handleRightPanel("whatsNew");
                    track(1, 105, "topMenu_whatsNew", "What's New");
                  }
                }}
                onClick={() => {
                  handleRightPanel("whatsNew");
                  track(1, 105, "topMenu_whatsNew", "What's New");
                }}
              >
                <span className="announcementsIcon" title="What's New">
                  {pendingAnnouncements.count > 0 ? (
                    <span className="announcementsCount">
                      <span>{pendingAnnouncements.count}</span>
                    </span>
                  ) : (
                    <></>
                  )}
                </span>
              </li>
              <li
                tabIndex={0}
                className={
                  rightPanelContent === "notifications"
                    ? "notificationsIcon navIconActive"
                    : "notificationsIcon"
                }
                id="notifications"
                aria-label="click to open notifications dialog"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    handleRightPanel("notifications");
                    track(1, 105, "topMenu_notifications", "Notifications");
                  }
                }}
                onClick={() => {
                  handleRightPanel("notifications");
                  track(1, 105, "topMenu_notifications", "Notifications");
                }}
              >
                <span className="notificationIcon" title="Action Center">
                  {notifications.count > 0 ? (
                    <span className="notificationCount">
                      <span>{notifications.count}</span>
                    </span>
                  ) : (
                    <></>
                  )}
                </span>
              </li>
              <li
                tabIndex={0}
                className={
                  rightPanelContent === "myProfile"
                    ? "myProfileIcon navIconActive"
                    : "myProfileIcon"
                }
                id="myProfile"
                aria-label="click to open My Profile dialog"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    handleRightPanel("myProfile");
                    track(1, 5, "topMenu_myProfile", "My Profile");
                  }
                }}
                onClick={() => {
                  handleRightPanel("myProfile");
                  track(1, 5, "topMenu_myProfile", "My Profile");
                }}
              >
                <img
                  title="My Profile"
                  className="userImg"
                  src={user.profile.photo || userDefaultImg}
                  alt=""
                />
              </li>
            </ul>
          </Nav>
        </Navbar.Collapse>
        {rightPanelContent === null ? null : <RightPanel />}
      </Navbar> */}
        <div role="banner" className="fixed-top" style={{height:'58px'}}>
      <Navbar  bg="light" expand="lg" role="none"
        fixed="top"
        className="sdmNavBar" 
        >
        <Navbar.Brand
            className={`${collapsedLeftMenu ? "collapse" : "expand"}`}
            href="/" aria-current="page"
            style={{ cursor: "pointer" }}
            aria-label="SDM PLUS 2.0 LOGO Click to go Home"
            tabindex="0"
          >
            <span className="logo" />
          </Navbar.Brand>
        <Nav role="navigation" aria-label="TopNavigation" className="mr-auto sdmMainMenu">
        <ul>
            {navItems.map((item) => createNavItem(item))}
        </ul>
        </Nav>
        <Nav className="mr-auto sdmMainMenuMobile">
          {navItems.map((item) => createNavItemMobile(item))}
        </Nav>
        <Navbar.Toggle 
        aria-controls="basic-navbar-nav"
        title="Hamburger Expand Menu"
        onClick={(e) => {
          e.currentTarget.title = e.currentTarget.classList.toggle('collapsed') ? "Hamburger Expand Menu" : "Hamburger Collapse Menu";
        }} 
        />
        <Navbar.Collapse id="basic-navbar-nav">

          <Nav className="rightSecNavBar ml-auto">
            <div className="searchSection">
              <>
                <div
                  title="Artifact Categories"
                  id="searchArtifact"
                >
                  <Dropdown
                    tabIndex={0}
                    placeholder={"All Categories"}
                    selectedKeys={selectedKeys}
                    onRenderTitle={onRenderPlaceholder}
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={onChange}
                    multiSelect
                    options={getArtifactSearchData(availableArtifacts)}
                    styles={{
                      dropdown: { height: '26px' },
                      dropdownOptionText: { fontSize: '12px' },
                      checkbox: {
                        width: "16",
                        height: "16",
                        background: 'white',
                        borderColor: 'red'
                      }
                    }}
                    onRenderCaretDown={onRenderCaretDown}
                    aria-label="All Categories"
                    className="searchinputFocus"
                  />
                </div>
              </>
              <div
                className={`${!validInput ? "navSearchBox " : "navSearchBox"
                  }`}
              >
                <div className="sdmGlobalSearch">


                  <input
                  className="searchinputFocus"
                    aria-label="searchBar"
                    type="text"
                    placeholder="Search"
                    id="navSearchBar"
                    value={searchText}
                    tabIndex={0}
                    onChange={(event) => 
                      setSearchText(event.target.value)
                    }
                    onClick={(event) => {
                      getSearchHistory();
                      setShowSearchPopUp(true);
                      expandSuggestions();
                    }}
                    ref={inputRef}
                    onKeyPress={(event) =>{
                      routeToSearchResults(event, searchText);
                      if(event.key === 'Enter'){
                        addSearchHistory(searchText);
                      }
                    }}
                    title={
                      !searchText || searchText.length < 3
                        ? "Please enter at least three character to search"
                        : searchText
                    }
                  />
                  <Icon
                    tabIndex={0}
                    aria-label="Search"
                    role="button"
                    iconName="Zoom"
                    className="globalSearchIcon searchinputFocus"
                    onClick={
                      (event) => {
                        event.stopPropagation();
                        if (searchText === null || searchText.trim().length === 0) {
                          setShowSuggestions(false);
                          setValidInput(false);
                        }
                        else if (
                          searchText.length > 2 &&
                          searchText.trim().length > 0 &&
                          validInput
                        ) {
                          getSuggestions()
                        }
                        routeToSearchResults({ which: 13 }, searchText);
                        addSearchHistory(searchText);
                      }
                    }
                  />

                </div>
                {showSuggestions || (showSearchPopUp && recentSearchHistory.length>0) ? (
                  <SearchSuggestion
                    data={suggestions}
                    validInput={validInput}
                    errorMessage={errorMessage}
                    searchText={searchText}
                    routeToSearchResults={routeToSearchResults}
                    selectedKeys={selectedKeys}
                    selectedKeysData={getSelectedArtifactSearchData(availableArtifacts)}
                    recentSearchHistory={recentSearchHistory}
                    setShowSearchPopUp={setShowSearchPopUp}
                    popupRef={popupRef}
                    setSearchText={setSearchText}
                    searchLoader={searchLoader}
                    addSearchHistory={addSearchHistory}
                    setRecentSearchHistory={setRecentSearchHistory}
                  />
                ) : null}

              </div>
            </div>
            <ul>
              <li
                tabIndex={0}
                className={
                  rightPanelContent === "externalApps"
                    ? "externalAppsIcon navIconActive"
                    : "externalAppsIcon"
                }
                id="externalApps"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    handleRightPanel("externalApps",e);
                    track(1, 105, "topMenu_otherTools", "Other Tools");
                  }
                }}
                onClick={(e) => {
                  handleRightPanel("externalApps",e);
                  track(1, 105, "topMenu_otherTools", "Other Tools");
                }}
                title="Other Tools"
              >
                <span className="othertoolsIcon" role="button" aria-label="Other Tools" />
              </li>
              <li
                title="Help & Support"
                tabIndex={0}
                className={
                  rightPanelContent === "helpAndSupport"
                    ? "questionMark navIconActive"
                    : "questionMark"
                }
                id="helpAndSupport"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    handleRightPanel("helpAndSupport",e);
                    track(1, 105, "topMenu_helpAndSupport", "Help & Support");
                  }
                }}
                onClick={(e) => {
                  handleRightPanel("helpAndSupport",e);
                  track(1, 105, "topMenu_helpAndSupport", "Help & Support");
                }}
              >
                <Icon
                  role="button"
                  aria-label="Help & Supportg"
                  iconName="StatusCircleQuestionMark"
                />
              </li>
              <li
                title="Copilot Dialog"
                tabIndex={0}
                className={
                  rightPanelContent === "copilot"
                    ? "copilotAppsIcon navIconActive"
                    : "copilotAppsIcon"
                }
                id="copilot"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    handleRightPanel("copilot",e);
                    // track(1, 105, "topMenu_helpAndSupport", "Help & Support");
                  }
                }}
                onClick={(e) => {
                  handleRightPanel("copilot",e);
                  // track(1, 105, "topMenu_helpAndSupport", "Help & Support");
                }}
              >
                <span className="copilotIcon" role="button" aria-label="Copilot Dialog" />
              </li>
              <li
                title="What's New"
                tabIndex={0}
                className={
                  rightPanelContent === "whatsNew"
                    ? "whatsNewIcon navIconActive"
                    : "whatsNewIcon"
                }
                id="whatsNew"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    handleRightPanel("whatsNew",e);
                    track(1, 105, "topMenu_whatsNew", "What's New");
                  }
                }}
                onClick={(e) => {
                  handleRightPanel("whatsNew",e);
                  track(1, 105, "topMenu_whatsNew", "What's New");
                }}
              >
                <span className="announcementsIcon" role="button" aria-label="What's New">
                  {pendingAnnouncements.count > 0 ? (
                    <span className="announcementsCount">
                      <span aria-hidden="true">{pendingAnnouncements.count}</span>
                    </span>

                  ) : (
                    <></>
                  )}
                </span>
              </li>
              <li
                title="Notifications"
                tabIndex={0}
                className={
                  rightPanelContent === "notifications"
                    ? "notificationsIcon navIconActive"
                    : "notificationsIcon"
                }
                id="notifications"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    handleRightPanel("notifications",e);
                    track(1, 105, "topMenu_notifications", "Notifications");
                  }
                }}
                onClick={(e) => {
                  handleRightPanel("notifications",e);
                  track(1, 105, "topMenu_notifications", "Notifications");
                }}
              >
                <span className="notificationIcon" role="button" aria-label="Notifications">
                  {notifications.count > 0 ? (
                    <span className="notificationCount">
                      <span aria-hidden="true" >{notifications.count}</span>
                    </span>
                  ) : (
                    <></>
                  )}
                </span>
              </li>
              <li
                title="My Profile"                
                tabIndex={0}
                className={
                  rightPanelContent === "myProfile"
                    ? "myProfileIcon navIconActive"
                    : "myProfileIcon"
                }
                id="myProfile"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    handleRightPanel("myProfile",e);
                    track(1, 5, "topMenu_myProfile", "My Profile");
                  }
                }}
                onClick={(e) => {
                  handleRightPanel("myProfile",e);
                  track(1, 5, "topMenu_myProfile", "My Profile");
                }}
              >
                <span role="button" aria-label="My Profile">
                <img
                  className="userImg"
                  src={user.profile.photo || userDefaultImg}
                  alt=""
                />
                </span>
              </li>
            </ul>
          </Nav>
        </Navbar.Collapse>
        {rightPanelContent === null ? null : <RightPanel setTriggerElement={setTriggerElement} triggerElement={triggerElement}/>}
      </Navbar>
      </div>
    </>
  );
}

export default NavBar;
